import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

/**
 * Hubs Embedded
 * 
 * @class Embedded
 * @kind JS.Module
 * @mixin
 * @classdesc Provides the Embedded JS Behaviour for the App
 * @namespace Hubs
 * @constructor
 */
Hubs.Embedded = function() {};

/** @member {Object} embeddedScrollData The Last Positions of Moving Parts (used to keep in view when Hub is embedded) */
Hubs.Embedded.prototype.embeddedScrollData  = {
        //        $e=element, h=height, o=offset, p=last position, f=found element
    'sheader' : {'$e': null, 'h': 0, 'o': 0, 'p': 0},
    'lheader' : {'$e': null, 'h': 0, 'o': 0, 'p': 0},
    'nav'     : {'$e': null, 'h': 0, 'o': 0, 'p': 0},
    'footer'  : {'$e': null, 'h': 0, 'o': 0, 'p': 0},
    'cta_s'   : {'$e': null, 'h': 0, 'o': 0, 'p': 0, 'f': false},   // Side CTA
    'cta_b'   : {'$e': null, 'h': 0, 'o': 0, 'p': 0, 'f': false},   // Blocking CTA
    'share'   : {'$e': null, 'h': 0, 'o': 0, 'p': 0, 'f': false},
    'toTop'   : {'$e': null, 'h': 0, 'o': 0, 'p': 0}
};

/** @member {Number} minTopBottomGap The minimum Gap between the Header and Footer */
Hubs.Embedded.prototype.minTopBottomGap = 300;

/** @member {Object} viewportData The current data associated with the Viewport */
Hubs.Embedded.prototype.viewportData = {'x': 0, 'y': 0, 'scrollTop': 0, 'offsetTop': 0};

/** @member {Object} absoluteOffset */
Hubs.Embedded.prototype.absoluteOffset = {'top': 0, 'bottom': 0};

/** @member {Object} animDuration */
Hubs.Embedded.prototype.animDuration = {'slide': 750, 'fade': {'hide': 0, 'delay': 250, 'show': 200}};

/** @member {Object} currentPosition */
Hubs.Embedded.prototype.embeddedPosition = {'header': 0, 'footer': 0, 'nav': 0, 'cta_s': 0, 'cta_b': 0, 'share': 0, 'toTop': 0, 'diff': 0};

/** @member {Boolean} hideBanner */
Hubs.Embedded.prototype.hideBanner = false;


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * initEmbedded():
 *   Page init sequence when in hub embedded mode.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.initEmbedded = function() {
    if (!Hubs.isEmbedded()) { return; }

    var $win = Shared.$('window');

    // Debug Statement
    Shared.Logger.log('embedded-init');
    
    // Always Allow Touch Events on Embedded + Mobile
    if (Hubs.isMobile()) {
        Hubs.restoreBodyScroll();
    }
    
    // Store Data for Window Scroll Event
    this.updateEmbeddedScrollData();
        
    // Watch Scroll Event
    $win.on('debouncedscroll.Hubs.Embedded', $.proxy(this.embeddedScrollEvent, this));
    
    // Watch Resize Event
    $win.on('debouncedresize.Hubs.Embedded orientationchange.Hubs.Embedded', $.proxy(this.updateEmbeddedScrollData, this));

    // Update hash bookmark and scroll-to-position
    this.setParentHashBookmark();
    this.setParentScrollPosition(300);
};

/**
 * setParentHashBookmark():
 *   Set hash bookmark on the parent window. Requires non-vanity style url to create
 *   hash fragment (e.g. https://hubs.uberflip.com/h/i/458905298-destination-wedding).
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.setParentHashBookmark = function() {
    var currentUrl = $('#page-type-identifier').attr('data-non-vanity-url') || '';
    var hashFragment = Hubs.getUrlFragmentId(currentUrl);
    var callback = function() { window.parentIFrame.updateUrl(hashFragment); };
    this.callIFrameResizer(callback);
};

/**
 * setParentScrollPosition():
 *   On page change, set scroll to last known position or to top. Delay (ms) if required.
 *   Uses current page url to remember last known position.
 *
 * @public
 * @this Hubs.App
 * @param {Number} timeout = Delay in ms before executing scroll-to-top
 * @return undefined
 */
Hubs.Embedded.prototype.setParentScrollPosition = function(timeout) {
    var collectionState = this.getCollectionState();
    var scrollPosition = (collectionState && collectionState.scrollTop) || 'top';
    var callback = function() { window.parentIFrame.scroll(scrollPosition) };
    this.callIFrameResizer(callback, timeout);
};

/**
 * callIFrameResizer():
 *   Execute a callback when iframeResizer library is bound to window and ready for use.
 *   Used for making changes to the parent window of the embedded hub.
 *
 * @public
 * @this Hubs.App
 * @param {Function} callback = Function to call when iframeResizer is ready
 * @param {Number} timeout = Delay in ms before running callback once iframeResizer is ready
 * @return undefined
 */
Hubs.Embedded.prototype.callIFrameResizer = function(callback, timeout) {
    var iframeResizerReady = 'parentIFrame' in window;

    if (iframeResizerReady) {
        timeout ? Hubs.root.setTimeout(callback, timeout) : callback();
    } else {
        window.addEventListener('parentIFrameReady', function () {
            timeout ? Hubs.root.setTimeout(callback, timeout) : callback();
        });
    }
};

/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.repositionLoadingIndicator = function() {
    // Reposition Loading Indicator
    this.$loadingIndicator.css({'top': 150});
};

/**
 * Called only at Init to determine size/initial position of elements
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.updateEmbeddedScrollData = function() {
    var $win = Shared.$('window'); 
    var $doc = Shared.$('document');
    var $levelThree = $(Hubs.Config.containers.levelThree);
    var queryParam = '';
    var hiddenEmbed = $(Hubs.Config.cta.elements.hiddenEmbed).length;
    
    if (!Hubs.isEmbedded()) { return; }
    
    // Debug Statement
    Shared.Logger.log('embedded-update-scroll-data');
    
    // Check for Hidden Top Banner
    this.hideBanner = (Hubs.isIFrame() && Hubs.Config.embedOptions.hideBanner);
    
    // Check for Absolute Offsets
    queryParam = Shared.getQueryParamByName('offsetTop');
    if (queryParam.length) { this.absoluteOffset.top = parseInt(queryParam, 10); }
    queryParam = Shared.getQueryParamByName('offsetBottom');
    if (queryParam.length) { this.absoluteOffset.bottom = parseInt(queryParam, 10); }

    // Get Bottom Coord for Level Three Block
    this.levelThreeBottom = 0;
    if ($levelThree.length) {
        this.levelThreeBottom = ($levelThree.height() + $levelThree[0].offsetTop - 60);
    }
    
    // Reset Handle to CTA Elements
    this.embeddedScrollData.cta_s.f = false;
    if (this.embeddedScrollData.cta_s.$e !== null && this.embeddedScrollData.cta_s.$e.length) {
        this.embeddedScrollData.cta_s.$e.css({'margin-top': 0});
        this.embeddedScrollData.cta_s.o = this.embeddedScrollData.cta_s.p = 0;
    }
    this.embeddedScrollData.cta_b.f = false;
    if (this.embeddedScrollData.cta_b.$e !== null && this.embeddedScrollData.cta_b.$e.length && !hiddenEmbed) {
        this.embeddedScrollData.cta_b.$e.css({'top': 0});
        this.embeddedScrollData.cta_b.o = this.embeddedScrollData.cta_b.p = 0;
    }
    
    // Get Handle & Height of Elements
    if (this.embeddedScrollData.lheader.$e === null || !this.embeddedScrollData.lheader.$e.length) {
        this.embeddedScrollData.lheader.$e = Shared.$(Hubs.Config.containers.largeHeader);
    }
    if (this.embeddedScrollData.lheader.$e.length) {
        this.embeddedScrollData.lheader.h = this.embeddedScrollData.lheader.$e.outerHeight();
    }
    if (this.embeddedScrollData.sheader.$e === null || !this.embeddedScrollData.sheader.$e.length) {
        this.embeddedScrollData.sheader.$e = Shared.$(Hubs.Config.containers.topNav);
    }
    if (this.embeddedScrollData.sheader.$e.length) {
        this.embeddedScrollData.sheader.h = this.embeddedScrollData.sheader.$e.outerHeight();
    }
    if (this.embeddedScrollData.footer.$e === null || !this.embeddedScrollData.footer.$e.length) {
        this.embeddedScrollData.footer.$e = Shared.$(Hubs.Config.containers.footer);
    }
    if (this.embeddedScrollData.footer.$e.length && !Hubs.Config.embedOptions.hideFooter) {
        this.embeddedScrollData.footer.h = this.embeddedScrollData.footer.$e.outerHeight();
        
        // Footer dynamically positioned using "top" via JS, so rid the "bottom" rule set in CSS 
        if (Hubs.Config.labOptions.stickyFooter) {
            this.embeddedScrollData.footer.$e.css({'top': -this.embeddedScrollData.footer.h, 'bottom': 'auto'});
        }
    }
    if (this.embeddedScrollData.toTop.$e === null || !this.embeddedScrollData.toTop.$e.length) {
        this.embeddedScrollData.toTop.$e = Shared.$(Hubs.Config.containers.moveToTop);
    }
    if (this.embeddedScrollData.toTop.$e.length) {
        this.embeddedScrollData.toTop.h = this.embeddedScrollData.toTop.$e.outerHeight();
        this.embeddedScrollData.toTop.$e.css({'bottom': 'auto'});
    }
    if (!Hubs.Config.labOptions.topMenu) {
        if (this.embeddedScrollData.nav.$e === null || !this.embeddedScrollData.nav.$e.length) {
            this.embeddedScrollData.nav.$e = Shared.$(Hubs.Config.containers.leftNav[2] + '.desktop');
        }
        if (this.embeddedScrollData.nav.$e.length) {
            this.embeddedScrollData.nav.h = this.embeddedScrollData.nav.$e.outerHeight();
        }
    }
    
    // Reset Handle to "AddThis" Share Element
    this.embeddedScrollData.share.f = false;
    if (this.embeddedScrollData.share.$e !== null && this.embeddedScrollData.share.$e.length) {
        this.embeddedScrollData.share.$e.css({'top': 160});
        this.embeddedScrollData.share.o = this.embeddedScrollData.sheader.h + 20;
        this.embeddedScrollData.share.p = 0;
    }
};

/**
 * Observes and Responds to the Scroll Events fired on the Global Window Object or App Container
 *  - Iframe Resizer also calls this on Init
 * 
 * @public
 * @this Hubs.App
 * @param {Object} eventData The Event Data associated with the Event 
 * @param {Number} scrollTop 
 * @param {Number} offsetTop 
 * @param {Number} viewportX 
 * @param {Number} viewportY 
 * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
 */
Hubs.Embedded.prototype.embeddedScrollEvent = function(e, scrollTop, offsetTop, viewportX, viewportY) {
    var $win = Shared.$('window');
    var $doc = Shared.$('document');
    
    // Debug Statement
    Shared.Logger.log('embedded-scroll');
    
    // Get Height of Document
    this.documentHeight = $doc.height();
    
    // Ensure Valid Values passed in
    if (scrollTop === undefined) { scrollTop = this.viewportData.scrollTop; }
    if (offsetTop === undefined) { offsetTop = this.viewportData.offsetTop; }
    if (viewportX === undefined) { viewportX = $win.width(); }
    if (viewportY === undefined) { viewportY = $win.height(); }
    
    // Store Current Viewport Data
    this.viewportData.x = viewportX;
    this.viewportData.y = viewportY;
    this.viewportData.scrollTop = scrollTop;
    this.viewportData.offsetTop = offsetTop;
    
    // Scroll Individual Elements
    this.scrollSmallHeader();
    this.scrollFooter();
    this.scrollNav();
    this.scrollMoveToTop();
    this.scrollCTAs();
    this.scrollSharing();
};


/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.scrollSmallHeader = function() {
    // Keep Small Header in View
    if (!this.hideBanner && this.embeddedScrollData.sheader.$e && this.embeddedScrollData.sheader.$e.length) {
        // Calculate New Position of Header
        this.embeddedPosition.header = this.viewportData.scrollTop - this.viewportData.offsetTop + this.absoluteOffset.top;
        
        // Ensure Menu stays within Top Bounds of Frame
        if (this.embeddedPosition.header < 0) { this.embeddedPosition.header = 0; }
        
        // Ensure Menu stays within Bottom Bounds of Frame
        if (this.embeddedPosition.header + this.embeddedScrollData.sheader.h + this.embeddedScrollData.footer.h + this.minTopBottomGap > this.documentHeight) {
            this.embeddedPosition.diff = this.embeddedPosition.header + this.embeddedScrollData.sheader.h + this.embeddedScrollData.footer.h + this.minTopBottomGap - this.documentHeight;
            this.embeddedPosition.header -= this.embeddedPosition.diff;
        }
        this.embeddedPosition.header = this.embeddedPosition.header | 0;

        // Don't Animate if Menu hasn't Moved
        if (this.embeddedPosition.header !== this.embeddedScrollData.sheader.p) {
            this.embeddedScrollData.sheader.p = this.embeddedPosition.header;

            // Slide Behavior
            if (Hubs.Config.embedOptions.revealBehaviour === 'slide') {
                this.embeddedScrollData.sheader.$e.stop().animate({'top': this.embeddedPosition.sheader}, this.animDuration.slide);
            }
            
            // Fade Behavior (with delayed reveal)
            if (Hubs.Config.embedOptions.revealBehaviour === 'fade') {
                window.clearTimeout(this.smallHeaderTimer); 
                this.embeddedScrollData.sheader.$e.stop().animate({'opacity': 0}, this.animDuration.fade.hide, $.proxy(function() {
                    this.smallHeaderTimer = window.setTimeout($.proxy(function() {
                        this.embeddedScrollData.sheader.$e.css({'top': this.embeddedPosition.header}).animate({'opacity': 1}, this.animDuration.fade.show);
                        this.scrollStarted = false;
                        
                        if (Hubs.Config.search.enabled) {
                            // Update the search positions now that the header position has been set
                            this.search.updateDropMenuPosition();
                            this.search.updateResultsPosition();
                        }
                    }, this), this.animDuration.fade.delay);
                }, this));
            }
        }
    }
};


/**
 * 
 * 
 * @public
 * @this Hubs.App 
 * @return undefined
 */
Hubs.Embedded.prototype.scrollFooter = function() {
    // Keep Footer in View
    if (Hubs.Config.labOptions.stickyFooter && this.embeddedScrollData.footer.$e && this.embeddedScrollData.footer.$e.length) {
        // Calculate New Position of Menu
        this.embeddedPosition.footer = this.viewportData.scrollTop - this.viewportData.offsetTop + this.viewportData.y - this.embeddedScrollData.footer.h - this.absoluteOffset.bottom;

        // Ensure Menu stays within Top Bounds of Frame
        if (this.embeddedPosition.footer < this.embeddedPosition.header + this.embeddedScrollData.sheader.h + this.minTopBottomGap) {
            this.embeddedPosition.footer = this.embeddedPosition.header + this.embeddedScrollData.sheader.h + this.minTopBottomGap;
        }
        
        // Ensure Menu stays within Bottom Bounds of Frame
        if (this.embeddedPosition.footer + this.embeddedScrollData.footer.h > this.documentHeight) {
            this.embeddedPosition.diff = this.embeddedPosition.footer + this.embeddedScrollData.footer.h - this.documentHeight;
            this.embeddedPosition.footer -= this.embeddedPosition.diff;
        }
        this.embeddedPosition.footer = this.embeddedPosition.footer | 0;
        
        // Don't Animate if Menu hasn't Moved
        if (this.embeddedPosition.footer !== this.embeddedScrollData.footer.p) {
            this.embeddedScrollData.footer.p = this.embeddedPosition.footer;
            
            // Slide Behavior
            if (Hubs.Config.embedOptions.revealBehaviour === 'slide') {
                this.embeddedScrollData.footer.$e.stop().animate({'top': this.embeddedPosition.footer}, this.animDuration.slide);
            }
            
            // Fade Behavior (with delayed reveal)
            if (Hubs.Config.embedOptions.revealBehaviour === 'fade') {
                window.clearTimeout(this.footerPosTimer); 
                this.embeddedScrollData.footer.$e.stop().animate({'opacity': 0}, this.animDuration.fade.hide, $.proxy(function() {
                    this.footerPosTimer = window.setTimeout($.proxy(function() {
                        this.embeddedScrollData.footer.$e.css({'top': this.embeddedPosition.footer}).animate({'opacity': 1}, this.animDuration.fade.show);
                    }, this), this.animDuration.fade.delay);
                }, this));
            }
        }
    }
};


/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.scrollNav = function() {
    
    // Keep Left Menu in View
    if (this.embeddedScrollData.nav.$e && this.embeddedScrollData.nav.$e.length) {
        // Calculate New Position of Menu
        this.embeddedScrollData.nav.$e.removeAttr('style'); // css({'top': 'inherit'});
        this.embeddedScrollData.nav.o = this.embeddedScrollData.sheader.h + 20;
        this.embeddedScrollData.nav.p = this.embeddedScrollData.nav.o;
        this.embeddedPosition.nav = this.viewportData.scrollTop - this.viewportData.offsetTop + this.embeddedScrollData.nav.o + this.absoluteOffset.top;
        
        // Ensure Menu stays within Top Bounds of Frame
        if (this.embeddedPosition.nav < this.embeddedScrollData.nav.o) { this.embeddedPosition.nav = this.embeddedScrollData.nav.o; }
        
        // Ensure Menu stays within Bottom Bounds of Frame
        if (this.embeddedPosition.nav + this.embeddedScrollData.nav.h + this.embeddedScrollData.footer.h + 30 > this.documentHeight) {
            this.embeddedPosition.diff = this.embeddedPosition.nav + this.embeddedScrollData.nav.h + this.embeddedScrollData.footer.h + 30 - this.documentHeight;
            this.embeddedPosition.nav -= this.embeddedPosition.diff;
        }
        this.embeddedPosition.nav = this.embeddedPosition.nav | 0;
        
        // Don't Animate if Menu hasn't Moved
        if (this.embeddedPosition.nav !== this.embeddedScrollData.nav.p) {
            this.embeddedScrollData.nav.p = this.embeddedPosition.nav;
            
            // Slide Behavior
            if (Hubs.Config.embedOptions.revealBehaviour === 'slide') {
                this.embeddedScrollData.nav.$e.stop().animate({'top': this.embeddedPosition.nav}, this.animDuration.slide);
            }
            
            // Fade Behavior (with delayed reveal)
            if (Hubs.Config.embedOptions.revealBehaviour === 'fade') {
                window.clearTimeout(this.menuPosTimer); 
                this.embeddedScrollData.nav.$e.stop().animate({'opacity': 0}, this.animDuration.fade.hide, $.proxy(function() {
                    this.menuPosTimer = window.setTimeout($.proxy(function() {
                        this.embeddedScrollData.nav.$e.css({'top': this.embeddedPosition.nav}).animate({'opacity': 1}, this.animDuration.fade.show);
                    }, this), this.animDuration.fade.delay);
                }, this));
            }
        }
    }
};


/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.scrollMoveToTop = function() {
    if (!this.embeddedScrollData.toTop.$e || !this.embeddedScrollData.toTop.$e.length) { return; }
    
    // Calculate New Position of Link
    this.embeddedScrollData.toTop.o = this.viewportData.y - this.embeddedScrollData.toTop.h - this.absoluteOffset.bottom - 20;
    this.embeddedPosition.toTop = this.viewportData.scrollTop - this.viewportData.offsetTop + this.embeddedScrollData.toTop.o;
    if (Hubs.Config.labOptions.stickyFooter && this.embeddedScrollData.footer.$e.length) {
        this.embeddedPosition.toTop -= this.embeddedScrollData.footer.h;
    }
    
    // Ensure Link stays within Top Bounds of Frame
    if (this.embeddedPosition.toTop < this.embeddedScrollData.toTop.o) { this.embeddedPosition.toTop = this.embeddedScrollData.toTop.o; }
    
    // Ensure Link stays within Bottom Bounds of Frame
    if (this.embeddedPosition.toTop + this.embeddedScrollData.toTop.h + 45 > this.documentHeight) {
        this.embeddedPosition.diff = this.embeddedPosition.toTop + this.embeddedScrollData.toTop.h + 45 - this.documentHeight;
        this.embeddedPosition.toTop -= this.embeddedPosition.diff;
    }
    this.embeddedPosition.toTop = this.embeddedPosition.toTop | 0;
    
    // Don't Animate if Link hasn't Moved
    if (this.embeddedPosition.toTop !== this.embeddedScrollData.toTop.p) {
        this.embeddedScrollData.toTop.p = this.embeddedPosition.toTop;
        
        // Slide Behavior
        if (Hubs.Config.embedOptions.revealBehaviour === 'slide') {
            this.embeddedScrollData.toTop.$e.stop().animate({'top': this.embeddedPosition.toTop}, this.animDuration.slide);
        }
        
        // Fade Behavior (with delayed reveal)
        if (Hubs.Config.embedOptions.revealBehaviour === 'fade') {
            window.clearTimeout(this.linkPosTimer); 
            this.embeddedScrollData.toTop.$e.stop().animate({'opacity': 0}, this.animDuration.fade.hide, $.proxy(function() {
                this.linkPosTimer = window.setTimeout($.proxy(function() {
                    this.embeddedScrollData.toTop.$e.css({'top': this.embeddedPosition.toTop}).animate({'opacity': 1}, this.animDuration.fade.show);
                }, this), this.animDuration.fade.delay);
            }, this));
        }
    }
};


/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.scrollCTAs = function() {
    // Find Existing Side-CTAs
    if (!this.embeddedScrollData.cta_s.f) {
        this.embeddedScrollData.cta_s.$e = $(Hubs.Config.cta.elements.itemCta + ':not(.blocking-cta)');
        if (this.embeddedScrollData.cta_s.$e.length && !this.embeddedScrollData.cta_s.$e.parents(Hubs.Config.containers.levelThree).length) {
            this.embeddedScrollData.cta_s.f = true;
        }
        if (this.embeddedScrollData.cta_s.f) {
            this.embeddedScrollData.cta_s.h = this.embeddedScrollData.cta_s.$e.outerHeight(true);
            if (this.embeddedScrollData.cta_s.h < 50) {
                this.embeddedScrollData.cta_s.h = this.embeddedScrollData.cta_s.$e.find('.tile.cta').outerHeight();
            }
            this.embeddedScrollData.cta_s.o = this.embeddedScrollData.cta_s.p = 0;
        }
    }
    
    // Keep Side-CTA in View
    if (this.embeddedScrollData.cta_s.f) {
        // Calculate New Position of Side-CTA
        this.embeddedPosition.cta_s = this.viewportData.scrollTop - this.viewportData.offsetTop + this.embeddedScrollData.cta_s.o;
        
        // Ensure Side-CTA stays within Bottom Bounds of Frame
        if (this.embeddedPosition.cta_s + this.embeddedScrollData.cta_s.h > this.levelThreeBottom) {
            this.embeddedPosition.diff = this.embeddedPosition.cta_s + this.embeddedScrollData.cta_s.h - this.levelThreeBottom;
            this.embeddedPosition.cta_s -= this.embeddedPosition.diff;
        }
        
        // Ensure Side-CTA stays within Top Bounds of Frame
        if (this.embeddedPosition.cta_s < this.embeddedScrollData.cta_s.o) { this.embeddedPosition.cta_s = this.embeddedScrollData.cta_s.o; }
        
        // Round to Whole Number
        this.embeddedPosition.cta_s = this.embeddedPosition.cta_s | 0;
        
        // Don't Animate if Side-CTA hasn't Moved
        if (this.embeddedPosition.cta_s !== this.embeddedScrollData.cta_s.p) {
            this.embeddedScrollData.cta_s.p = this.embeddedPosition.cta_s;
            
            // Slide Behavior Only
            this.embeddedScrollData.cta_s.$e.stop().animate({'margin-top': this.embeddedPosition.cta_s}, this.animDuration.slide);
        }
    }
    
    // Find Existing Blocking-CTAs
    var $documentBlockingCta = $(Hubs.Config.cta.elements.itemBlockCta);
    if (!this.embeddedScrollData.cta_b.f && !$documentBlockingCta.length) {
        this.embeddedScrollData.cta_b.$e = $(Hubs.Config.cta.elements.blockCta);
        if (this.embeddedScrollData.cta_b.$e.length) {
            this.embeddedScrollData.cta_b.f = true;
        }
        if (this.embeddedScrollData.cta_b.f) {
            this.embeddedScrollData.cta_b.h = this.embeddedScrollData.cta_b.$e.outerHeight(true);
            if (this.embeddedScrollData.cta_b.h < 50) {
                this.embeddedScrollData.cta_b.h = this.embeddedScrollData.cta_b.$e.find('.tile.cta').outerHeight();
            }
            if (this.embeddedScrollData.cta_b.o < 1) {
                this.embeddedScrollData.cta_b.o = this.embeddedScrollData.sheader.h + 20;
            }
        }
    }

    // Keep Blocking-CTA in View
    if (this.embeddedScrollData.cta_b.f) {
        // Calculate New Position of Blocking-CTA
        this.embeddedPosition.cta_b = this.viewportData.scrollTop - this.viewportData.offsetTop + this.embeddedScrollData.cta_b.o + this.absoluteOffset.top;
        
        // Ensure Blocking-CTA stays within Bottom Bounds of Frame
        if (this.embeddedPosition.cta_b + this.embeddedScrollData.cta_b.h + this.embeddedScrollData.footer.h + 40 > this.documentHeight) {
            this.embeddedPosition.diff = this.embeddedPosition.cta_b + this.embeddedScrollData.cta_b.h + this.embeddedScrollData.footer.h + 40 - this.documentHeight;
            this.embeddedPosition.cta_b -= this.embeddedPosition.diff;
        }
        
        // Ensure Blocking-CTA stays within Top Bounds of Frame
        if (this.embeddedPosition.cta_b < this.embeddedScrollData.cta_b.o) { this.embeddedPosition.cta_b = this.embeddedScrollData.cta_b.o; }
        
        // Round to Whole Number
        this.embeddedPosition.cta_b = this.embeddedPosition.cta_b | 0;
        
        // Don't Animate if Blocking-CTA hasn't Moved
        if (this.embeddedPosition.cta_b !== this.embeddedScrollData.cta_b.p) {
            this.embeddedScrollData.cta_b.p = this.embeddedPosition.cta_b;
            
            // Wait until next event loop before triggering animation
            Hubs.root.setTimeout($.proxy(function() {
                // Slide Behavior Only
                this.embeddedScrollData.cta_b.$e.stop().animate({'top': this.embeddedPosition.cta_b}, this.animDuration.slide);
            }, this), 0);
        }
    }
    
};


/**
 * 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Embedded.prototype.scrollSharing = function() {
    // Find Any Existing Share Element
    if (!this.embeddedScrollData.share.f && Hubs.Config.labOptions.topMenu) {
        this.embeddedScrollData.share.$e = $('.addthis_toolbox');
        if (this.embeddedScrollData.share.$e.length) {
            this.embeddedScrollData.share.f = true;
        }
        if (this.embeddedScrollData.share.f) {
            this.embeddedScrollData.share.h = this.embeddedScrollData.share.$e.outerHeight(true);
        }
    }
    
    // Keep Share Element in View
    if (this.embeddedScrollData.share.f) {
        // Calculate New Position of Sharing Element
        if (this.embeddedScrollData.share.o < 1) {
            this.embeddedScrollData.share.o = this.embeddedScrollData.sheader.h + 20;
            this.embeddedScrollData.share.p = this.embeddedScrollData.share.o;
        }
        this.embeddedPosition.share = this.viewportData.scrollTop - this.viewportData.offsetTop + this.embeddedScrollData.share.o + this.absoluteOffset.top;
        
        // Ensure Menu stays within Bottom Bounds of Frame
        if (this.embeddedPosition.share + this.embeddedScrollData.share.h > (this.levelThreeBottom + 160)) {
            this.embeddedPosition.diff = this.embeddedPosition.share + this.embeddedScrollData.share.h - (this.levelThreeBottom + 160);
            this.embeddedPosition.share -= this.embeddedPosition.diff;
        }
        
        // Ensure Menu stays within Top Bounds of Frame
        if (this.embeddedPosition.share < this.embeddedScrollData.share.o) { this.embeddedPosition.share = this.embeddedScrollData.share.o; }
        
        // Round to Whole Number
        this.embeddedPosition.share = this.embeddedPosition.share | 0;
        
        // Don't Animate if Menu hasn't Moved
        if (this.embeddedPosition.share !== this.embeddedScrollData.share.p) {
            this.embeddedScrollData.share.p = this.embeddedPosition.share;
            
            // Slide Behavior Only
            this.embeddedScrollData.share.$e.stop().animate({'top': this.embeddedPosition.share}, this.animDuration.slide);
        }
    }
};

/**
 * pickEmbedOptionParams():
 *   Pick embed options from current url's query string and return as an object.
 *
 * @public
 * @this Hubs.App
 * @return {Object} Embed option key-value pairs
 */
Hubs.Embedded.prototype.pickEmbedOptionParams = function () {
    var pickEmbedParams = ['embedded', 'offsetTop', 'offsetBottom', 'hideHeader', 'hideBanner',
        'hideFooter', 'hidePriNav', 'hideSecNav', 'linkBreakOut', 'ignoreLockForPreview'];
    var currentUrlQuery = this.queryString;
    var embedParams = {};

    $.each(pickEmbedParams, function (index, optionName) {
        if (optionName in currentUrlQuery) {
            embedParams[optionName] = parseInt(currentUrlQuery[optionName], 10);
        }
    });
    return embedParams;
};
