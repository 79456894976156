import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';
import cookies from 'js-cookie';

// Bring Shared Logger to Local Object
Hubs.log = Shared.Logger.log;

/**
 * Hubs App
 *     - This is the main Class for the Application.
 *       All control and logic exists here.
 *       Entry point is Hubs.App.initialize()
 *
 * @class App
 * @extends Hubs.Core
 * @classdesc Application Controller
 * @namespace Hubs
 * @constructor
 */
Hubs.App = function(appOptions) {
    // Call Parent Constructor
    Hubs.Core.apply(this, [appOptions]);
    Object.seal(Hubs.Config.knownUser);

    this.ufaCaller = new Hubs.UfaCaller({
        ufaUrl: appOptions.serverUrl.ufa,
        accountId: appOptions.accountId,
        hubId: appOptions.hubId,
        pageType: appOptions.pageType,
        pardotCookie: appOptions.pardotCookie,
        connectedMAPs: appOptions.mapIntegrations,
        streamId: appOptions.streamId || null,
        itemId: appOptions.itemId || null,
        authorId: appOptions.authorId || null,
        requireOptIn: Hubs.UfaCaller.ufaRequiresOptIn(appOptions)
    });

    // Populates the MAP visitor cache with any known visitor data.
    Hubs.UfaCaller.enqueueTrackables({
        marketo: function() { return cookies.get('_mkto_trk'); },
        eloqua: function() { return cookies.get('eloquautk'); },
        hubspot: function() { return cookies.get('hubspotutk'); },
        pardot: function() { return cookies.get(appOptions.pardotCookie); },
    }, function(){
        Shared.ajaxFetch(Hubs.Config.serverUrl.ping, null, 'GET', true, null, null);
    }, 40); // 40 retries = 20s

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize       = null;

    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */

    this.hubState = {
        collections: new Hubs.SessionVariable('collections'),
        previousState: new Hubs.SessionVariable('previousState'),
        userInterface: new Hubs.CookieVariable('uiState') // We use a cookie here because the server needs this information too.
    }

    // For backwards compatibility with custom code, keep old ajax based state up to date with new session state.
    // We add the hooks here, before variable initialization, because we want these hooks to catch inital values being set.
    // Start backwards compatibility
    this.hubState.collections.on('set', $.proxy(function (e, newState) {
        this.collectionState = newState;
    }, this));

    this.hubState.previousState.on('set', $.proxy(function (e, newPreviousState) {
        this.previousItemId = newPreviousState['itemId'];
        this.previousCollectionId = newPreviousState['collectionId'];
        this.previousCollectionType = newPreviousState['collectionType'];
    }, this));
    // End backwards compatibility

    /** @member {Bool} firstPage Whether or not the current page is the first page */
    this.firstPage           = true;

    /** @member {String} pageType The Current Page Type being Displayed */
    this.pageType            = Hubs.PAGE_TYPE_HUB;

    /** @member {String} collectionType The Current Collection Type being Displayed (Only when PageType is a Collection) */
    this.collectionType      = Hubs.COLLECTION_TYPE_NONE;

    /** @member {String} itemType The Current Item Type being Displayed */
    this.itemType            = null;

    /** @member {Number} collectionCount The Number of Items in the Current Collection (for lazy loading more items) */
    this.collectionCount     = 0;

    /** @member {Number} currentCollectionId The ID of the Current Collection Type being Displayed */
    this.currentCollectionId = 0;

    /** @member {Object} collectionState The viewed Page number and Scroll values being Displayed  */
    // NOTE: This exists only for backwards compatibility. This value is now stored in this.hubState.collections
    this.collectionState     = this.getCollectionState();

    /** @member {Number} currentItemId The ID of the Current Item being Displayed */
    this.currentItemId       = 0;

    /** @member {Array} currentItemTags A list of tags associated with the Current Item being displayed */
    this.currentItemTags      = null;

    /** Formerly previousItemId, The ID of the Last Item which was displayed */
    if (!this.hubState.previousState.get('itemId')) {
        // Only initialize this variable if it is the start of a new session
        this.hubState.previousState.set('itemId', 0);
    }

    /** Formerly previousCollectionId, The ID of the Previous Collection Type Displayed */
    if (!this.hubState.previousState.get('collectionId')) {
        // Only initialize this variable if it is the start of a new session
        this.hubState.previousState.set('collectionId', 0);
    }

    /** Formerly previousCollectionType, The Type of the Previous Collection Displayed */
    if (!this.hubState.previousState.get('collectionType')) {
        // Only initialize this variable if it is the start of a new session
        this.hubState.previousState.set('collectionType', Hubs.COLLECTION_TYPE_NONE);
    }

    /** @member {Object} $appContainer A jQuery Element Handle to the Container DIV of the Application  */
    this.$appContainer       = null;

    /** @member {Object} $$loadingOverlay A jQuery Element Handle to the Loading Overlay DIV of the Application  */
    this.$loadingOverlay     = null;

    /** @member {Object} $loadingIndicator A jQuery Element Handle to the Loading Indicator DIV of the Application  */
    this.$loadingIndicator   = null;

    /** @member {Object} relatedCarousel A reference to the Carousel Object for the Content Page Related Items */
    this.relatedCarousel     = null;

    /** @member {Boolean} fullDescDisplayed The Display state of the Description for Secondary Content used for Toggling the Display */
    this.fullDescDisplayed   = false;

    /** @member {Boolean} mobileNavClicked Flag for Allowing Body Clicks to Close Side Menu */
    this.mobileNavClicked    = false;

    /** @member {Object} history A reference to the global history instance */
    this.history             = Hubs.root.history;

    /** @member {Object} lazyLoader A reference to the LazyLoader Object for Collection Pages */
    this.lazyLoader          = null;

    /** @member {Object} search A reference to the Search Object */
    this.search              = null;

    /** @member {Number} time of last request ms **/
    this.pageTimer           = Date.now();

    /** @member {number} ID of the Timer for the Delayed Initial Fullscreen Mode for Flipbooks */
    this.initialFullscreenTimer = 0;

    /** @member {Number} shared throttle for events **/
    this.throttle            = -1;

    /** @member {Object} container for url querystring parameters **/
    this.queryString = {};

    /** @member {Number} recoTracking The ID for Recommendation Tracking */
    this.recoTracking       = null;

    /** @member {String} recoVisual The placement of where the item was displayed (panel, carousel, next) */
    this.recoVisual = null;

    /** @member {Boolean} for itemPreview enabled or not **/
    this.itemPreviewEnabled = false;

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */

    /**
     * Initialize the Application
     *     - Parse Collections, Determine Page Type, Build Common Elements,
     *       Initialize Events, Initialize Required Components
     *
     * @private
     * @this Hubs.App
     * @param {Object} [options] Configuration Settings for the Class
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        if (!Hubs.Config.isPreventAnalyticsCollectionEnabled) {

            this.ufaCaller.init();

            var startTracking = function(ufaCaller) {
                ufaCaller
                    .trackPageView()
                    .trackVisitorData()
                    .trackTimeOnPage();
            };

            var isCtaPage = Hubs.Config.pageType === "cta";

            if (isCtaPage && window.IntersectionObserver) {
                var body = document.querySelector('body');
                var observer =
                    new IntersectionObserver(
                        function(entries) {
                            var isObserving = entries.some(function(entry) { return entry.isIntersecting });
                            if (isObserving) {
                                observer.unobserve(body);
                                startTracking(Hubs.appInstance.ufaCaller);
                                Hubs.appInstance.ufaCaller.trackLazyLoadImpressions();
                            }
                        },
                        {
                            root: null,
                            rootMargin: '0px',
                            threshold: 0.5,
                        }
                    );
                observer.observe(body);
            } else {
                startTracking(this.ufaCaller);
            }

            Hubs.Events.on('recoPanelOpen', function() {
                Hubs.appInstance.ufaCaller.trackRecoPanelImpressions();
            });

            Hubs.Events.on('itemsLoaded', function() {
                Hubs.appInstance.ufaCaller.trackLazyLoadImpressions();
                Hubs.appInstance.ufaCaller.trackLazyLoadScrollDepth();
            });

            Hubs.Events.on('ctaActivateUfaCollector', function(ctaId, ctaTile) {
                Hubs.appInstance.ufaCaller.ctaId = ctaId;
                Hubs.appInstance.ufaCaller.ctaTile = ctaTile;
                Hubs.appInstance.ufaCaller.trackCtaFormActivation();
            });

            Hubs.Events.on('ctaFormSubmitSuccess', function (ctaId, mappedData, ctaName, ctaTile) {
                if (Hubs.isMobile()) {
                    // "this" picks up wrong (mobile overlay) dom element on mobile
                    Hubs.appInstance.ufaCaller.ctaTile = $('#hub-cta-' + ctaId + '.active-mobile-overlay');
                } else {
                    Hubs.appInstance.ufaCaller.ctaTile = ctaTile;
                }

                Hubs.appInstance.ufaCaller.ctaId = parseInt(ctaId);
                Hubs.appInstance.ufaCaller.trackCtaFormSubmit();
            });

            // Link CTA clicks
            // TODO: add proper hub event for link cta click
            $('#hubs-container').on('click', 'a.cta-button', function() {
                var ctaUrl = this.getAttribute('href')
                Hubs.appInstance.ufaCaller.ctaId = this.dataset.ctaId;
                Hubs.appInstance.ufaCaller.ctaTile = this;
                Hubs.appInstance.ufaCaller.trackCtaLinkClick(ctaUrl);
            });

        }

        // Update Logging/Profiling State
        Shared.Logger.enabled = Hubs.Config.enableDebugger;

        // Get App Container Element
        this.$appContainer = $(Hubs.Config.containers.app);
        if (!this.$appContainer.length) {
            return Shared.Logger.log({'msg': 'app-invalid-container', 'type': Shared.LVL_ERROR});
        }

        // When we close fullscreen mode on a flipbook, some cleanup needs to be done.
        // If fullscreen is closed via the esc key, the cleanup wont happen.
        // So, listen for fullscreen change and call Shared.exitFullscreen() if we just exited fullscreen.
        $(window).on('fullscreenchange mozfullscreenchange webkitfullscreenchange MSFullscreenChange', function () {
            // Only do this for flipbooks! This will break videos in safari.
            if (Hubs.appInstance.itemType !== 'uberflip') {
                return;
            }

            var isFullScreen = document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement

            if(!isFullScreen){
                Shared.exitFullscreen();
            }
        });

        // Add Localization to Logger
        Shared.Logger.setL10N(Hubs.DEBUG_MSG);

        // Debug Statement
        Shared.Logger.log('app-init');

        // Init Search Tool
        this.search = new Hubs.Search(this);

        // Store Hubs Data Locally
        this.pageType = Hubs.Config.pageType;

        // Initialize the Splash Screen
        var hasSplashScreen = this.hubState.userInterface.get('hasHeroBanner');
        var dismissedSplashScreen = this.hubState.userInterface.get('bannerDismissed');

        if (hasSplashScreen && !dismissedSplashScreen){
            this.initSplashScreen(this.pageType);
        }

        // Determine Type of Page to Build
        this.determinePageType();

        // Update Page-Width Element
        Hubs.updatePageWidthElement(this.pageType);

        // Update Size of Main Sharing Menu
        this.resizeHubShare();

        // Vertically align header elements
        this.repositionHeader();

        // Update Position of Sharing on Level 3 Items
        this.repositionLevel3Sharing();

        // Resize instagram video
        this.resizeInstagramVideo();

        // Reposition CTA for Item Views
        this.repositionCta();

        // Initialize Events
        this.initEvents();

        // Initialize CTAs
        this.initCTAs();

        // Desktop Navigation
        this.desktopNav();

        // Mobile Navigation
        this.mobileNav();

        // signal for our metrics to be persisted
        this.signalMetricsTemp();

        if(this.pageType === Hubs.PAGE_TYPE_ITEM) {
            this.loadExtraInfo();
        }

        // Add Analytics Codes for Tracking
        Shared.Tracker.addAnalyticsCodes(Hubs.Config.analyticsCodes);

        // Track Hubs Open
        Shared.Tracker.trackPage(Hubs.root.self.location.href, this.history.state);

        // Store query parameters
        this.queryString = Shared.getQueryParams();

        // Continue from previously set collection page number and scrollTop
        this.initCollectionState();

        // Prettify the url
        this.cleanUrlQueryString();

        // save params that may be used for CTA hidden fields
        this.persistQueryParamsForCtaFields();

        // Debug Statement
        Shared.Logger.log('app-init-end');

        // When in iframe mode (Embedded Hubs or Preview Mode), send "ready" message to iframeResizer.js
        if (Hubs.root.parent) {
            // Emit a custom event to let parent window know app is ready.
            Hubs.root.parent.postMessage('Hubs.Initialized', '*');
        }
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();
};
Hubs.App.prototype = new Hubs.Core();
Hubs.App.prototype.constructor = Hubs.App;
// End of Hubs.App


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Post-Initialization of the Application
 *     - Occurs after the Application has built all necessary elements,
 *       and loaded initial resources.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.postInit = function() {
    // Debug Statement
    Shared.Logger.log('app-post-init');

    // Get jQuery Handle to DOM Element for Loading Indicator
    this.$loadingOverlay = $(Hubs.Config.containers.loadingOverlay);
    this.$loadingIndicator = $(Hubs.Config.containers.loadingIndicator);

    // update page specific elements like links, carousel, ctas, etc.
    this.updatePageSpecific();

    if (this.pageType === Hubs.PAGE_TYPE_ITEM && this.isRecommendationEnabled()) {
        // set up recommendation rule things
        this.setUpRecommendations(this.initAsyncPageElements.bind(this));
    } else if (this.pageType === Hubs.PAGE_TYPE_ITEM && this.itemPreviewEnabled) {
        this.enableItemPreview();
    }

    // Update MoveToTop Link Class
    this.updateMoveToTopClass();

    // Post-Init for Embedded Hubs
    this.initEmbedded();

    // Finalize the Splash Screen behaviour for Desktop/Mobile
    this.finalizeSplashScreen();

    // Debug Statement
    Shared.Logger.log('app-post-init-end');

    // Call Hubs-Loaded Event
    Shared.onNextEventLoop(function() {
        if (typeof Hubs.onLoad === 'function') {
            Shared.onNextEventLoop(Hubs.onLoad, this);
        }

        // emit load event
        Shared.onNextEventLoop(Hubs.Events.trigger.bind(this, 'load'), this);
    }, this);
};

/**
 * Initialize Events for the Hubs Application
 *   - Root level events set on window object
 *   - Initializes the ImagesLoaded component and fires Post-Init
 *     from here, when all resources are loaded.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.initEvents = function() {
    var $win = Shared.$('window');
    var $body = Shared.$('body');
    var $largeHeader = $(Hubs.Config.containers.largeHeader);
    var $mainContent = Shared.$(Hubs.Config.containers.mainContent);
    var $footer = Shared.$(Hubs.Config.containers.footer);
    var $moveToTop = Shared.$(Hubs.Config.containers.moveToTop);
    var $chevron = $(Hubs.Config.containers.splashChevron);
    var imgLoad = null;

    // Debug Statement
    Shared.Logger.log('events-init');

    // Load Images
    if ($largeHeader.length) {
        imgLoad = imagesLoaded($largeHeader[0]);
        imgLoad.on('always', $.proxy(function(instance, image){
            if (this.initialImagesLoaded) {return;}
            this.initialImagesLoaded = true;

            this.repositionHeader();

            // Call Post-Init of App
            this.postInit();
        }, this));

    } else {
        this.postInit();
    }

    // Iframe Load Event from Embedded Hub
    if (Hubs.isEmbedded()) {
        Shared.$('window').on('loadData.Hubs.App', $.proxy(function () {
            // Check if starting in expanded mode, requires time delay
            this.initialDelayedFullscreen();
        }, this));
    }

    // Bind to StateChange Event
    $(Hubs.root).on(
        'beforeunload',
        function (event, newHistoryState, title, url) {
            this.setPreviousStateOnExit();
            this.setCollectionStateOnExit();

            // Hide Loading Indicator
            if (this.$loadingOverlay) this.$loadingOverlay.hide();
        }.bind(this)
    );

    // Apply Margin to Bottom of Content to Account for Height of Sticky Footer
    if (Hubs.Config.labOptions.stickyFooter && $footer.length && $mainContent.length) {
        $mainContent.css({'margin-bottom': $footer.outerHeight()});
    }

    // Splash Screen Arrow
    if ($chevron.length) {
        $chevron.on(Hubs.CLICK_EVENT, $.proxy(function() {
            // Hide Splash Screen and Restore Scrolling
            this.removeHeroHeader(true);
        }, this));
    }

    // "Move to Top" link events
    if ($moveToTop.length) {
        // Click Event for "Move to Top" link
        $moveToTop.addClass('hooked').on(Hubs.TAP_EVENT, $.proxy(function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (Hubs.isEmbedded() && 'parentIFrame' in window) {
                window.parentIFrame.scroll('top');
            } else {
                Shared.scrollTop(0, 750);
            }
            return false;
        }, this));
    }

    // Window Scroll Event
    $win.on('scroll.Hubs', $.proxy(this.scrollEvent, this));

    // Window Resize/Orientation Events
    $win.on('debouncedresize.Hubs orientationchange.Hubs', $.proxy(this.resizeEvent, this));

    $mainContent.on('click', '.fullscreen-controls.underneath .download-pdf', $.proxy(function() {
        // update metrics when pdf downloaded
        var flipbookId = $('[data-flipbookId]').attr('data-flipbookId');
        this.pdfDownload(flipbookId);
    }, this));

    $win.on('pageshow', function () {
        if (this.$loadingOverlay) this.$loadingOverlay.hide();
    }.bind(this));
};

/**
 * Initializes the Flipbook Overlay Control Events
 *     - When a Flipbook is Opened (Desktop) a Button is placed
 *       as an overlay on the Flipbook to allow the User to return to Hubs.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.initDocEvents = function() {
    var $container = $(Hubs.Config.containers.flipbookContainer);
    var $controls = $(Hubs.Config.containers.fullscreenControls);

    // Debug Statement
    Shared.Logger.log('doc-events-init');

    // Fullscreen Overlay Controls
    $controls.find('.fullscreen-open, .fullscreen-close').on(Hubs.TAP_EVENT, $.proxy(function(e) {
        e.stopPropagation();
        this.toggleFullscreen();
        return false;
    }, this));

    var flipbookOrigin;

    try {
        var flipbookUrl = $container.find('iframe').attr('src');
        // prepend protocol if it has been stripped off
        if (/^\//.test(flipbookUrl)) {
            flipbookUrl = window.location.protocol + flipbookUrl;
        }
        flipbookOrigin = new URL(flipbookUrl).origin;
    } catch(e) {
        Shared.Logger.log('error getting flipbook origin: ' + e.message);
    }

    window.addEventListener(
        'message',
        $.proxy(function (event) {
            if (event.origin !== flipbookOrigin) return;

            if (event.data.type !== Shared.MESSAGE_TYPES.FLIPBOOK_EVENT) return;

            if (!Hubs.Config.isPreventAnalyticsCollectionEnabled && Hubs.appInstance.ufaCaller) {
                switch(event.data.eventType) {
                    case 'view_flipbook_pages':
                        event.data.pageIds.forEach(function(pageId, index){
                            var pageNumber = event.data.pageNumbers[index];
                            Hubs.appInstance.ufaCaller.trackFlipbookPageView(event.data.flipbookId, pageId, pageNumber);
                        });
                        break;
                    default:
                        break;
                }
            }
        }, this),
        false
    );

    // Check if starting in expanded mode, requires time delay
    if (!Hubs.isEmbedded()) {
        this.initialDelayedFullscreen();
    }
};

/**
 * display Item Page in Preview mode
 *
 * @public
 * @return undefined
 */
Hubs.App.prototype.enableItemPreview = function() {
    var $article = $('section.level-three > .entry-wrapper');
    var $itemNextPrev = $('.item-next-prev');
    var $comments = $('.comments-container');

    var sTop = window.scrollY,
        aTop = $article.offset().top,
        artH = $article.innerHeight(),
        winH = $(window).innerHeight();

    if( artH - sTop + aTop  > winH ) {
        $article.addClass('item-preview').css('max-height', sTop - aTop + winH + 100);
        $('<div/>').attr('id', 'preview-read-more')
            .attr('class', 'item-preview-div')
            .html('<a href="javascript:void(0)" id="item-preview-link" class="btn btn-white item-preview-link">'+ Hubs.Config.itemPreviewButtonLabel +'</a>')
            .insertAfter('article');
        $comments.hide();
        $itemNextPrev.hide();

        //clear preview on button click
        $('#item-preview-link').on('click', function () {
            $article.removeClass('item-preview').css('max-height','none');
            $(this).parent().remove();
            $comments.show();
            $itemNextPrev.show();
        });
    }
};

/**
 * Toggles Fullscreen Mode of a Hub
 *
 * @public
 * @this Hubs.App
 * @param {Boolean} fullscreen A flag to Toggle Fullscreen
 * @return undefined
 */
Hubs.App.prototype.toggleFullscreen = function(fullscreen, pseudo) {
    var $container = $(Hubs.Config.containers.flipbookContainer);

    // Determine which way to Toggle if not specified
    if (fullscreen === undefined) {
        fullscreen = !(Shared.isBrowserFullscreen() || Shared.isPseudoFullscreen());
    }

    // Check if we want to Force Pseudo-Fullscreen Mode
    //  (Browser Fullscreen Mode must be initiated by user-gesture,
    //   therefore to start out in Fullscreen Mode when the page is
    //   loaded we must fake it.)
    if (pseudo === undefined) {
        pseudo = Shared.isPseudoFullscreen();
    }

    if (fullscreen) {
        if (pseudo || Shared.enterFullscreen($container[0]) === false) {
            this.togglePseudoFullscreen(fullscreen);
        }
    } else {
        if (Shared.isPseudoFullscreen() || Shared.exitFullscreen() === false) {
            this.togglePseudoFullscreen(fullscreen);
        }
    }
};

/**
 * Toggles Fullscreen Mode Overlay Elements of a Hub
 *
 * @public
 * @this Hubs.App
 * @param {Boolean} fullscreen True to Mark Overlay Elements as Fullsceen; false to remove Marks
 * @return undefined
 */
Hubs.App.prototype.togglePseudoFullscreen = function(fullscreen) {
    var $container = $(Hubs.Config.containers.flipbookContainer);
    var $itemContainer = $(Hubs.Config.containers.itemContent);

    // Mark Containers with Fullscreen State
    if (fullscreen) {
        Shared.$('body').addClass('pseudo-fullscreen');
        $container.addClass('fullscreen');
        $itemContainer.addClass('fullscreen-doc');
    } else {
        Shared.$('body').removeClass('pseudo-fullscreen');
        $container.removeClass('fullscreen');
        $itemContainer.removeClass('fullscreen-doc');
    }

    // Update Body Scrolling
    Hubs.setBodyScroll(!fullscreen);

    // Notify Parent Iframe of Embedded Hubs
    if (Hubs.isEmbedded() && 'parentIFrame' in window) {
        window.parentIFrame.fullscreen(fullscreen ? 1 : 0);
    }
};

Hubs.App.prototype.initialDelayedFullscreen = function() {
    var $container = $(Hubs.Config.containers.flipbookContainer);
    var $blockingCta = $(Hubs.Config.cta.elements.blockCta);
    var isBlockedByCta = $blockingCta.length && $blockingCta.is(':visible');
    this.initialFullscreenTimer = Hubs.root.setTimeout($.proxy(function () {
        if ($container.hasClass('initial-expanded') && !isBlockedByCta) {
            this.toggleFullscreen(true, true);
            $container.removeClass('initial-expanded');
        }
        this.initialFullscreenTimer = 0;
    }, this), 2000);
};

/**
 * Observes and Responds to the Resize Events fired on the Global Window Object or App Container
 *     - Also fires for the OrientationChanged event, as the behaviour is essentially the same.
 *
 * @public
 * @this Hubs.App
 * @param {Object} eventData The Event Data associated with the Event
 * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
 */
Hubs.App.prototype.resizeEvent = function(eventData) {
    // Debug Statement
    Shared.Logger.log('app-resize');

    // Delay Event so that DOM Elements are updated
    Hubs.root.setTimeout($.proxy(function() {
        // Responsive Resize
        this.repositionHeader();
        this.resizeImageTiles();
        this.repositionLevel3Sharing();
        this.resizeInstagramVideo();
        this.repositionCta();
        this.resizeCtaForms();
        this.responsiveCtaText();
        this.resizeHubShare();
        // Search Resize
        this.search.resize(eventData);

        // Debug Statement
        Shared.Logger.log('app-resize-end');

        // Call Hubs-Loaded Event
        if (typeof Hubs.onResize === 'function') {
            Shared.onNextEventLoop(Hubs.onResize, this);
        }

        // emit optimized resize event
        Shared.onNextEventLoop(Hubs.Events.trigger.bind(this, 'resize'), this);

    }, this), 125); // just after carousel resize event

    return true;
};

/**
 * Observes and Responds to the Scroll Events fired on the Global Window Object or App Container
 *
 * @public
 * @this Hubs.App
 * @param {Object} eventData The Event Data associated with the Event
 * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
 */
Hubs.App.prototype.scrollEvent = function(e, scrollTop, offsetTop, viewportX, viewportY) {
    var $win = Shared.$('window');
    var $doc = Shared.$('document');
    var $body = Shared.$('body');
    var $moveToTop = Shared.$(Hubs.Config.containers.moveToTop);
    var $heroHeader = $(Hubs.Config.containers.largeHeader);
    var documentHeight = $doc.height();

    if (scrollTop === undefined) { scrollTop = $win.scrollTop(); }
    if (offsetTop === undefined) { offsetTop = 0; }
    if (viewportX === undefined) { viewportX = $win.width(); }
    if (viewportY === undefined) { viewportY = $win.height(); }

    // By disabling pointer-events while scrolling, can achieve ~60fps during scroll.
    // Note: this may also be a bug fix for scrolling on pages with iFrame Embeds,
    // did not seem safe to remove it entirely
    if (!Shared.IOS9) {
        clearTimeout(this.scrollTimer);
        if (!$body.hasClass('disable-hover')) {
            $body.addClass('disable-hover');
        }
        this.scrollTimer = setTimeout(function () {
            $body.removeClass('disable-hover');
        }, 100);
    }

    // Hero Header Behaviour
    if (!Hubs.Config.labOptions.permHeader && !Hubs.Config.labOptions.noHeader && $heroHeader.length) {
        // Hide Large Header when Scrolled past
        if ((scrollTop - offsetTop) >= $heroHeader.outerHeight() || (scrollTop - offsetTop) + viewportY === documentHeight) {
            //Hubs.restoreBodyScroll();
            this.removeHeroHeader(false);
        }
    }

    // Update Search Class
    this.search.scroll(scrollTop, offsetTop, viewportX, viewportY);

    // Show "Move to Top" link after scrolling a bit
    if (!Hubs.isEmbedded()) {
        if ($moveToTop.length) {
            if ((scrollTop - offsetTop) > 300) {
                $moveToTop.addClass('show');
            } else {
                $moveToTop.removeClass('show');
            }
        }
    }

    // Call Hubs-Scroll Event
    if (typeof Hubs.onScroll === 'function') {
        Shared.onNextEventLoop(Hubs.onScroll, this);
    }

    // emit optimized scroll event
    Shared.onNextEventLoop(Hubs.Events.trigger.bind(this, 'scroll'), this);
};

/**
 * Enable Desktop Navigation
 *   - Attaches Hover Events for the Desktop Navigation
 *     (CSS behaviour alone is jumpy)
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.desktopNav = function() {
    var $navItems, $shareButton, $hubSelection, $localeSelector;
    if (Hubs.isMobile()) { return; }

    // Debug Statement
    Shared.Logger.log('nav-desktop-init');

    // Desktop-nav specific - showing and hiding items on hover
    $navItems = Shared.$(Hubs.Config.containers.leftNav[2]).find('li.collapsed');
    if ($navItems.length) {
        Shared.enableHoverMenu({
            'hover' : $navItems,
            'hoverOverCallback' : function(e, $menuEl) {
                $navItems.removeClass('hover');
                window.setTimeout(Hubs.positionLeftNav, 10);
            }
        });
    }

    // Desktop Share Menu
    $shareButton = $('.share-toggle');
    if ($shareButton.length) {
        Shared.enableHoverMenu({
            'hover' : $shareButton,
            'menu' : function(e, options) {
                var $menuEl = $('.share-item');
                if ($menuEl.length) { return $menuEl; }
                return $('.share-hub');
            },
            'hoverOverCallback' : $.proxy(function(e, $menuEl) {
                Hubs.positionShareNav();
                this.search.hideDropDown();
            }, this),
            'isSeparateMenu' : '.share-hub, .share-item'
        });
    }

    // Desktop language/locale selector
    $localeSelector = $('.locale-selector');
    if ($localeSelector.length) {
        Shared.enableHoverMenu({
            'hover' : $localeSelector,
            'hoverOverCallback' : function() {
                $localeSelector.removeClass('hover');
                window.setTimeout(Hubs.positionLeftNav, 10);
            }
        });
    }
};

/**
 * Enable the Mobile Navigation
 *   - Displays the Mobile Navigation and Hides the Desktop Navigation
 *   - Attaches Events for the Mobile Navigation
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.mobileNav = function() {
    var $mobileNav = Shared.$('.mobile-nav');

    // Debug Statement
    Shared.Logger.log('nav-mobile-init');

    // Toggling the mobile navigation and sharing menus
    $('.nav-toggle').on(Hubs.CLICK_EVENT, $.proxy(function(e) {
        e.preventDefault();
        var scrollTo;
        if ($mobileNav.length) {
            $mobileNav.fadeIn();
            scrollTo = $mobileNav.find('.overlay-scroller');
        }
        Hubs.restrictBodyScroll();
        Shared.scrollTop(0, 0, scrollTo);

        if (Hubs.isEmbedded() && 'parentIFrame' in window) {
            window.parentIFrame.scroll('top');
        }
    }, this));


    if ($mobileNav.length) {
        // Exiting the mobile navigation and sharing menus
        $mobileNav.find('.exit').on(Hubs.CLICK_EVENT, $.proxy(function (e) {
            $mobileNav.fadeOut(400, function () {
                Hubs.closeOpenMenus();
                Hubs.restoreBodyScroll();
            });
        }, this));

        // Expanding and collapsing menu items with child links
        $mobileNav.find('li.collapsed > a').on(Hubs.TAP_EVENT, function (e) {
            e.preventDefault();
            var $el = $(this), $parent = $el.parent('li');
            if ($parent.hasClass('collapsed')) {
                Hubs.toggleHeight(true, $parent.find('.collapsable-section'));
                $parent.removeClass('collapsed').addClass('expanded');
            } else if ($parent.hasClass('expanded')) {
                Hubs.toggleHeight(false, $parent.find('.collapsable-section'));
                $parent.removeClass('expanded').addClass('collapsed');
            }

            // Fix Bug in iOS8 (height of scrolling element does not update when content height changes)
            //  - Force a repaint for this device
            if (Hubs.isMobile() && Shared.IOS8) {
                Hubs.root.setTimeout(function () {
                    $('.overlay-scroller').hide().delay(0).show(0);
                }, 205); // 200ms transition effect
            }
        });
    }

    // Show/Hide the mobile locale language selector
    var localeToggle = $('.locale-toggle');
    if (localeToggle.length) {
        localeToggle.on(Hubs.TAP_EVENT, $.proxy(function(e) {
            var $localeMenu = $('.locale-selector-mobile');
            e.preventDefault();
            if ($localeMenu.hasClass('active')) {
                $localeMenu.removeClass('active');
                return;
            }
            $localeMenu.addClass('active');
        }, this));
    }

    // Tablet-nav specific - showing and hiding items on click
    if (Hubs.isMobile()) {
        $(Hubs.Config.containers.leftNav[2] + '.desktop').find('> li').on(Hubs.TAP_EVENT, 
            $.proxy(function(e) {
            var $el = $(e.currentTarget);
            e.preventDefault();
            this.preventBubbling();
            $(Hubs.Config.containers.leftNav[2] + '.desktop').find('.hover').removeClass('hover');
            if (!$el.find('ul').is(':visible') && !$el.hasClass('hover')) {
                $el.addClass('hover');
                Hubs.positionLeftNav();
            }
        }, this));

        // Toggling the sharing menu
        $('.share-toggle').on(Hubs.TAP_EVENT, $.proxy(function(e) {
            var $menuEl = null;
            e.preventDefault();
            this.preventBubbling();
            $menuEl = $('.share-item');
            if (!$menuEl.length) { $menuEl = $('.share-hub'); }
            $menuEl.addClass('hover');
            Hubs.positionShareNav();
            return false;
        }, this));

        // Allow Propagation for Nested Links
        $(Hubs.Config.containers.leftNav[2] + '.desktop').find('> li > ul > li').on(Hubs.TAP_EVENT, $.proxy(function(e) {
            e.stopPropagation();
            return true;
        }, this));

        // Tapping/Swiping on Document should close any open menus
        Shared.$('body').on('swipemove.Hubs tapone.Hubs', $.proxy(function(e) {
            if (!this.mobileNavClicked && window.navigator.msPointerEnabled === undefined) {
                e.preventDefault();
                e.stopPropagation();
                $('.share-hub, .share-item').removeClass('hover');
                Shared.$(Hubs.Config.containers.leftNav[2]).find('li.collapsed').removeClass('hover');
                return false;
            }
        }, this));
    }
};

/**
 *
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.requestInternalPageChange = function(url, type, title, seoTitle) {
    // Ensure Valid Params
    if (url === undefined) { return; }
    if (type === undefined) { type = 'standard'; }
    if (title === undefined) { title = ''; }
    if (seoTitle === undefined) { seoTitle = ''; }

    // Debug Statement
    Shared.Logger.log('internal-page-change-request');

    // Restore Scrolling on Body
    Hubs.restoreBodyScroll();

    // Kill any existing Ajax Requests
    Shared.cancelAjax(this.historyXhr);

    // Hide any Open Menus
    Hubs.root.setTimeout(Hubs.closeOpenMenus, 300);

    // Reset the container for CTAs to record a view for
    Hubs.CTAs.ctasForViewTracking = [];

    // Handle Mobile Flipbook case
    var isMobileFlipbook = Hubs.isMobile() && $(Hubs.Config.containers.mobileFlipbook).length;
    var flipbookBreakOutCandidate = isMobileFlipbook || Hubs.Config.labOptions.flipbookBreakOut;
    var shouldBreakOutOfFlipbook = flipbookBreakOutCandidate && /uberflip/i.test(type);

    if (shouldBreakOutOfFlipbook) {
        // Mobile has its one off case for flipbooks, store this page view
        // Mobile can't have blocking ctas on mobile as it opens in new page
        Hubs.storePageView(type, this.currentItemId, this.currentCollectionId, false);

        // Open Source URL in New Window
        Hubs.root.open(url, '');
        return;
    }

    // Break out to Top for Embedded Hubs when clicking an Item Link
    var isEmbeddedMode = Hubs.isEmbedded();
    var isPreviewMode = $('body').hasClass('inPreview');
    var linkBreakOut = isEmbeddedMode ? Hubs.Config.embedOptions.linkBreakOut :
        Hubs.Config.labOptions.linkBreakOut;
    var shouldBreakOutOfEmbeddedHub = linkBreakOut && $('body').hasClass('iframe') && !isPreviewMode;

    if (shouldBreakOutOfEmbeddedHub) {
        var metricsParams = this.constructMetricsParams();
        url += (url.match(/\?/g) ? '&' : '?') + $.param(metricsParams);
        Hubs.root.top.location.href = url;
        return;
    }

    // Change Page in current window (no break out)
    // Append url if required: ?embedded=1, ?inPreview=1
    if (isEmbeddedMode) url += (url.match(/\?/g) ? '&' : '?') + 'embedded=1';
    if (isPreviewMode) url += (url.match(/\?/g) ? '&' : '?') + 'inPreview=1';

    // Change Page
    this.requestChangePage({type: type, title: title}, '', url);

    // Update meta title
    $('meta[name=title]').attr('content', seoTitle || title);
};

/**
 * Overrides all Links on Hubs Page
 *     - Hooks all Links so that content can be pulled via Ajax and controlled via History Manager
 *     - Exception; Document links on Small Screen Devices should open in a new window/tab
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.overrideLinks = function() {
    // Debug Statement
    Shared.Logger.log('links-override-init');

    // Internal Links
    // Find all non-hooked internal links
    $('a[data-internal]:not([href^=mailto]):not(.hooked)').each($.proxy(function(idx, el){
        var $anchor = $(el), attr = $anchor.attr('data-internal');
        if (attr === undefined || attr === 'false') { return; }

        // Attach click event to links and mark as "hooked"
        $anchor.addClass('hooked').on(Hubs.CLICK_EVENT, $.proxy(function(e) {
            var $tile;
            var $el = $(e.currentTarget);
            var type = $el.attr('data-internal');
            var flipbookPage = Shared.getQueryParamByName('p');
            var sourceUrl = $el.attr('data-source-url');
            var title = '';
            var seoTitle = '';
            var recotrk = $el.attr('data-recotrk');
            var visual = $el.attr('data-visual');

            // determine if item is recommended & store the id
            if (this.pageType === Hubs.PAGE_TYPE_ITEM &&
                this.isRecommendationEnabled()) {
                var $itemId = Hubs.Recommendations.markItemToHidePublishDate($el);
                if ($itemId) {
                    this.hubState.previousState.set('lastRecoItm', $itemId)
                }
            }

            // Debug Statement
            Shared.Logger.log('internal-link-click');

            // Ignore if Carousel is currently Sliding
            if (this.pageType === Hubs.PAGE_TYPE_ITEM && 
                this.relatedCarousel !== null &&
                    this.relatedCarousel.isSliding()
            ) {
                return false;
            }

            // Prevent Default Action and Event Propagation
            this.preventBubbling();
            e.preventDefault();
            e.stopPropagation();

            // Check for Source URL Attribute
            if (((Hubs.isMobile() && 
                $(Hubs.Config.containers.mobileFlipbook).length) || 
                Hubs.Config.labOptions.flipbookBreakOut) && 
                /uberflip/i.test(type) && sourceUrl !== undefined
            ) {
                $tile = $el.closest('.tile');

                if (flipbookPage !== '') {
                    sourceUrl += '/' + flipbookPage;
                }
                sourceUrl += '?hubItemID='+$tile.attr('data-id');
            } else {
                // Get Correct Text to Display in Title Bar
                title = $el.attr('data-page-title');
                if (title === undefined || !title.length) { title = $el.text(); }
                // Get seo title
                seoTitle = $el.attr('data-seo-title');

                // Get URL of Link
                sourceUrl = $el.attr('href');
            }
            this.setRecoTrackingInformation(recotrk, visual);

            // Change Page Internally
            this.requestInternalPageChange(sourceUrl, type, title, seoTitle);

            // Prevent Event Bubbling
            return false;
        }, this));
    }, this));

    // Find all non-hooked external sharing links
    $('a[data-share]:not(.hooked)').each($.proxy(function(idx, el){
        var $anchor = $(el), attr = $anchor.attr('data-share');
        if (attr === undefined || attr === 'false') { return; }

        // Attach click event to links and mark as "hooked"
        $anchor.addClass('hooked').on(Hubs.TAP_EVENT, $.proxy(function(e) {
            var popupWin, popupTarget = '', 
                $el = $(e.currentTarget), 
                url = $el.attr('href'), type = $el.attr('data-share');

            // Debug Statement
            Shared.Logger.log('external-share-link-click');

            // Prevent Default Action
            this.preventBubbling();
            e.preventDefault();
            e.stopPropagation();

            // Ignore if Carousel is currently Sliding
            if (this.pageType === Hubs.PAGE_TYPE_ITEM &&
                    this.relatedCarousel !== null &&
                    this.relatedCarousel.isSliding()
            ) { 
                return false; 
            }

            if($el.attr('data-addthis-sharing') === undefined) {
                // Open Window for Share Service
                if (Hubs.isMobile() && type === 'email') { popupTarget = '_top'; }

                popupWin = Hubs.root.open(url, 
                    popupTarget, 'left=100,top=100,width=550,height=450,personalbar=0,toolbar=0,scrollbars=1,resizable=1'
                );

                // Force Close the Popup on Desktop
                if (!Hubs.isMobile() && type === 'email') {
                    Hubs.root.setTimeout(function() {
                        if (!popupWin.location || popupWin.location.href === 'about:blank') {
                            popupWin.close();
                        }
                    }, 5000);
                }
            }

            // Close Open Menus
            Hubs.closeOpenMenus();

            // Tracking
            var itmID = ($el.attr('data-item-id') !== undefined) ? $el.attr('data-item-id') : this.currentItemId;
            $.ajax(Shared.absoluteHubUrl(Hubs.Config.serverUrl.socialTracking), {
                type: 'POST',
                data: {
                    hub: Hubs.Config.hubId,
                    col: this.currentCollectionId,
                    itm: itmID,
                    social: type
                }
            });

            // Prevent Event Bubbling
            return false;
        }, this));
    }, this));

    // Find all non-hooked CTA links and add Tracking
    $('.cta-website a:not(.tracked), .home-web-cta a:not(.tracked)').each($.proxy(function(idx, el) {
        var $anchor = $(el),
            ctaId = $anchor.data('ctaId');

        // Attach click event to links and mark as "hooked"
        $anchor.addClass('tracked').one(Hubs.CLICK_EVENT + ".ctaTracking", $.proxy(function(e) {
            // Debug Statement
            try {
                Shared.ajaxFetch(Hubs.Config.serverUrl.ctaTracking, {
                    hub: Hubs.Config.hubId,
                    cta: ctaId,
                    col: this.currentCollectionId,
                    itm: this.currentItemId,
                    prevCol: this.hubState.previousState.get('collectionId'),
                    prevItm: this.hubState.previousState.get('itemId')
                }, 'GET');
            } catch (e1) {} //TODO ignore metrics failures for now

            return true;
        }, this));
    }, this));
};

/**
 * constructMetricsParams():
 *   Returns a list of metrics that must be passed to hubs server on every page change,
 *   appended to targetUrl as query.
 *
 * @private
 * @this Hubs.App
 * @return {Object} Metric parameters
 */
Hubs.App.prototype.constructMetricsParams = function() {
    var metricParams = {
        prevItm: this.currentItemId,
        prevCol: this.currentCollectionId,
        ts: Date.now() - this.pageTimer,
    };

    var isTrackingRecommendations = this.recoTracking && this.recoVisual;
    if (isTrackingRecommendations) {
        metricParams.recotrk = this.recoTracking;
        metricParams.visual = this.recoVisual;
    }

    return metricParams;
};

/**
 * initCollectionState():
 *   If current page is a Collection, load previously saved page number and scrollTop.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.initCollectionState = function() {
    this.clearCollectionState();

    var isCollection = Hubs.COLLECTION_PAGE_TYPES.indexOf(this.pageType) !== -1;
    var page = (this.queryString || {}).page || 0;
    var scrollTop = (this.queryString || {}).scrollTop || 0;

    if (isCollection && (page || scrollTop)) {
        this.setCollectionState(page, scrollTop);
    }
};

    /**
 * isTrackingAllowed():
 *   Check is all privacy groups have been accpeted.
 *   Will return false if one group has not been accpeted
 *
 * @private
 * @this Hubs.App
 * @return boolean
 */
Hubs.App.prototype.isTrackingAllowed = function() {
    var cookie = cookies.get(Hubs.PRIVACY_PREFS_COOKIE_NAME);
    if (!cookie) return false;

    var cookieArr = cookie.split('|');

    // remove the cookie version value - we don't need it
    // remove the banner status value - we don't need it
    cookieArr.splice(0, 2);

    var privacyGroupsAccepted = true;
    var groupAcceptedStatus = 2;

    $.each(cookieArr, function (i, csv) {
        var group = csv.split(',');
        if (parseInt(group[groupAcceptedStatus], 10) === 0) {
            privacyGroupsAccepted = false;
        }
    });

    return privacyGroupsAccepted;
};

    /**
 * removeExcludedParams():
 *   Get the query params, and filter pararms
 *   Return the filtered params
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.removeExcludedParams = function() {
    var toExclude = Hubs.Config.ufQueryStrings;
    var remainingParams = Shared.getQueriesWithout(toExclude);
    return Shared.removeCustomCodeCommands(remainingParams);
}

    /**
 * persistQueryParamsForCtaFields():
 *   When page is initialized, grab Query Params from URL that we need to track
     *  for marketing, and save them to local storage. When Form CTA is submitted,
     *  we will include them in the "extraParams" property.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.persistQueryParamsForCtaFields = function(params) {
    if (this.isTrackingAllowed()) {
        var paramValues = this.removeExcludedParams();
        var storedCtaQueryParams = localStorage.getItem(Hubs.CTA_LOCALSTORAGE_KEY);
        var storedValues = storedCtaQueryParams ? JSON.parse(storedCtaQueryParams) : {};
        var newValuesToStore = $.extend({}, storedValues, paramValues);
        localStorage.setItem(Hubs.CTA_LOCALSTORAGE_KEY, JSON.stringify(newValuesToStore));
    }
}


/**
 * cleanUrlQueryString():
 *   When page is initialized, clean-up the current url query to hide
 *   parameters that don't need to persist after page change has completed.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.cleanUrlQueryString = function() {
    var urlParts = Hubs.root.self.location.href.split('?');

    var noQueryString = urlParts.length === 1;
    if (noQueryString) { return; }

    var remainingParams = this.removeExcludedParams();
    var queryParams = $.param(remainingParams);
    var hash = Hubs.root.self.location.hash;
    var url = urlParts[0] + (queryParams && '?' + queryParams) + hash;

    this.history.replaceState(null, null, url);
};

/**
 * Handle link click events.
 *
 * @public
 * @this Hubs.App
 * @param {Object} data = Info of new history state (type, title)
 * @param {String} title = New page title
 * @param {String} url = New page url
 * @return undefined
 */
Hubs.App.prototype.requestChangePage = function (data, title, url) {
    // NOTE: Don't refactor this function signature. It needs to remain the same for backward-
    // compatibility.

    // Remove Fullscreen Mode when changing pages
    if ($(Hubs.Config.containers.flipbookContainer).hasClass('fullscreen')) {
        this.toggleFullscreen(false);
    }

    // Close Search Results (if open)
    this.search.closeSearch();

    // Close any open menus
    Hubs.closeOpenMenus();

    // Show Loading Overlay
    this.$loadingOverlay.show();

    // Update loading spinner position
    if (Hubs.isEmbedded()) {
        this.repositionLoadingIndicator();
    }

    // Redirect to url
    Hubs.root.self.location.href = this.constructRedirectUrl(url);
};

/**
 * constructRedirectUrl():
 *   Append query parameters to targetUrl when handling page change.
 *
 * @private
 * @this Hubs.App
 * @param {String} targetUrl = New page from link click event (has trailing ?embedded=1)
 * @return {String} Url with query string
 */
Hubs.App.prototype.constructRedirectUrl = function(targetUrl) {
    var metricsParams = this.constructMetricsParams();
    var embedParams = Hubs.isEmbedded() ? $.param(this.pickEmbedOptionParams()) : '';
    var baseUrl = targetUrl.split('?')[0] + '?';
    return baseUrl + $.param(metricsParams) + (embedParams && '&' + embedParams);
};

/**
 * setPreviousStateOnExit():
 *   Used before a page change. Save current state to memory so it can be used for tracking
 *   assist metrics on any CTAs that are submitted on the next page.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.setPreviousStateOnExit = function () {
    this.hubState.previousState.set('collectionId', this.currentCollectionId);
    this.hubState.previousState.set('collectionType', this.collectionType);
    this.hubState.previousState.set('itemId', this.currentItemId);
};

/**
 * setCollectionStateOnExit():
 *   Used before a page change. If current page is a Collection, save page number
 *   and scrollTop to memory, so we can revert to position if user clicks Back browser
 *   button. Also, clear existing memory because we only want this feature on Back click.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.setCollectionStateOnExit = function() {
    var isCollection = this.currentCollectionId !== 0;

    this.clearCollectionState();

    if (this.lazyLoader && isCollection && !Shared.IOS8_1) {
        var page = this.lazyLoader.currentPage;
        var scrollTop = 0;

        if (Hubs.isEmbedded()) {
            var hasIFrameScrollTop = this.viewportData && this.viewportData.scrollTop;
            if (hasIFrameScrollTop) scrollTop = this.viewportData.scrollTop;
        } else {
            scrollTop = Shared.scrollTop();
        }
        this.setCollectionState(page, scrollTop);

        // On page exit, update current url with page number and scrollTop
        var collectionState = Hubs.isEmbedded() ? {page: page, scrollTop: scrollTop} : {page: page};
        var currentUrl = Hubs.root.location.href;
        var appendParams = (currentUrl.match(/\?/g) ? '&' : '?') + $.param(collectionState);

        // Params for page 0 don't change behaviour so don't include them for sake of cleanliness!
        var ignoreParams = !Hubs.isEmbedded() && page === 0;

        if (!ignoreParams) this.history.replaceState(null, null, currentUrl + appendParams);
    }
};

/**
 * setCollectionState():
 *   Save current page number and scrollTop to Collection memory.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.setCollectionState = function(page, scrollTop) {
    var currentUrl = Hubs.root.location.href.split('?')[0];
    var collectionState = {
        page: parseInt(page, 10),
        scrollTop: parseInt(scrollTop || 0, 10)
    };
    this.hubState.collections.set(currentUrl, collectionState);
};

/**
 * getCollectionState():
 *   If current page is a Collection, get page number and scrollTop from memory.
 *
 * @public
 * @this Hubs.App
 * @return {page, scrollTop} or undefined
 */
Hubs.App.prototype.getCollectionState = function() {
    var currentUrl = Hubs.root.location.href.split('?')[0];
    var defaultState = {page: 0, scrollTop: 0};
    return this.hubState.collections.get(currentUrl) || defaultState;
};

/**
 * clearCollectionState():
 *   Clear session memory for collection namespace.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.clearCollectionState = function() {
    this.hubState.collections.clear();
};

/**
 * Determines the Page Type based on the Partial Data
 *     - Accordingly sets internal flags to indicate Page Type and Collection Type
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.determinePageType = function() {
    var $el = $(Hubs.Config.containers.pageIdentifier);

    // Reset Current Collection Details
    this.collectionType = Hubs.COLLECTION_TYPE_NONE;
    this.currentCollectionId = 0;
    this.currentItemId = 0;
    this.collectionCount = 0;

    // Determine Type of Page
    if (!$el.length) {
        this.pageType = Hubs.PAGE_TYPE_HUB;
    } else {
        this.pageType = Hubs[$el.attr('data-page-type')];
        this.collectionType = $el.attr('data-collection-type');
        this.currentItemId = parseInt($el.attr('data-item-id'), 10);
        this.currentItemTags = $el.data('tags');
        this.currentCollectionId = $el.attr('data-collection-id');
        this.currentAuthorId = $el.attr('data-author-id');
        this.itemType = $el.attr('data-item-type');
        this.currentCollectionId = parseInt(this.currentCollectionId, 10) || this.currentCollectionId;
        this.collectionCount = parseInt($el.attr('data-collection-count') || 0, 10);
        this.itemPreviewEnabled = $el.attr('data-item-preview');
    }
};

/**
 * Set the recommendation tracking info. This will be used upon click to
 * to pass these values to the next page.
 *
 * @param recotrk
 * @param visual
 * @see Hubs.App.prototype.constructMetricsParams
 */
Hubs.App.prototype.setRecoTrackingInformation = function(recotrk, visual) {
    this.recoTracking = recotrk || null;
    this.recoVisual = visual || null;
};

/**
 * Updates the Classname on the MoveToTop Element depending on the Page Type
 *     - This is used to provide certain style overrides via CSS for certain Page Types.
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.updateMoveToTopClass = function() {
    var $pageWidthEl = $(Hubs.Config.containers.itemContent);
    var $moveToTopEl = Shared.$(Hubs.Config.containers.moveToTop);
    if ($pageWidthEl.length && $moveToTopEl.length) {
        $moveToTopEl.addClass('item-level');
        if ($pageWidthEl.hasClass('with-cta')) {
            $moveToTopEl.addClass('with-cta');
        }
    } else {
        $moveToTopEl.removeClass('item-level with-cta');
    }
};

/**
 * Updates the Selected Collection Group in the Navigation Menu based on the Current Page Type
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.updateSelectedMenu = function() {
    var $leftNav = Shared.$(Hubs.Config.containers.leftNav[2]),
        $selected = null;

    // Reset Selected Menu
    $leftNav.find('li.selected').removeClass('selected');

    if (this.collectionType === '') {
        this.collectionType = null;
    }

    // Set Selected Menu
    if (this.pageType === Hubs.PAGE_TYPE_HUB) {
        $leftNav.find('li.home').addClass('selected');
    } else {
        if (this.collectionType !== null) {
            if (Hubs.ITEM_COLLECTION_MAP[this.collectionType] === undefined) {
                $leftNav.find('li.' + this.collectionType).addClass('selected');
                $leftNav.find('li.' + this.collectionType + '-' + this.currentCollectionId).addClass('selected');
            } else {
                $leftNav.find('li.' + Hubs.ITEM_COLLECTION_MAP[this.collectionType]).addClass('selected');
                $leftNav.find('li.' + Hubs.ITEM_COLLECTION_MAP[this.collectionType] + '-' + this.currentCollectionId).addClass('selected');
            }

            // Set the custom menu items to selected for that case (they don't use the collection types but rather collection ids)
            $selected = $leftNav.find('li.custom-menu-item[data-collection-id="' + this.currentCollectionId + '"]');
            $selected.addClass('selected');
            $selected.closest('li.custom-menu-item:not(.selected)').addClass('selected');
        }
    }
};

/**
 * Update Page-Specific Elements based on Page Type
 *     - Flipbooks open in an overlayed iframe, so the content behind must still be functional
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.updatePageSpecific = function() {
    // Debug Statement
    Shared.Logger.log('page-update-specific');

    // Update Page-Width Element
    Hubs.updatePageWidthElement(this.pageType);

    if (!this.isRecommendationEnabled()) {
        // Create Carousel Control for Related Items (if exists)
        this.createCarousel('related');
    }

    // Create LazyLoader Control for Collection Items (if exists)
    this.createLazyLoader();

    // Need to make the timestamps appear friendly
    Hubs.setFriendlyTimestamps();

    // Adding form cta handling
    this.initializeFormCtas();

    if (this.pageType === Hubs.PAGE_TYPE_ITEM) {
        // Update Size of Video Players (if exists)
        Hubs.updateVideoSize(this.itemType);

        // Toggle the Show More link for long content (if exists)
        this.toggleShowMore();

        // Fix Content Images in DOM
        Hubs.fixContentImages(this.collectionType, Hubs.Config.containers.itemContent);

        // Fix the next/previous Images in DOM
        Hubs.fixPrevNextImages(Hubs.Config.containers.itemContent);

        // Fix Content Liks in DOM
        Hubs.fixContentLinks(Hubs.Config.containers.itemContent);

        // Load non proxied images
        Hubs.loadNonProxiedImages();

        // Document Type Content (Flipbook)
        if (Hubs.REGEX_FLIPBOOK.test(this.itemType)) {
            this.initDocEvents();
        }

        // On page level, need to re-initialize the addthis toolbar and re-add event listener
        // To check if the script is being blocked by an ad blocker, like Ghostery or AdBlock
        // lets check that addthis.ready exists, after we know the addThis is enabled, this is only dispatched when the API has fully loaded.
        if(Hubs.root.addthis && Hubs.root.addthis.ready && !$('.at300b').length){
            Hubs.root.addthis.toolbox(Hubs.Config.containers.addthisToolbar, 
                {'data_track_addressbar':false, 'data_track_clickback':false});
            Hubs.root.addthis.addEventListener('addthis.menu.share', $.proxy( function(evt){
                // Don't let this event fire multiple times in a row
                if (this.throttle > 0) { return; }
                this.throttle = setTimeout($.proxy(function() { this.throttle = -1; }, this), 200);

                var type = '';
                switch(evt.data.service){
                    case 'facebook_like':
                        type = 'facebook';
                        break;
                    case 'tweet':
                        type = 'twitter';
                        break;
                    case 'linkedin':
                        type = 'linkedin';
                        break;
                }

                if(type.length > 1 ){
                    Shared.Logger.log('addthis share button clicked: ' + type);
                    // Tracking
                    $.ajax(Shared.absoluteHubUrl(Hubs.Config.serverUrl.socialTracking), {
                        type: 'POST',
                        data: {
                            hub: Hubs.Config.hubId,
                            col: this.currentCollectionId,
                            itm: this.currentItemId,
                            social: type
                        }
                    });
                }
            }, this) );
        }
    }

    // activate the privacy banner or hook the privacy page toggles
    this.initPrivacyCookieManagement();

    // Override behaviour of Links. Run after Hubs.fixContentLinks() cause data-internal might change
    this.overrideLinks();

    var hiddenBlockingCta = this.showFormCtas(this.lazyLoader, this.pageType);

    // Store Page View if item type (needs to happen after determining if there's hidden blocking CTA)
    if (this.pageType === Hubs.PAGE_TYPE_ITEM) {
        Hubs.storePageView(this.itemType, this.currentItemId, this.currentCollectionId, hiddenBlockingCta);
    }

    // Images Loaded Event
    Hubs.updateImageTiles(this, '.tile .img');

    // Update Menu Selection
    this.updateSelectedMenu();

    // Update Position of Sharing on Level 3 Items
    this.repositionLevel3Sharing();

    // Resize Instagram Videos
    this.resizeInstagramVideo();

    // Resize Shaing Menu
    this.resizeHubShare();

    // Reposition CTA for Item Views
    this.repositionCta();

    //Reposition the header hero
    this.repositionHeader();

    // Resize All CTA Text
    this.responsiveCtaText();

    // Record CTA views
    Hubs.App.recordCtaViews();

    this.hidePublishDate();

    // Scroll-to position on page change (only non-embedded hubs)
    if (!Hubs.isEmbedded()) {
        var skipScrollWhenHasHashString = Hubs.root.location.hash.length;
        if (skipScrollWhenHasHashString) { return }

        var collectionState = this.getCollectionState();
        var revertCollectionScrollTop = collectionState && collectionState.scrollTop;
        if (revertCollectionScrollTop) Shared.scrollTop(collectionState.scrollTop);
    }
};

/**
 * Callback for the recommendations to set up asynchronously loaded elements
 */
Hubs.App.prototype.initAsyncPageElements = function() {

    // Create Carousel Control for Related Items (if exists)
    this.createCarousel('related');

    // Override behaviour of Links.
    this.overrideLinks();

    // Images Loaded Event
    Hubs.updateImageTiles(this, '.tile .img');

    if (this.pageType === Hubs.PAGE_TYPE_ITEM && this.itemPreviewEnabled) {
        this.enableItemPreview();
    }
};

/**
 * Creates the LazyLoader Control
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.createLazyLoader = function() {
    // Check for LazyLoader Elements
    var $container = $(Hubs.Config.lazyloader.elements.container);
    if ($container.length) {
        // Create LazyLoader Control
        this.lazyLoader = new Hubs.LazyLoader(this);
    }
};

/**
 * Creates a Carousel Control for the Specified Type (Featured, Related)
 *
 * @private
 * @this Hubs.App
 * @param {String} carouselName The name of the Carousel to Create
 * @return undefined
 */
Hubs.App.prototype.createCarousel = function(carouselName) {
    // Check for Carousel Elements
    var config, $container = $('#' + carouselName + '-items');
    if ($container.length) {
        // Update Configs for Carousel
        config = $.extend(true, {}, Hubs.Config.carousel, {'name' : carouselName, 'itemsUpdated' : this.carouselItemsUpdated()});

        // Create Carousel Control
        this[carouselName + 'Carousel'] = new Shared.Carousel(config);
    }
};

/**
 * carouselItemsUpdated - Returns a function for the Carousel with specific behavior for the Type of Carousel
 *
 * @private
 * @this Hubs.App
 * @return {Function}
 */
Hubs.App.prototype.carouselItemsUpdated = function() {
    /**
     * [Anonymous Function] - Updates Elements after the Carousel Items have Been Updated
     *
     * @private
     * @this Hubs.App
     * @return undefined
     */
    return $.proxy(function(itemCount, lazyLoadLimit, $carouselItem) {
        var i = 0, $item = null;
        Hubs.updateImageTiles(this, '.tile .img');
        Hubs.setFriendlyTimestamps();
        this.overrideLinks();
    }, this);
};

/**
 * Toggles the display of the "Show More" link on Level 3 item pages
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.toggleShowMore = function() {
    var $showMoreLink = $(Hubs.Config.containers.showMoreLink),
        $descWrapper, $fullDesc, $showMoreAnchor = null,
        showMoreText = $showMoreLink.find('a').attr('data-show-more'),
        showLessText = $showMoreLink.find('a').attr('data-show-less'),
        descWrapperHeight = 0;

    if (!$showMoreLink.length) { return; }

    $descWrapper = $(Hubs.Config.containers.descWrapper);
    $fullDesc = $(Hubs.Config.containers.fullDesc);

    // Get Default Height of Description Wrapper
    descWrapperHeight = $descWrapper.height();
    this.fullDescDisplayed = false;

    // Check if Content Description exceeds the Wrapper Bounds
    if ($fullDesc.height() > descWrapperHeight) {
        $showMoreLink.find('a').addClass('hooked');
        $showMoreLink.css({'display': 'block'}).on(Hubs.TAP_EVENT, $.proxy(function(e) {
            if (this.fullDescDisplayed) {
                $descWrapper.animate({'height': '3.6em'});
                $showMoreLink.find('a').text(showMoreText);
            } else {
                $descWrapper.animate({'height': $fullDesc.outerHeight(true)});
                $showMoreLink.find('a').text(showLessText);
            }
            this.fullDescDisplayed = !this.fullDescDisplayed;
        }, this));

        // Center the Show More link
        if (Hubs.isMobile()) {
            $showMoreAnchor = $showMoreLink.find('> a');
            $showMoreAnchor.css({'margin-left': (($showMoreLink.width() - $showMoreAnchor.width()) / 2) });
        }
    } else {
        $showMoreLink.css({'display': 'none'});
    }
};

/**
 * Prevents Bubbling on Clicks up to the Body Level after a Set Timeout to allow Tap Events to Close Side Menu
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.preventBubbling = function() {
    this.mobileNavClicked = true;
    Hubs.root.setTimeout($.proxy(function() { this.mobileNavClicked = false; }, this), 100);
};

/**
 * Privacy Management
 */
Hubs.App.prototype.getPrivacyBannerState = Hubs.PrivacyHelpers.getPrivacyBannerState;

Hubs.App.prototype.savePrivacyGroupPreferences = Hubs.PrivacyHelpers.savePrivacyGroupPreferences;

Hubs.App.prototype.formatPrivacyCookieValue = Hubs.PrivacyHelpers.formatPrivacyCookieValue;

Hubs.App.prototype.setPrivacyCookieValue = Hubs.PrivacyHelpers.setPrivacyCookieValue;

Hubs.App.prototype.ufaOptIn = Hubs.PrivacyHelpers.ufaOptIn;

Hubs.App.prototype.ufaOptOut = Hubs.PrivacyHelpers.ufaOptOut;

Hubs.App.prototype.initPrivacyCookieManagement = function() {
    var $banner = $('#uf-privacy-banner');

    function setPrivacyBannerHidden() {
        var cookie = cookies.get(Hubs.PRIVACY_PREFS_COOKIE_NAME);
        if (cookie) {
            var cookieArr = cookie.split('|');
            cookieArr[1] = Hubs.SHOW_NO_BANNER;
            this.setPrivacyCookieValue(cookieArr.join('|'));
        } else {
            // Create new cookie where Privacy Banner is dismissed
            Hubs.Privacy.saveChanges();
        }
    }

    function hookPrivacyBanner() {
        function handleExitClick() {
            $banner.hide();
            setPrivacyBannerHidden.call(this);
        }

        function handleAcceptClick() {
            Hubs.Privacy.acceptAll();
            Hubs.Privacy.applyChanges();
            handleExitClick.call(this);
        }

        // Dismiss Banner
        $banner.on('click', '#privacy-banner-close', handleExitClick.bind(this));

        // Privacy Link Click
        $banner.on('click', '[data-privacy-action="manage"]', handleExitClick.bind(this));

        // Accept button Click
        $banner.on('click', '[data-privacy-action="accept"]', handleAcceptClick.bind(this));
    }

    function updateBannerVisibilityOnPageChange() {
        // No banner on embed tiles
        if (Hubs.Config.isEmbeddedTile) { return; }

        var bannerState = parseInt($banner.data('showBanner'), 10) || 0;
        if (bannerState === 1 || bannerState === 2) {
            $banner.fadeIn('fast');
        } else {
            $banner.hide();
        }
    }

    /**
     * Privacy Banner
     */
    function initPrivacyBanner() {
        // No banner on embed tiles
        if (Hubs.Config.isEmbeddedTile) { return; }

        hookPrivacyBanner.call(this);
        updateBannerVisibilityOnPageChange();
    };

    /**
     * Privacy Policy page
     */
    function initPrivacyPolicyPageSettings() {
        function handleTogglePrivacyGroup(event) {
            var checkboxElement = $(event.currentTarget);
            var isChecked = checkboxElement.is(':checked');
            var privacyGroupId = checkboxElement.closest(toggleSelector).attr('data-group-id');
            var methodName = isChecked ? 'acceptById' : 'rejectById';
            Hubs.Privacy[methodName](privacyGroupId);
        }

        // Hook Toggle Events:
        var toggleSelector = '.uf-privacy-group-toggle';
        $(toggleSelector + ' input').on('change', handleTogglePrivacyGroup);
    }

    var pageType = $('#page-type-identifier').attr('data-page-type');
    if (pageType === 'PAGE_TYPE_PRIVACY') {
        initPrivacyPolicyPageSettings();
    }

    initPrivacyBanner.call(this);
};

/**
 * Signals that the temp metrics data that we've stored server-side can be transmitted to the metrics store
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.signalMetricsTemp = function() {
    if(Hubs.Config.isEmbeddedTile || Hubs.Config.disableUfMetrics) {
        return;
    }

    var metricsTempId = $(Hubs.Config.containers.pageIdentifier).attr('data-metrics-temp-id');
    if(metricsTempId === undefined || !metricsTempId.length) {
        metricsTempId = '';
    }
    (new Image()).src = Shared.absoluteHubUrl(Hubs.Config.serverUrl.signalMetricsTemp) + '/' + metricsTempId + '?t=' + (new Date().getTime());
};

/**
 * Loads the extra info at item level
 *
 * @private
 * @this Hubs.App
 * @return undefined
 */
Hubs.App.prototype.loadExtraInfo = function() {
    // Callbacks by service
    var services = {
        'twitter': function(extraInfo, $itemContainer) {
            // Display the numbers of retweets and favourites
            $.each(extraInfo, function (index, count) {
                var $info = $itemContainer.find('.info .' + index);
                if (count > 0) {
                    $info.show().find('.count').text(count);
                    if (count > 1) {
                        $info.find('.plural').show();
                    }
                }
            });
        },
        'instagram': function(extraInfo, $itemContainer) {
            // Display the likes and the comments
            var compiled;
            var diff;
            var likeTemplate = '<a title="<%= _.escape(username) %>" href="https://instagram.com/<%= _.escape(username) %>" target="_blank" rel="noopener"><img alt="<%= _.escape(username) %>" src="<%= _.escape(profile_picture) %>" /></a>';
            var commentTemplate = '<div class="comment">' +
                '<div class="text">' +
                '<a href="https://instagram.com/<%= _.escape(from.username) %>" target="_blank" rel="noopener"><%= _.escape(from.username) %></a>' +
                '<span><%= _.escape(text) %></span>' +
                '</div>' +
                '</div>';

            if (extraInfo.likes && extraInfo.likes.length > 0) {
                $itemContainer.find('.likes').show();
                compiled = _.template(likeTemplate);
                $.each(extraInfo.likes, function (index, like) {
                    // Display 3 likes at most
                    if (index == 3) {
                        diff = extraInfo.likes.length - index;
                        $itemContainer.find('.likes .more').show().find('.diff').text(diff);
                        if (diff > 1) {
                            $itemContainer.find('.likes .more .plural').show();
                        }
                        return false;
                    }
                    var likeHtml = compiled(like);
                    $itemContainer.find('.likes .list').append(likeHtml);
                });
            }

            if (extraInfo.comments && extraInfo.comments.length > 0) {
                $itemContainer.find('.comments').show().find('.count').text(extraInfo.comments.length);
                if (extraInfo.comments.length > 1) {
                    $itemContainer.find('.comments .plural').show();
                }
                compiled = _.template(commentTemplate);
                $.each(extraInfo.comments, function (index, comment) {
                    // Display 30 comments at most
                    if (index == 30) {
                        diff = extraInfo.comments.length - index;
                        $itemContainer.find('.comments .more').show().find('.diff').text(diff);
                        return false;
                    }
                    var commentHtml = compiled(comment);
                    $itemContainer.find('.comments .list').append(commentHtml);
                });
            }
        }
    };

    if(services[this.itemType] === undefined) {
        return;
    }

    Shared.ajaxFetch(Hubs.Config.serverUrl.extraInfo + this.currentItemId, 
        {}, 'GET', true,  
        $.proxy(function(data) {
            if (data.response && data.response.extraInfo) {
                services[this.itemType].apply(this, [data.response.extraInfo, $(Hubs.Config.containers.itemContent)]);
            }
        }, this)
    );
};

Hubs.App.recordCtaViews = function() {
    var pageIdentifier = $(Hubs.Config.containers.pageIdentifier);

    if (Hubs.CTAs.ctasForViewTracking.length) {
        Shared.Logger.log('recording view for ctas: ' + JSON.stringify(Hubs.CTAs.ctasForViewTracking));
        var jqxhr = $.ajax(Shared.absoluteHubUrl(Hubs.Config.serverUrl.ctaViewTracking), {
            type: 'POST',
            data: {
                hubId: Hubs.Config.hubId,
                ctas: Hubs.CTAs.ctasForViewTracking,
                metricsKey: pageIdentifier.attr('data-metrics-temp-id')
            }
        });

        // Once we are done the request, reset the list of CTAs to track.
        jqxhr.then(function(){
            Hubs.CTAs.ctasForViewTracking = [];
        });
    }
};

Hubs.App.prototype.hidePublishDate = function() {
    var pageIdentifier = $(Hubs.Config.containers.pageIdentifier);

    if (pageIdentifier.data('page-type') !== 'PAGE_TYPE_ITEM') {
        return;
    }

    var date = $(Hubs.Config.containers.date);
    var itemId = pageIdentifier.data('item-id');
    var itemToHide = this.hubState.previousState.get('lastRecoItm');


    Shared.Logger.log('current reco item stored: ' + JSON.stringify(itemToHide));
    if (itemToHide === itemId) {
        date.hide();
    }

    this.hubState.previousState.remove('lastRecoItm');
};

/* if updating this function:
    another copy of it exist under Flipbooks namespace
    make sure to update it too
    */
Hubs.App.prototype.pdfDownload = function (flipbookId) {
    if (Hubs.appInstance.ufaCaller) {
        var ufaCaller = Hubs.appInstance.ufaCaller;
        ufaCaller.trackFlipbookDownload(flipbookId);
    }

    var statsUrl = Shared.absoluteHubUrl('/issues/download/issue/' + flipbookId);
    // Debug Message
    Shared.Logger.log('stats-page-pdf-download');

    function qAjax(options) {
        var deferred = Q.defer();

        // Ensure we have a Valid URL
        if (options === undefined || options.url === undefined || !options.url.length) {
            deferred.reject('invalid url');
        }

        // Check for Other Options for AJAX
        if (options.type === undefined) { options.type = 'POST'; }
        if (options.data === undefined) { options.data = {}; }
        if (options.async === undefined) { options.async = true; }
        if (options.dataType === undefined) { options.dataType = 'json'; }

        // Success Handler; Resolve Promise
        options.success = function(data) {
            deferred.resolve(data);
        };

        // Error Handler; Reject Promise
        options.error = function(jqXhr, textStatus, errorThrown) {
            deferred.reject([jqXhr, textStatus, errorThrown]);
        };

        // Track Last AJAX Request
        var LastXHR = $.ajax(options);

        // Return Promise
        return deferred.promise;
    }

    qAjax({'url': statsUrl, 'data': {'v': 'm4'}, 'dataType': 'html'}).then($.proxy(function(responseData) {
        qAjax({'url': statsUrl, 'data': {'v': 'm4', 'data[Download][session]': responseData}, 'dataType': 'html'});
    }, this));

    return this;
};

/**
 * Initializes the Splash Screen per Page Type
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.App.prototype.initSplashScreen = function(pageType) {
    if (pageType === Hubs.PAGE_TYPE_HUB) {
        Shared.scrollTop(0);
    } else {
        // Hide Large Header if visible on any page other than the main page
        this.removeHeroHeader(false);
    }
};

/**
 * Removes the Hero Header
 *
 * @public
 * @this local
 * @param {Boolean} animate Whether or not to animate the removal
 * @return undefined
 */
Hubs.App.prototype.removeHeroHeader = function(animate) {
    if (Hubs.Config.labOptions.permHeader 
        || Hubs.Config.labOptions.noHeader) { 
            return; 
        }

    var $largeHeader = $(Hubs.Config.containers.largeHeader),
        $desktopLeftNav = $(Hubs.Config.containers.leftNav[2] + '.desktop'),
        headerHeight = $largeHeader.height(),
        shrinkTo = headerHeight - Shared.scrollTop(),
        transitionDuration = '',
        removeHeader = $.proxy(function() {
            // Large header is no longer needed, can remove and reset the header
            $largeHeader.remove();
            Shared.$('header').css("height", "auto");

            // Scroll to Top of Hub
            if (Hubs.isEmbedded() && 'parentIFrame' in window) {
                // Signal Scroll
                window.parentIFrame.scroll('top');
            } else {
                // if you have a Fragment Identifier don't move to top
                if (!Hubs.root.location.hash.length) {
                    Shared.scrollTop(Shared.scrollTop() - headerHeight);
                }

            }

            if (Hubs.Privacy.isFunctionalityEnabled('UBERFLIPUI')) {
                this.hubState.userInterface.set('bannerDismissed', 1);
            }
        }, this);

    // Check if Large Header exists
    if ($largeHeader.length > 0) {

        // Animate hiding of large header by moving it to the top
        if(animate) {

            $largeHeader.css({'margin-top': -shrinkTo}).css("border-bottom", "0");
            $(Hubs.Config.containers.headerLoading).css("opacity", "0");

            // Timeout used to wait until animation of moving large header to top is done
            window.setTimeout(removeHeader, 1000);
        } else {
            // Remove large header immediately and scroll to top
            // (Just in case user somehow manages to scroll off page and remove the header while on mobile)
            Shared.$('body').off('touchmove.Hubs.preventMove');
            removeHeader();
        }
    }
};

/**
 * Finalizes the Splash Screen per Page Type
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.App.prototype.finalizeSplashScreen = function (){
    var $loader = $(Hubs.Config.containers.splashLoader),
        $chevron = $(Hubs.Config.containers.splashChevron),
        afterFade = function() {
            if (!Hubs.isEmbedded()) {
                $(Hubs.Config.containers.headerLoading).addClass("hide-splash-state");
            } else {
                $(Hubs.Config.containers.headerLoading).fadeOut(200);
            }
            $(Hubs.Config.containers.largeHeader).find('h2, div.primary-logo').css("opacity", 1);
            $(Hubs.Config.containers.app).removeClass('invisible');
        };

    if (!Hubs.isEmbedded()) {
        // Fade out the loader, fade in the chevron, change the background color
        $loader.fadeOut(200, function() {
            $chevron.fadeIn(300, afterFade);
        });
    } else {
        // Fade out the loader, fade in the chevron, change the background color
        $loader.fadeOut(200, afterFade);
    }
};

// Augment App to have CTA, Responsive & Embedded Methods
Shared.augment(Hubs.App, [Hubs.CTAs, Hubs.Responsive, Hubs.Embedded, Hubs.Recommendations]);
