import '../../../libs/timeago/timeago.js';
import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

/**
 * Hubs Recommendations
 *
 * @class Recommendations
 * @kind JS.Module
 * @mixin
 * @classdesc Provides the Recommendations Bombora calls and UI manipulation
 * @namespace Hubs
 * @constructor
 */
Hubs.Recommendations = function() {};

var opts = {};

var config = {
  animationSpeed          : 500,
  panelWidth              : 242,
  // the width at which the panel will fit in the left margin without adjustment.
  contentBreakpoint       : 1402,
  sideCtaContentBreakpoint: 1746,
};

var recoSelectors = {
  recommendationContent   : '#recommendation-content-wrapper',
  recommendationNextButton: '#recommendation-button-wrapper',
  defaultNextButton       : '#default-next-button-wrapper',
  carouselWrapper         : '.related-items-container'
};



/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */


/**
 * Init Reco DOM elements
 * @param callback: set-up for elements that appear asynchronously on the page, like carousel
 */
Hubs.Recommendations.prototype.setUpRecommendations = function(callback) {

  // make sure the reco options are available to us
  opts = Hubs.recommendation;
  if (!opts.enabled){return;}

  // construct the bombora request body
  var body = {
    "rule": {
        "id": opts.id,
        "type": "hub"
    },
    "type": {
    },
    "context": {
      "hub_id": parseInt(Hubs.Config.hubId, 10),
      "visual": shouldOverrideCarousel() ? [{name:'carousel'},{name:'next'}] : [{name: 'panel'}],
      "url": window.location.href,
      "visitor":{
        "uberflip": {
        },
        "bombora": {
        }
      }
    },
    "filters": {
      "count": opts.template.max_items_to_display,
      "max_age_days": opts.template.max_item_age,
      "hide_publish_date": opts.template.hide_publish_date
    }
  };

  if (typeof window.getBomboraUuid === 'function') {
    body.context.visitor.uberflip.uuid = window.getBomboraUuid();
  }

  // get CCMAID a.k.a. "bombora_visitor_id"
  if (window.localStorage && localStorage.getItem('_ccmaid')){
    body.context.visitor.bombora.id = localStorage.getItem('_ccmaid');
  }

  if (Hubs.appInstance) {
    body.context.item_id = Hubs.appInstance.currentItemId;
    body.context.collection_id = Hubs.appInstance.currentCollectionId;
  } else {
    var $pageInfoDiv = $('#page-type-identifier');
    body.context.item_id =   parseInt($pageInfoDiv.attr('data-item-id'),10);
    body.context.collection_id = parseInt($pageInfoDiv.attr('data-collection-id'),10);
  }

  if (opts.isInternalRule) {

    // add the type recommendation type
    body.type.id = Shared.RECOMMENDATION_ENGINE_TYPE_STREAM;
    body.type.source_collection_id = opts.source_collection_id;

    callRecoEngine(
      body,
      buildRecoElements.bind(this, opts.template, opts.template.panel_delay, callback),
      showCarouselAndNext.bind(this, false, callback)
      );

  } else if (opts.isAiRule) {

    // add the type recommendation type
    body.type.id = Shared.RECOMMENDATION_ENGINE_TYPE_AI;

    // start timer for panel delay
    var getTimeRemaining = setDelayCountdown(opts.template.panel_delay);

    // don't pass stream ids if the rule is for all streams
    if (opts.recommendationAiContent !== 'all_streams') {
      body.filters.allowed_collection_ids = opts.recommendationAiStreams;
    }

    var sendAjax = function() {
      callRecoEngine(
        body,
        buildRecoElements.bind(this, opts.template, getTimeRemaining(), callback),
        showCarouselAndNext.bind(this, false, callback)
      );
    };

    // if we don't have topics, wait a bit for the bombora callback to return
    var count = 8; // number of intervals before timeout
    (function wait(){
      if (window._ml && window._ml.us) {
        body.context.visitor.bombora.intent_topics = window._ml.us.tp;
        sendAjax();
      } else if (count <= 0){
        sendAjax();
      } else {
        count -= 1;
        setTimeout(wait, 200);
      }
    })();

  }
};

Hubs.Recommendations.prototype.isRecommendationEnabled = function() {
  return Hubs.recommendation && Hubs.recommendation.enabled;
};

Hubs.Recommendations.markItemToHidePublishDate = function ($element) {
  var recommendation = Hubs.recommendation || {};
  var recoTemplate = recommendation.template || {};
  // if there is recommendation that is enabled & has template
  if (recommendation && recommendation.enabled) {
    // If there is a template set and hide publish date is enabled
    if (recoTemplate && recoTemplate.hide_publish_date) {
      if (recoTemplate.enable_panel && shouldShowPanel()) { // panel
        return $element.closest('.reco-panel-items .tile').data('id');	
      } else if (!shouldShowPanel() && shouldOverrideCarousel()) { // next item / carousel 
        return $element.data('item-id') || $element.closest('.tile').data('id');
      }
    }
  }

  return false;
};

/* ******************************************************************************************** */
/* * Private functions                                                                        * */
/* ******************************************************************************************** */

function callRecoEngine(body, success, fallback){
  $.ajax({
    type:'POST',
    url: Shared.absoluteHubUrl('/hubsFront/ajax_getRecommendations'),
    data: JSON.stringify(body),
    contentType: "application/json",
    success: function(data) {
      if (data.response.recommendations) {
        var recoData = data.response.recommendations;

        if (recoData.tiles) {
          // insert data into the DOM containers
          $(recoData.tiles.html).appendTo(recoSelectors.recommendationContent);
          if(!Hubs.recommendation.template.hide_publish_date) {
            $(recoSelectors.recommendationContent + ' ' + Hubs.Config.containers.timeAgo).timeago();
          }
        } else {
          return fallback();
        }

        if (recoData.next) {
          $(recoData.next.html).appendTo(recoSelectors.recommendationNextButton);
        }

        // build dom things
        success();
      } else {
        // there was no content returned, so show the default carousel
        fallback();
      }
    },
    error: function(){
      //show the default carousel
      fallback();
    }
  });
}


function showCarouselAndNext(showNext, callback) {
  var recoButton = $(recoSelectors.recommendationNextButton).find('.item-next');

  // should show Reco next button or default prev/next?
  if (showNext && recoButton.length) {
    // show Reco next button
    $(recoSelectors.recommendationNextButton).removeClass('hide');
    $(recoSelectors.defaultNextButton).addClass('hide');
  } else {
    // show default next/prev button
    $(recoSelectors.recommendationNextButton).addClass('hide');
    $(recoSelectors.defaultNextButton).removeClass('hide');
  }

  // show the carousel no matter what
  $(recoSelectors.carouselWrapper).addClass('reco-engine-show');

  // The Reco stuff is all in the dom. need to hook links and setup carousel
  if(callback) {callback();}

  // emit event
  Hubs.Events.trigger('recoItemsLoaded');
}


/**
 * Get the bottom of the topNav from top of window.
 * @returns int
 */
function getNavBottom() {
  // This is way better than a jQuery solution,
  // getBoundingClientRect() returns x, y, height, bottom, etc of border-box relative to browser window.
  var boundingBox = document.querySelector('.top-nav').getBoundingClientRect();

  return boundingBox.bottom;
}

/**
 * Get widths needed for calculating what to do with panel and margins.
 */
function getWidths() {
  var panelWidth = config.panelWidth;
  var $content = $('#item-content');
  var $article = $content.find('.item-data');
  var windowWidth = getWindowSize().width;
  var leftMarginWidth = $article.offset().left;
  var hasSideCta = pageHasSideCta();

  var contentWidth;
  if (hasSideCta) {
    var $sideCta = $content.find('.cta-item-container .cta');
    // Measure from article left to cta right
    contentWidth = ($sideCta.offset().left + $sideCta.width() + 10) - leftMarginWidth;
  } else if ($content.data('defaultWidths')) {
    // use the original width of the content (for correct resize when shrinking the screen)
    contentWidth = $content.data('defaultWidths').content;
  }  else {
    contentWidth = $article.outerWidth();
  }

  var rightMarginWidth = windowWidth - (contentWidth + leftMarginWidth);
  var adjustedRightMargin = (((windowWidth - contentWidth) - panelWidth) / 2);
  var adjustedLeftMargin = (adjustedRightMargin + panelWidth);

  // Store the original widths
  if ( ! $content.data('defaultWidths') || ! $content.attr('data-margins-adjusted')) {
    $content.data('defaultWidths', {
      content: $article.outerWidth(),
      leftMargin: $content.css('margin-left'),
      rightMargin: $content.css('margin-right'),
    });
  }

  return {
    window: windowWidth,
    content: contentWidth,
    leftMargin: leftMarginWidth,
    rightMargin: rightMarginWidth,
    adjustedRight: adjustedRightMargin,
    adjustedLeft: adjustedLeftMargin,
  };
}

function adjustContentMargins(marginLeft, marginRight) {
  var $content = $('#item-content');
  $content.animate(
    {
      marginLeft : marginLeft,
      marginRight: marginRight
    },
    config.animationSpeed,
    function(){
      $content.attr('data-margins-adjusted', true);
    }
  );
}

function removeAdjustedContentMargins() {
  var $content = $('#item-content');
  if( ! $content.attr('data-margins-adjusted')) return;
  $content.animate(
    {
      marginLeft : $content.data('defaultWidths').leftMargin,
      marginRight: $content.data('defaultWidths').rightMargin,
    },
    config.animationSpeed,
    function() {
      $content.removeAttr('data-margins-adjusted');
      $content.css({marginLeft: '', marginRight: ''});
    }
  );
}

/**
 * Has cta && is The Cta floating beside the article?
 */
function pageHasSideCta() {
  var $content = $('#item-content');
  return $content.hasClass('with-cta') && $content.find('.entry-wrapper .cta-item-container .cta').length === 0;
}

/**
 * Decide whether we should override the carousel.
 */
function shouldOverrideCarousel() {
  return opts.template.override_next && ! shouldShowPanel();
}

/**
 * Should we show the panel?
 */
function shouldShowPanel() {
  var widths = getWidths();
  return (
    opts.template.enable_panel &&
    config.panelWidth < (widths.leftMargin + widths.rightMargin)
  );
}

/**
 * Decide what Reco Elements to build
 * @param template
 * @param delay
 * @param callback: set-up for elements that appear after page init, like carousel
 */
function buildRecoElements(template, delay, callback) {
  // this will adjust the page margins
  var showPanel    = shouldShowPanel();
  var overrideNext = template.override_next && !showPanel;
  var label        = template.label_recommended;
  var fromTop      = template.panel_top_auto ? getNavBottom() : template.panel_top_pixels;

  // If there are no tiles to show, abort
  if ( ! $(recoSelectors.recommendationContent).find('.tile').length) return;

  // Decide what to show/hide/override
  if (showPanel) {
    createRecoPanel(label, fromTop, delay);
  } else if (overrideNext) {
    replaceCarouselWithReco(label);
  } // else don't show any recos

  // always show the carousel
  showCarouselAndNext(overrideNext, callback);

  if(overrideNext) {
      Hubs.recoMetrics.initialRecoMetrics(true);
  }
}

/**
 * Swap out the default carousel for the Reco carousel
 * @param label
 */
function replaceCarouselWithReco(label) {
  // check if we have tiles
  var $recoTiles = $(recoSelectors.recommendationContent).find('.tile');
  if (!$recoTiles.length) { return; }

  var $header = $('#related-items-heading', recoSelectors.carouselWrapper).detach();
  var $carousel = $('#related-items', recoSelectors.carouselWrapper).detach();

  // replace tiles and header text
  $carousel.find('.carousel-inner').html($recoTiles);
  $header.text(label);

  // insert the updated carousel and header to the DOM
  $carousel.prependTo(recoSelectors.carouselWrapper).before($header);
}

/**
 * Create the panel, insert it, and set relevant events.
 * @param label
 * @param fromTop
 * @param delay
 */
function createRecoPanel(label, fromTop, delay) {
  // check if we have tiles
  var $recoTiles = $(recoSelectors.recommendationContent).find('.tile');
  if (!$recoTiles.length) { return; }

  var $panel = $("<div class='reco-panel'>" +
                    "<div class='reco-toggle'>" +
                      "<span class='reco-show'>+</span>" +
                      "<span class='reco-hide'>x</span>" +
                    "</div>" +
                    "<h3>" + label + "</h3>" +
                    "<div class='reco-panel-items'></div>" +
                  "</div>");

  $panel.find('.reco-panel-items').append($recoTiles);
  $panel.css('top', fromTop);

  $panel.on('transitionend', function (event) {
    if (event.originalEvent.propertyName == 'left') {
        Hubs.recoMetrics.initialRecoMetrics(shouldOverrideCarousel());
        $panel.off('transitionend');
    }
  });

  // insert panel into DOM, hidden
  $('body').append($panel).addClass('has-reco-panel');

  function slidePanelIn() {
      $panel.addClass('open');
      // emit event
      Hubs.Events.trigger('recoPanelOpen');
  }

  function slidePanelOut() {
      $panel.removeClass('open');
  }

  function initialPanelEntry() {
    var widths = getWidths();
    // will panel fit in left margin without adjustment?
    if (config.panelWidth > widths.leftMargin){
      adjustContentMargins(widths.adjustedLeft, widths.adjustedRight);
    }
    slidePanelIn();
  }

  // delay panel entry?
  var entryDelay;
  if (delay <= 0) {
    initialPanelEntry();
  } else {
    entryDelay = setTimeout(
      initialPanelEntry,
      (delay * 1000)
    );
  }

  // panel open/close event
  $panel.on('click', '.reco-toggle span', function() {
    // clear timeout if user opens panel before panel entry delay runs out
    if (entryDelay) {
      clearTimeout(entryDelay);
      entryDelay = null;
    }

    var widths = getWidths();
    // panel is greater than left margin, but lesser than both margins combined.
    var adjustMargins = (
      config.panelWidth > widths.leftMargin &&
      config.panelWidth < (widths.leftMargin + widths.rightMargin)
    );

    if ($panel.hasClass('open')) {
      // panel is closing so reset margins
      removeAdjustedContentMargins();
      slidePanelOut();
    } else {
      // panel is opening
      if (adjustMargins){
        adjustContentMargins(widths.adjustedLeft, widths.adjustedRight);
      }
      slidePanelIn();
    }
  });

  Hubs.Events.on('resize', function() {
    if (!$panel.hasClass('open')) return;

    var widths = getWidths();
    var $content = $('#item-content');

    // we need the hardcoded config values here because
    // once margins are adjusted panel will always fit in left margin.
    var breakWidth = pageHasSideCta() ? config.sideCtaContentBreakpoint : config.contentBreakpoint;
    var panelWillFitInLeftMargin = widths.window > breakWidth;
    var panelWontFit = config.panelWidth > (widths.leftMargin + widths.rightMargin);

    if (panelWillFitInLeftMargin || panelWontFit) {
      $content.removeAttr('data-margins-adjusted');
      $content.css({marginLeft: '', marginRight: ''});
      if (panelWontFit) {
        slidePanelOut();
      }
    } else {
      // re-adjust the margins
      adjustContentMargins(widths.adjustedLeft, widths.adjustedRight);
    }
  });
}

// timer that returns a getter for remaining seconds
function setDelayCountdown(delay) {
  var secondsRemaining = delay;

  var timerId = setInterval(function(){
    secondsRemaining -= 1;
    if (secondsRemaining <= 0) {
      clearInterval(timerId);
    }
  }, 1000);

  return function() {
    return secondsRemaining;
  };
}

// check window width
function getWindowSize() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth  || e.clientWidth  || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {width:x, height:y};
}
