import 'jquery-migrate';
import $ from 'jquery';
import cookies from 'js-cookie';

/**
 * A wrapper for creating a variable that is read from / written to a cookie,
 * containing events for any side effects that may be needed. Used to move us away from
 * ajax based navigation, while perserving state for the user across page changes. 
 *
 * @class CookieVariable
 */
class CookieVariable {

  constructor(name, customCookieSettings = false) {
    const defaults = {
      secure: true
    };
    this.cookieName = name;
    this.cookieSettings = customCookieSettings ? customCookieSettings : defaults;
  }

  /**
   * Apply jQuery based event listeners for this variable.
   * EX types: set, get, clear
   *
   * @param {String} type
   * @param {Function} func
   * @returns {CookieVariable}
   * @memberof CookieVariable
   */
  on(type, func) {
    $.fn.on.apply($(window),[
      `state.${this.cookieName}.${type}`, 
      func
    ]);
    return this;
  }

  /**
   * Trigger jQuery based events on this variable
   *
   * @param {String} type
   * @param {Function} func
   * @returns {CookieVariable}
   * @memberof CookieVariable
   */
  trigger(type, ...args) {
    $.fn.trigger.apply($(window),[
      `state.${this.cookieName}.${type}`,
      ...args
    ]);
    return this;
  }

  /**
   * Get the value of a particular key on this cookie variable,
   * or get the entire CookieVariable object (with no key provided).
   * Triggers "get" Event.
   *
   * @param {String} key
   * @memberof CookieVariable
   */
  get(key) {
    if(key) {
      const data = cookies.get(this.cookieName);
      const obj = data ? JSON.parse(data) : {};
      this.trigger('get', obj[key], key);
      return obj[key];
    } else {
      const data = cookies.get(this.cookieName);
      const obj = data ? JSON.parse(data) : {};
      this.trigger('get', obj, 'all');
      return obj;
    }
  }
      
  /**
   * Set a particular key on this cookie variable to a
   * provided value. Triggers "set" event.
   *
   * @param {String} key
   * @param {*} value
   * @returns {Object}
   * @memberof CookieVariable
   */
  set(key, value) {
    const data = cookies.get(this.cookieName);
    const obj = data ? JSON.parse(data) : {};
    obj[key] = value;
    cookies.set(this.cookieName, JSON.stringify(obj), this.cookieSettings);
    this.trigger('set', obj, key);
    return obj;
  }
      
  /**
   * Delete the contents of a cookie variable, replacing them with an
   * empty object.
   *
   * @returns {CookieVariable}
   * @memberof CookieVariable
   */
  clear() {
    cookies.set(this.cookieName, JSON.stringify({}), this.cookieSettings);
    this.trigger('clear', this.cookieName);
    return this;
  }

  /**
   * Returns true/false if this cookie variable contains any keys
   *
   * @returns {CookieVariable}
   * @memberof CookieVariable
   */
  hasContents() {
    const contents = this.get();
    return (Object.keys(contents).length > 0);
  }
}

export default CookieVariable;