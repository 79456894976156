class Privacy {
  static FUNCTIONALITY_CODE_UFA = 'UBERFLIP';

  static cleanId(id) {
    return parseInt(id, 10);
  }

  /**
   * Check that all required/expected Global Variables are set and accessible. If one
   * of these variables is missing, we cannot continue with Privacy Groups events (could
   * mean that `privacy.js` loaded before `Hub.appInstance` had bootstrap; in this case,
   * you should wait for the `load` event to trigger before accessing JS Privacy API).
   *
   * @returns {boolean}
   */
  static areGlobalsReady() {
    return Boolean(Hubs && Hubs.Config && Hubs.Config.privacyGroups && Hubs.appInstance);
  }

  /**
   * Returns keyed-list of Privacy Groups on this Hub, including current `isAccepted`
   * status respective of the current visitor. Each item contains Privacy Group object.
   */
  static getAll() {
    return this.areGlobalsReady() ? Object.assign({}, Hubs.Config.privacyGroups) : {};
  }

  static getById(privacyGroupId) {
    const id = this.cleanId(privacyGroupId);
    return this.getAll()[id] || null;
  }

  /**
   * Get a Privacy Group based on the Functionality code (eg. `UBERFLIP`).
   *
   * @param (string) code: eg. 'uberflip', 'UBERFLIPUI', etc (case-insensitive)
   * @returns {null}
   */
  static getByFunctionalityCode(functionalityCode) {
    if (!functionalityCode) return null;

    const privacyGroups = this.getAll();

    for (const id in privacyGroups) {
      const privacyGroup = privacyGroups[id];
      const functionalities = privacyGroup.functionalities;

      for (const key in functionalities) {
        const functionality = functionalities[key];
        if (functionalityCode.toLowerCase() === functionality.code.toLowerCase()) {
          return privacyGroup;
        }
      }
    }
    return null;
  }

  /**
   * Check if a Privacy Group belonging to Functionality (eg. `UBERFLIP`) has been
   * "Accepted" by the visitor.
   *
   * If the Functionality is not linked to a Privacy Group, it is treated as being
   * Enabled.
   *
   * @param (string) functionalityCode: eg. UBERFLIP, UBERFLIPUI, etc
   * @returns {boolean}
   */
  static isFunctionalityEnabled(functionalityCode) {
    const privacyGroup = this.getByFunctionalityCode(functionalityCode);
    return privacyGroup ? Boolean(privacyGroup.isAccepted) : true;
  }

  /**
   * Set all Privacy Groups as "Accepted" for this current visitor.
   */
  static acceptAll() {
    const privacyGroupIds = Object.keys(this.getAll());
    privacyGroupIds.forEach((privacyGroupId) => this.updateById(privacyGroupId, true));
    this.saveChanges();
  }

  /**
   * Set all Privacy Groups as "Rejected" for this current visitor.
   */
  static rejectAll() {
    const privacyGroupIds = Object.keys(this.getAll());
    privacyGroupIds.forEach((privacyGroupId) => this.updateById(privacyGroupId, false));
    this.saveChanges();
  }

  /**
   * Set a specific Privacy Group as "Accepted" for this current visitor.
   */
  static acceptById(privacyGroupId) {
    this.updateById(privacyGroupId, true);
    this.saveChanges();
  }

  /**
   * Set a specific Privacy Group as "Rejected" for this current visitor.
   */
  static rejectById(privacyGroupId) {
    this.updateById(privacyGroupId, false);
    this.saveChanges();
  }

  static updateById(privacyGroupId, isAccepted) {
    if (!this.areGlobalsReady()) return;

    const id = this.cleanId(privacyGroupId);
    const privacyGroup = Hubs.Config.privacyGroups[id];
    if (!privacyGroup) return;

    const isAcceptedValue = isAccepted ? 1 : 0;
    const noValueChange = privacyGroup.isAccepted === isAcceptedValue;
    if (noValueChange) return;

    privacyGroup.isAccepted = isAcceptedValue;

    (privacyGroup.functionalities || []).forEach((functionality) => {
      if (functionality.code === this.FUNCTIONALITY_CODE_UFA) {
        const methodName = isAccepted ? 'ufaOptIn' : 'ufaOptOut';
        Hubs.appInstance[methodName]();
      }
    });
  }

  /**
   * Save `isAccepted` changes to the Privacy Preferences cookie.
   *
   * For changes to be stored, this method must be triggered after any changes
   * using the methods: acceptAll, rejectAll, acceptById, or rejectById.
   */
  static saveChanges() {
    const privacyGroups = this.getAll();
    const privacyGroupIds = Object.keys(privacyGroups);
    /* eslint-disable sort-keys */
    const pgPreferences = privacyGroupIds.map((id) => ({
      id,
      version: privacyGroups[id].version,
      accepted: privacyGroups[id].isAccepted,
    }));
    /* eslint-enable sort-keys */
    Hubs.appInstance.savePrivacyGroupPreferences(pgPreferences);
  }

  /**
   * Function that must be triggered once all cookie changes have been made. It will
   * reload the page so that Hub elements are rendered with the new privacy
   * behaviour (eg. stop/start tracking the visitor's activity).
   */
  static applyChanges() {
    window.location.reload();
  }
}

export default Privacy;
