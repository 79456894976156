import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './constants';
import Shared from './shared_util';
import MBP from '/app/libs/mbp/js/helper';

if (window.Hubs !== undefined) {
    Hubs.recommendation = window.Hubs.recommendation;
}

/** Make Hubs global for inline JavaScript */
window.Hubs = Hubs;

/** Set window of Iframe as root */
Hubs.root = window;

/**
 * Hubs Core
 * 
 * @class Core
 * @classdesc Base class for Hubs.App; provides underlying functionality for the application
 * @namespace Hubs
 * @constructor
 */
Hubs.Core = function(options) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null,
        optimizeMobile = null,
        browserIdentifiers = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    
    /**
     * Initialize the Core of the Application
     * 
     * @private
     * @this Hubs.Core
     * @param {Object} [options] Configuration Settings for the Class
     * @return undefined
     * @constructs
     */
    initialize = function() {
        var $body = Shared.$('body');
        
        // Web crawlers are findings url we don't want them to find... using partial urls and building
        $.each(options.serverUrl, function(key, value){
            if(key !== 'hub' && key !== 'cdn' && key !== 'ufa') {
                options.serverUrl[key] = options.hubBaseUrl + value;
            }
        });
        
        // Get Configuration Settings for App
        Hubs.Config = $.extend(true, Hubs.Config, options);
        
        // Breakout if Mobile in an iFrame and not Embedded
        if (window.top !== window.self) {
            if (Hubs.isMobile() && !Hubs.isEmbedded()) {
                window.top.location.href = window.self.location.href;
            } else {
                $body.addClass("iframe");
            }
        }
        
        // Check for Admin Preview Mode
        if (/inPreview=1/i.test(window.location.search)) {
            $body.addClass("inPreview");
        }
        
        // Update Logging/Profiling State
        Shared.Logger.enabled = Hubs.Config.enableDebugger;

        // Ensure Special Events are initialized; we need the debouncedresize event
        Hubs.initSpecialEvents();
        
        // Update Click Event Type
        if (Hubs.isMobile()) { 
            Hubs.TAP_EVENT = 'tapone.Hubs';
            Shared.$('document').on(Hubs.CLICK_EVENT, 'a', $.proxy(function(e) {
                if (!$(e.currentTarget).hasClass('hooked')) { return true; }
                e.preventDefault();
                e.stopPropagation();
                return false;
            }, this));
        }
        
        // Optimizations for Mobile Devices
        if (Hubs.isMobile()) { optimizeMobile(); }
        
        // Identify Browsers to CSS
        browserIdentifiers();
        
        // Prevent Errors
        if (!Hubs.Config.enableDebugger) {
            window.onerror = function() { return false; };
        }
    };
    
    /**
     * Optimizations for Mobile Devices
     *     - Should only be called by Hubs.Core.initialize
     * 
     * @private
     * @this Hubs.Core
     * @return undefined
     */
    optimizeMobile = function() {
        // Fix for iPhone viewport scale bug
        MBP.scaleFix();
        
        // Enable CSS active pseudo styles in Mobile Safari
        MBP.enableActive();
    };
    
    /**
     * Identify Browsers to CSS
     *     - Adds specific Classnames to the HTML tag
     * 
     * @private
     * @this Hubs.Core
     * @return undefined
     */
    browserIdentifiers = function() {
        // Check for Mobile
        if (Hubs.isMobile()) { Shared.$('html').addClass('mobile'); }
        
        // Check for IE10
        if (Shared.MS_IE_10) { Shared.$('html').addClass('ie10'); }
        
        // Check for Android 3
        if (Shared.ANDROID_3) { Shared.$('html').addClass('android3'); }
        
        // Check for Firefox in Android Tablets
        if (Shared.ANDROID_FF) { Shared.$('html').addClass('android-ff'); }
        
        // Check for Amazon Kindle
        if (Shared.AMAZON_SILK) { Shared.$('html').addClass('silk'); }
        
        // Check for BB Playbook 2.0
        if (Shared.BB_PLAYBOOK) { Shared.$('html').addClass('playbook'); }
        
        // Check for iOS
        if (Shared.IOS) { Shared.$('html').addClass('ios'); }
    };


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    if (options) { initialize(); }
};
// End of Hubs.Core

/* ******************************************************************************************** */
/* * Public Exposed Events                                                                    * */
/* ******************************************************************************************** */

/**
 * Exposed Event: onLoad - Called when the Hub Initially Loads
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *             For example, "this.pageType" will give you the Current Page Type (hub, collection or item)
 *             
 * @public 
 * @this Hubs.App
 * @return undefined
 */
Hubs.onLoad = function() {};

/**
 * Exposed Event: onResize - Called when the Hub is Resized or Reoriented
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *             For example, "this.pageType" will give you the Current Page Type (hub, collection or item)
 *             
 * @public 
 * @this Hubs.App
 * @return undefined
 */
Hubs.onResize = function() {};

/**
 * Exposed Event: onScroll - Called when the Hub is Scrolled (Debounced)
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *             For example, "this.pageType" will give you the Current Page Type (hub, collection or item)
 *             
 * @public 
 * @this Hubs.App
 * @return undefined
 */
Hubs.onScroll = function() {};

/**
 * Exposed Event: onItemsLoaded - Called when the More Items are Loaded within a Stream (Lazyloader or "More" button)
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *             For example, "this.pageType" will give you the Current Page Type (hub, collection or item)
 *             
 * @public 
 * @this Hubs.App
 * @param {Array} itemIds - An array of Integers of the Item IDs that were Loaded
 * @param {String} itemsSelector - An Element Selector for Querying the DOM for the Loaded Tiles
 * @return undefined
 */
Hubs.onItemsLoaded = function(itemIds, itemsSelector) {};

/**
 * Exposed Event: onPageChange - Called when the Hub Changes Pages Internally
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *             For example, "this.pageType" will give you the Current Page Type (hub, collection or item)
 *            
 * @public 
 * @this Hubs.App
 * @return undefined
 */
Hubs.onPageChange = function() {};

/**
 * Exposed Event: onCtaActivate - Called when the CTA is activated
 *
 * @public
 * @this Hubs.App
 * @param {int} ctaId
 * @return undefined
 */
Hubs.onCtaActivate = function(ctaId) {};

/**
 * Exposed Event: onCtaFormSubmitSuccess - Called when a form CTA submissions returns successful
 *     - Note: Since the "this" variable has been bound the the Instance of the Hubs App,
 *             all Internal Variables/Methods are available via "this".
 *            
 * @public 
 * @this Hubs.App
 * @return undefined
 */
Hubs.onCtaFormSubmitSuccess = function(ctaId, ctaData, ctaName) {};

/* ******************************************************************************************** */
/* * Public Static Methods                                                                    * */
/* ******************************************************************************************** */

/** {Object} appInstance a Reference to the Instance of the Hub Application */
Hubs.appInstance = null;

/**
 * Changes the Hub Page to using internal mechanisms
 *            
 * @public 
 * @this Hubs
 * @return undefined
 */
Hubs.changePage = function(url, type, title) {
    if (Hubs.appInstance === null) { return; }
    
    // Request Internal Page Change
    Hubs.appInstance.requestInternalPageChange(url, type, title);
};

export default Hubs;
