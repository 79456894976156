import { ufa } from '@uberflip/ufa-client';
import cookies from 'js-cookie';
import getCtaFormProperties from './ctaFormProperties';
import getImpressionTrackingRules from './impressionTrackingRules';
import getLinkCtaTrackingRules from './ctaLinkProperties';
import getScrollDepthTrackingRules from './scrollDepthTrackingRules';
/* eslint-disable no-underscore-dangle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["optIn", "optOut"] }] */

const TIMER_INTERVAL = 2;

/**
 * This is an abstraction over `ufa` to allow us to consolodate code in one place, and avoid
 * repeating the same logic or finding the same information on different pages. Just like `ufa`
 * you must call `init` before calling other methods.
 *
 * @class UfaCaller
 */
class UfaCaller {
  constructor({
    ufaUrl,
    accountId,
    hubId,
    pageType,
    streamId,
    itemId,
    authorId,
    pardotCookie,
    connectedMAPs,
    requireOptIn,
  }) {
    this.ufaUrl = ufaUrl; // The url to point ufa at
    this.accountId = accountId;
    this.hubId = hubId;
    this.pageType = pageType;
    this.hubId = hubId;
    this.streamId = streamId;
    this.itemId = itemId;
    this.authorId = authorId;
    this.pageType = pageType;
    this.isCtaEmbed = pageType === 'cta';
    this.connectedMAPs = connectedMAPs.map((map) => map.toUpperCase());
    this.requireOptIn = requireOptIn;
    this.MAP_TO_COOKIE_NAME = {
      ELOQUA: 'eloquautk',
      HUBSPOT: 'hubspotutk',
      MARKETO: '_mkto_trk',
      PARDOT: pardotCookie, // Pardot cookie names are dynamic, passed from the server
    };

    Object.freeze(this.MAP_TO_COOKIE_NAME);
  }

  init() {
    ufa.load(window, this.ufaUrl, []);
    ufa('init', {
      accountId: this.accountId,
      requireOptIn: this.requireOptIn,
      ...getImpressionTrackingRules(),
      ...getScrollDepthTrackingRules(),
    });
    return this;
  }

  trackRecoPanelImpressions() {
    ufa('track_reco_panel_impressions');
    return this;
  }

  trackLazyLoadImpressions() {
    ufa('track_lazy_load_impressions');
    return this;
  }

  trackLazyLoadScrollDepth() {
    ufa('track_lazy_load_viewport_adjustments');
    return this;
  }

  trackTimeOnPage() {
    ufa('track_time_on_page', {
      timerInterval: TIMER_INTERVAL,
    });
    return this;
  }

  trackCtaFormActivation() {
    ufa('activate_form_cta', {
      ...getCtaFormProperties(this.ctaId, this.ctaTile),
    });
    return this;
  }

  trackCtaFormSubmit() {
    ufa('submit_form_cta', {
      ...getCtaFormProperties(this.ctaId, this.ctaTile),
    });
    return this;
  }

  trackCtaLinkClick(ctaUrl) {
    ufa('click_link_cta', {
      ...getLinkCtaTrackingRules(this.ctaId, this.ctaTile),
      ctaUrl,
    });
    return this;
  }

  trackPageView() {
    const { referrer } = document;

    ufa('view_page', {
      authorId: this.authorId,
      hubId: this.hubId,
      isEmbedded: this.isCtaEmbed,
      itemId: this.itemId,
      pageType: this.pageType,
      streamId: this.streamId,
      url: window.location.href,
      ...(this.isCtaEmbed ? { parent: referrer, referrer: undefined } : { referrer }),
    });
    return this;
  }

  trackFlipbookPageView(flipbookId, flipbookPageId, pageNumber) {
    ufa('view_flipbook_page', {
      flipbookId,
      flipbookPageId,
      pageNumber,
    });
    return this;
  }

  trackFlipbookDownload(flipbookId) {
    ufa('download_flipbook', {
      flipbookId,
    });
    return this;
  }

  trackVisitorData() {
    // get & wait for all connect MAP cookies
    const trackableMaps = this.connectedMAPs
      // filter out maps with unknown cookie names
      .filter((mapName) => this.MAP_TO_COOKIE_NAME[mapName])
      .reduce(
        (obj, mapName) => ({
          ...obj,
          [`${mapName.toLowerCase()}Id`]: () => cookies.get(this.MAP_TO_COOKIE_NAME[mapName]),
        }),
        {},
      );

    // get or wait for legacy uberflip metrics cookie
    const trackableLegacy = {
      uberflipLegacyMetricsId: () => cookies.get('pdf_event'),
    };

    // get or wait for bombora connection
    const trackableBombora = {
      bomboraSegments: () => {
        const us = window._ml ? window._ml.us : null;
        const ccmaid = window.localStorage ? localStorage.getItem('_ccmaid') : null;
        if (!us || !ccmaid) {
          return false;
        }

        return {
          ...us,
          ccmaid,
        };
      },
    };

    ufa.enqueueTrackables(
      {
        ...trackableMaps,
        ...trackableLegacy,
        ...trackableBombora,
      },
      (trackables) => ufa('add_visitor_data', trackables),
    );

    return this;
  }

  optIn() {
    return new Promise((resolve) => {
      ufa('opt_in', null, resolve);
    });
  }

  optOut() {
    return new Promise((resolve) => {
      ufa('opt_out', null, resolve);
    });
  }

  /**
   * Repeatedly checks for the presence of a given set of entities (every 500ms),
   * and executes a callback function
   * any time an entity is found.
   * Repeated checks stop after 120 retries - ie. 1 minute (500ms * 120 retries) - by default.
   *
   * @param {Object} trackables - An object containing 1 or more key-value pairs,
   *  where the key is an arbitrary name
   *  for the entity, and the value is a function used to check for the presence of that entity.
   *  eg.
   *  {
   *      "marketoCookie" : function () { return $.cookie('_mkto_trk'); },
   *      "eloquaCookie" :  function () { return $.cookie('eloquautk'); }
   *  }
   * @param {function} callback - A callback function that is executed each time an entity is found.
   * @param {number} [retries] - The number times to check for the entities'
   *  presence, with a 500ms interval.
   *  Default is 120 retries - ie. 1 minute (500ms * 120 retries)
   */
  static enqueueTrackables = ufa.enqueueTrackables;

  /**
   * For Uberflip Analytics (UFA), determine if this visitor must click "Accept" on the
   * Privacy Banner or Privacy Group (on Privacy Policy page), before we begin tracking
   * non-anonymously.
   *
   * Note: if the visitor does NOT accept the Privacy Setting, we will track them
   * anonymously; meaning, their actions will not have any Personal Identifying Info
   * (name, email, phone number, address, etc) attached.
   *
   * "ufaRequiresOptIn" is true when:
   *   - a Privacy Group has been linked to `UBERFLIP` in this Hub, and
   *   - the visitor has NOT clicked "Accept" for this Privacy Group.
   *
   * @param Config: appOptions object from the script section in the relevant thtml file
   *
   * @return bool
   */
  static ufaRequiresOptIn(Config) {
    return Config.disableUfMetrics;
  }
}

export default UfaCaller;
