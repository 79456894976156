import Shared from './shared_util';

Shared.Tracker = {}

/* ******************************************************************************************** */
/* * Private Static Members                                                                   * */
/* ******************************************************************************************** */
Shared.Tracker.analyticsCodes = [];

/* ******************************************************************************************** */
/* * Public Static Methods                                                                    * */
/* ******************************************************************************************** */

/**
 * Track Page Change Events via Google Analytics
 *
 * @public
 * @this Shared.Tracker
 * @param {String} pageUrl The URL of the Page being tracked
 * @param {Object} stateData The State of the Page being tracked
 * @return undefined
 */
Shared.Tracker.trackPage = function(pageUrl, stateData) {
    var i, n, url = '';
    if (window.gtag === undefined || !Shared.Tracker.analyticsCodes.length) { return; }

    var blacklistedParams = Hubs.Config.ufQueryStrings;
    var parsedUrl = Hubs.url.parse(pageUrl);
    var path = parsedUrl.path;
    var queries = parsedUrl.query || '';


    var cleanedParams = queries
            .split('&')
            .filter(function(query){
                var pair = query.split('=');
                var key = pair[0];
                var isBlacklisted = blacklistedParams.includes(key);
                return !isBlacklisted; // only allow non-blacklisted items to pass the filter
            })
            .join('&');


    pageUrl = path;
    if (cleanedParams.length) {
        pageUrl += '?' + cleanedParams;
    }

    // Add URL to GA Tracker
    for (i = 0, n = Shared.Tracker.analyticsCodes.length; i < n; i++) {
        Shared.Logger.log({'msg': 'tracking-page', 'args': {'page': pageUrl, 'code': Shared.Tracker.analyticsCodes[i]}, 'type': Shared.LVL_DEBUG});
        gtag('event', 'page_view', { 'send_to': Shared.Tracker.analyticsCodes[i] });
    }
};

/**
 * Track Event
 *
 * @public
 * @this Shared.Tracker
 * @param {String} category
 * @param {String} action
 * @param {String} label
 * @return undefined
 */
Shared.Tracker.trackEvent = function(category, action, label) {
    var i,n;

    if (window.gtag === undefined || !Shared.Tracker.analyticsCodes.length || !category || !action) { return; }
    for (i = 0, n = Shared.Tracker.analyticsCodes.length; i < n; i++) {

        // Log Event Tracking for each GA code
        Shared.Logger.log({'msg': 'tracking-event', 'args': {'event': 'Category:' + category + ' Action:' + action + ' Label:' + label, code: Shared.Tracker.analyticsCodes[i]}, 'type': Shared.LVL_DEBUG});

        gtag('event', action, {event_category: category, event_label: label});
    }
};

/**
 * Adds Analytics Codes to the List for Tracking
 *
 * @public
 * @this Shared.Logger
 * @param {Object} l10n An object containing key->value associations for text conversions
 * @return undefined
 */
Shared.Tracker.addAnalyticsCodes = function(analyticsCodes) {
    if (analyticsCodes && analyticsCodes.length) {
        Shared.Tracker.injectGaScript(analyticsCodes);
    }

    var i = 0, n = 0;
    for (n = analyticsCodes.length; i < n; i++) {
        Shared.Tracker.analyticsCodes.push(analyticsCodes[i]);
    }
};

/**
 * Only inject Google Analytics script if there are GA Codes provided for this Hub.
 */
Shared.Tracker.injectGaScript = function (analyticsCodes) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
        window.dataLayer.push(arguments);
    };
    gtag('js', new Date());
    if (!Array.isArray(analyticsCodes)) {
        analyticsCodes = [analyticsCodes];
    }
    var gtagScript = document.createElement('script');
    gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + analyticsCodes[0]);
    gtagScript.async = true;
    document.head.appendChild(gtagScript);
    for (var index = 0; index < analyticsCodes.length; ++index) {
        var analyticsCode = analyticsCodes[index];
        gtag('config', analyticsCode, {
            cookie_expires: 31536000,
            send_page_view: false,
            cookie_flags: 'secure;samesite=none',
        });
    }
};
