const Shared = {};

// Empty Value Regex
Shared.EMPTY = /^\s*$/;

// Month Names
Shared.MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Logging Level Types
Shared.LVL_DEBUG    = 'debug';
Shared.LVL_ERROR    = 'error';
Shared.LVL_WARNING  = 'warn';
Shared.LVL_INFO     = 'info';

// Key Codes
Shared.KB_ENTER  = 13;
Shared.KB_ESCAPE = 27;
Shared.KB_DELETE = 46;

// Browser Detection
Shared.MS_IE_10 = (window.navigator.msPointerEnabled !== undefined);
Shared.Chrome = (!!window.chrome && !window.opera);   // Chrome

Shared.AMAZON_SILK = (new RegExp('(?=.*Silk)(?=.*Mobile)', 'i').test(window.navigator.userAgent));
Shared.ANDROID_3 = (new RegExp('(?=.*Android 3)', 'i').test(window.navigator.userAgent));
Shared.ANDROID_FF = (new RegExp('(?=.*Android)(?=.*Tablet)(?=.*Firefox)', 'i').test(window.navigator.userAgent));
Shared.BB_PLAYBOOK = (new RegExp('(?=.*Playbook)(?=.*Webkit)', 'i').test(window.navigator.userAgent));
Shared.CriOS = (new RegExp('(?=.*CriOS)', 'i').test(window.navigator.userAgent));   // Chrome on iOS
Shared.OPERA_MINI = (Object.prototype.toString.call(window.operamini) === '[object OperaMini]');
Shared.NOKIA_XPRESS = (new RegExp('(Nokia311|NokiaX3)', 'i').test(window.navigator.userAgent));
Shared.IOS  = (new RegExp('(iPad|iPhone);.*CPU.*OS', 'i').test(window.navigator.userAgent));
Shared.IOS6 = (new RegExp('(iPad|iPhone);.*CPU.*OS 6_', 'i').test(window.navigator.userAgent));
Shared.IOS7 = (new RegExp('(iPad|iPhone);.*CPU.*OS 7_', 'i').test(window.navigator.userAgent));
Shared.IOS8 = (new RegExp('(iPad|iPhone);.*CPU.*OS 8_', 'i').test(window.navigator.userAgent));
Shared.IOS8_1 = (new RegExp('(iPad|iPhone);.*CPU.*OS 8_1', 'i').test(window.navigator.userAgent));
Shared.IOS9 = (new RegExp('(iPad|iPhone);.*CPU.*OS 9_', 'i').test(window.navigator.userAgent));

// Recommendation Engine types
Shared.RECOMMENDATION_ENGINE_TYPE_AI = 1;
Shared.RECOMMENDATION_ENGINE_TYPE_STREAM = 2;

// Message types for postMessage/addEventListener
Shared.MESSAGE_TYPES = {
    FLIPBOOK_EVENT: 'flipbook.event',
};

export default Shared;
