import Hubs from './core';
import cookies from 'js-cookie';

Hubs.PrivacyHelpers = {
  ufaOptIn: function () {
    if (Hubs.Config.isPreventAnalyticsCollectionEnabled) return;

    var ufaCaller = Hubs.appInstance.ufaCaller;
    if (!ufaCaller) return;

    ufaCaller.optIn().catch(function (err) {
      // eslint-disable-next-line no-console
      console.warn('ufa opt-in failed', err);
    });
  },

  ufaOptOut: function () {
    if (Hubs.Config.isPreventAnalyticsCollectionEnabled) return;

    var ufaCaller = Hubs.appInstance.ufaCaller;
    if (!ufaCaller) return;

    ufaCaller.optOut().catch(function (err) {
      // eslint-disable-next-line no-console
      console.warn('ufa opt-out failed', err);
    });
  },

  savePrivacyGroupPreferences: function (pgPreferences) {
    var privacyCookieValue = this.formatPrivacyCookieValue(pgPreferences);
    this.setPrivacyCookieValue(privacyCookieValue);
  },

  formatPrivacyCookieValue: function (groupsArray, changeBannerState) {
    var cookieVersion = '1';
    var bannerState =
      typeof changeBannerState !== 'undefined' ? changeBannerState : this.getPrivacyBannerState();

    // Format is "{groupId},{timestamp},{accepted}|..."
    // e.g. "10,33423123,0|12,1231231458,1|..."
    var privacyGroupsCsv = $.map(groupsArray, function (privacyGroup) {
      return [privacyGroup.id, privacyGroup.version, privacyGroup.accepted].join(',');
    });

    return [cookieVersion, bannerState, privacyGroupsCsv.join('|')].join('|');
  },

  getPrivacyBannerState: function () {
    var cookie = cookies.get(Hubs.PRIVACY_PREFS_COOKIE_NAME);
    if (!cookie) return Hubs.SHOW_NO_BANNER;
    return parseInt(cookie.split('|')[1], 10);
  },

  setPrivacyCookieValue: function (value) {
    cookies.set(Hubs.PRIVACY_PREFS_COOKIE_NAME, value, { expires: 365, path: '/' });
  },
};
