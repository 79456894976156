import 'jquery-migrate';
import $ from 'jquery';
import Cookie from 'js-cookie';
import fitvids from 'fitvids';
import PerfectScrollbar from 'perfect-scrollbar';

/**
 * Wrap the browser's history object's methods with a function
 * that will trigger jQuery events we can hook into, under the
 * namespace "history" EX. history.pushState
 *
 *
 * @param {History instance} history
 */
export const wrapHistory = function(history = window.history) {

  if(history.hasBeenWrapped) {
    return history;
  }

  // Return a copy of a function that triggers a history event
  const eventWrapper = function(functionName) {
    const originalFunction = history[functionName];
    return function() {
      const returnValue = originalFunction.apply(this, arguments);
      $(window).trigger(`history.${functionName}`, arguments);
      return returnValue;
    };
  };

  // Replicate jquery.history functionality
  history.getState = () => history.state;

  // Add event trigger for all hisotry object methods
  for (let property in history) {
    const currentProperty = history[property];
    if( typeof currentProperty === 'function') {
      history[property] = eventWrapper(property);
    }
  }

  history.hasBeenWrapped = true;

  return history;
};



/**
 * A set of functions for adding functionality we lost when various jQuery plugins were updated,
 * in order to be backwards compatible with custom code.
 */
export const augmentJquery = {

  withCookie: function(jQuery) {
    jQuery.cookie = Cookie;
  },

  /**
   * Note: This function will look for the specified players anywhere
   * on the page. The fact that it is accessible via the jQuery selector
   * object is only for backwards compatibility.
   */
  withFitVids: function(jQuery) {
    jQuery.fn.fitVids = function() {
      // Looks for these elements by default:
      // --- 'iframe[src*="player.vimeo.com"]',
      // --- 'iframe[src*="youtube.com"]',
      // --- 'iframe[src*="youtube-nocookie.com"]',
      // --- 'iframe[src*="kickstarter.com"][src*="video.html"]',
      // --- 'object'
      // Add custom elements in the "players" array below
      fitvids({
        players: [
          '.wistia_embed'
        ]
      });
    };
  },

  withPerfectScrollbar: function(jQuery) {
    jQuery.fn.perfectScrollbar = function(command) {
      const args = arguments;
      this.each(function(i, el){
        if(!el.ps) {
        // If the element doesn't have a PS instance initialized, make one
          el.ps = new PerfectScrollbar( el, ...args);
        } else if(typeof el.ps[command] === 'function') {
        // otherwise, execute the requested function if it exists
          el.ps[command]();
          if(command === 'destroy') {
            el.ps = null;
          }
        }
      });

      return this;
    };
  },


  withAllPollyfills: function(jQuery) {
    this.withCookie(jQuery);
    this.withFitVids(jQuery);
    this.withPerfectScrollbar(jQuery);
  },
};

export default {
  wrapHistory,
  augmentJquery
};

