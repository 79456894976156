import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

/**
 * Hubs LazyLoader
 *
 * @class LazyLoader
 * @classdesc Application LazyLoader for Infinite Scrolling on Collection Pages
 * @namespace Hubs
 * @constructor
 */
Hubs.LazyLoader = function(app) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null,
        revertCollectionPage = null,
        initEvents = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */

    /** @member {Object} app A reference to the Application Object */
    this.app              = app;

    /** @member {Object} $container A jQuery Element Handle to the Container DIV of the LazyLoader  */
    this.$container       = null;

    /** @member {Object} $loadingNotifier A jQuery Element Handle to the Container DIV of the Loading Notifier Box */
    this.$loadingNotifier = null;

    /** @member {Object} $scrollContainer A jQuery Element Handle to the Container DIV that Scrolls (Window or Other) */
    this.$scrollContainer = null;

    /** @member {Number} collectionId The ID of the Collection currently being viewed */
    this.collectionId     = 0;

    /** @member {Number} containerOffset The Top Offset of the Container */
    this.containerOffset  = 0;

    /** @member {Number} itemCount The Number of Items Currently Displayed */
    this.itemCount        = 0;

    /** @member {Number} itemSize The Outer Width or Height of an Item in the List */
    this.itemSize         = 0;

    /** @member {Number} featuredItemsCount The number of featured items */
    this.featuredItemsCount    = 0;

    /** @member {Number} viewportSize The Width or Height of the Viewport  */
    this.viewportSize     = 0;

    /** @member {Number} lastItemBounds The X or Y Boundary of the Last Item in the List  */
    this.lastItemBounds   = 0;

    /** @member {Number} currentPage The Current Page Displayed within the Collection */
    this.currentPage      = 0;

    /** @member {Number} scrollTop The Current Scroll Top ofthe Window or Parent Window */
    this.scrollTop        = 0;

    /** @member {Boolean} loadingItems A flag indicating whether Items are currently being loaded or not */
    this.loadingItems     = false;

    /** @member {Boolean} endOfCollection A flag indicating whether we have reached the end of the collection */
    this.endOfCollection  = false;

    /** @member {Object} $lastSection A jQuery Element Handle to the section DIV of the LazyLoader  */
    this.$lastSection = null;


    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */

    /**
     * Initialize the LazyLoader
     *
     * @private
     * @this Hubs.LazyLoader
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Statement
        Shared.Logger.log('lazy-loader-init');

        // Get Handles to Container Element
        this.$container = $(Hubs.Config.lazyloader.elements.container);
        this.$loadingNotifier = $(Hubs.Config.lazyloader.elements.notify);
        if (!this.$container.length) { return; }

        // Store Reference to Hubs.App
        this.app = app;

        // Initialize page number from saved collection state
        revertCollectionPage();

        // Set Default Scroll Container
        this.$scrollContainer = Shared.$('window');

        // Get Collection ID
        this.collectionId = this.app.currentCollectionId;

        // Store Top Offset of Container
        this.containerOffset = (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? this.app.$appContainer[0].offsetTop : this.app.$appContainer[0].offsetLeft;

        // Initialize Events
        initEvents();

        // Debug Statement
        Shared.Logger.log('lazy-loader-init-end');
    }, this);

    /**
     * revertCollectionPage():
     *   Initialize page number from a previously saved collection state.
     *
     * @private
     * @this Hubs.LazyLoader
     * @return undefined
     */
    revertCollectionPage = $.proxy(function() {
        var collectionState = this.app.getCollectionState();
        if (collectionState) {
            this.currentPage = parseInt(collectionState.page, 10);
        }
    }, this);

    /**
     * Initialize Events for the Hubs LazyLoader
     *
     * @private
     * @this Hubs.LazyLoader
     * @return undefined
     */
    initEvents = $.proxy(function() {
        // Debug Statement
        Shared.Logger.log('lazy-loader-events-init');

        // Window Scroll Event
        Shared.$('window')
            .on('debouncedresize.Hubs.LazyLoader', $.proxy(this.resizeEvent, this));

        // Set event by loading type
        if (Hubs.Config.labOptions.loadByButton) {
            Shared.$('document').on('click.Hubs.LazyLoader', Hubs.Config.lazyloader.elements.loadMore, $.proxy(this.clickEvent, this));
        } else {
            Shared.$('window').on('scroll.Hubs.LazyLoader', $.proxy(this.scrollEvent, this));
        }

        this.app.$appContainer.parent()
            .on('scroll.Hubs.LazyLoader', $.proxy(this.scrollEvent, this));
    }, this);

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();

    return this;
};
// End of Hubs.LazyLoader


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */


/**
 * Observes and Responds to the Resize Events fired on the Global Window Object
 *
 * @public
 * @this Hubs.LazyLoader
 * @param {Object} eventData The Event Data associated with the Event
 * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
 */
Hubs.LazyLoader.prototype.resizeEvent = function(eventData) {
    // Debug Statement
    Shared.Logger.log('lazy-loader-resize');

    // Update Sizes
    Hubs.root.setTimeout($.proxy(this.updateSizes, this), 250); // just after app resize event
    return true;
};

/**
 * Observes and Responds to the Scroll Events fired on the Global Window Object
 *
 * @public
 * @this Hubs.LazyLoader
 * @param {Object} eventData The Event Data associated with the Event
 * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
 */
Hubs.LazyLoader.prototype.scrollEvent = function(eventData, scrollTop, offsetTop, viewportX, viewportY) {
    // Check for More Pages
    if (this.loadingItems || this.endOfCollection || Shared.$('body').hasClass('noscroll')) { return; }

    // Debug Statement
    //Shared.Logger.log('lazy-loader-scroll');

    // Get Handle to Scroll Container
    if (!Hubs.isEmbedded()) {
        if (eventData.currentTarget === Hubs.root) {
            this.$scrollContainer = Shared.$('window');
        } else {
            this.$scrollContainer = this.app.$appContainer.parent();
        }
    }

    // Get Scroll Top of Current Window
    this.scrollTop = this.$scrollContainer.scrollTop();

    // Get Scroll Top from Parent Window (if defined)
    if (scrollTop !== undefined) {
        this.scrollTop = scrollTop - offsetTop;
    }

    // Get Parent Window Viewport (if defined)
    if (viewportX !== undefined && viewportY !== undefined) {
        this.viewportSize = (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? viewportY : viewportX;
    }

    // Update Sizes to get Last Item Bounds
    this.updateSizes();

    // Check Boundary to Load More Items
    this.checkBoundary(false);
    return true;
};

/**
 * Observes and Responds to the Click Event fired when the "load more" button clicked
 *
 * @public
 * @this Hubs.LazyLoader
 * @param {Object} eventData The Event Data associated with the Event
 * @return undefined
 */
Hubs.LazyLoader.prototype.clickEvent = function(eventData) {
    var btn = Shared.$('document').find(Hubs.Config.lazyloader.elements.loadMore);
    // Hide button
    btn.hide();
    // Load more items and show button
    var showBtnCallback = function() {
        if (this.app.pageType ==  Hubs.PAGE_TYPE_HUB || (this.app.collectionCount > this.app.lazyLoader.itemCount)) {
            btn.show();
        }
    };
    this.loadItems(false, showBtnCallback.bind(this));
};

/**
 * Loads the next set of items via Ajax and populates the page with new items
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.loadItems = function(initialLoad, callback) {
    var data, $items, itemCount;
    if (this.loadingItems || this.endOfCollection) { return; }

    // Check if We Need to LazyLoad
    if (!this.checkItemCount()) { return; }

    // Update Loading Flag
    this.setLoading(true);

    // Change Page based on State
    try {
        // Data to Request Next Set of Items
        data = {
            'collectionId': this.collectionId,
            'currentPage': this.currentPage + 1,
            'limit': Hubs.Config.lazyloader.itemLoadCount,
            'featuredItemCount': Hubs.Config.lazyloader.featuredItemCount,
            'embedded': Hubs.isEmbedded() ? 1 : 0
        };
        if(this.app.pageType == Hubs.PAGE_TYPE_AUTHOR) {
            data.authorId = this.app.currentAuthorId;
        }

        // Check if we need to delay for testing
        if (Hubs.Config.ajaxResponseDelay !== null) {
            data = $.extend(true, {}, data, Hubs.Config.ajaxResponseDelay);
        }

        this.$lastSection = this.$container.find(Hubs.Config.lazyloader.elements.section).last();
        var lastSectionId = this.$lastSection.attr('id');

        // Fetch Next Set of Items via Ajax
        Shared.ajaxFetch(Hubs.Config.serverUrl.lazyLoader, data, 'GET', false, $.proxy(function(itemData) {
            // Clean up Item Data
            itemData = Shared.trim(itemData);

            // Check if we have more items to display
            if (!itemData.length) {
                // Update Loading Flag
                this.setLoading(false);
                this.endOfCollection = true;
                return;
            }

            var sectionIndex = $('.collection-section').length;
            var $sections = $('<div />').append(itemData).find(Hubs.Config.lazyloader.elements.section);
            // when beta feature is turned on, $sections length should be greater than 0; when it is off, $sections.length == 0
            if($sections.length > 0) {
                $sections.each($.proxy(function(i, el) {
                    var id = $(el).attr('id');
                    var $sectionOnPage = $('#'+id);
                    if (id === lastSectionId) {
                        $sectionOnPage.find('.collection-section-items').append($(el).find('.tile'));
                    } else {
                        var sectionIndexString = 'section-index-' + sectionIndex;
                        $(el).addClass(sectionIndexString);
                        $(el).find('.section-index-link').attr('id', sectionIndexString);
                        sectionIndex = sectionIndex + 1;
                        if (this.$loadingNotifier.length) {
                            this.$loadingNotifier.before(el);
                        } else {
                            this.$container.append(el);
                        }
                    }
                }, this))
            } else {
                // Insert Items into Container
                if (this.$loadingNotifier.length) {
                    this.$loadingNotifier.before(itemData);
                } else {
                    this.$container.append(itemData);
                }
            }

            // Check Count of Items
            this.checkItemCount();

            // Resize All Image Tiles
            Hubs.updateImageTiles(this.app, '.tile .img');

            // Run friendly timestamp plugin on new html
            Hubs.setFriendlyTimestamps();

            // Sets up the form cta handling
            this.app.initializeFormCtas();

            // Need to resize the CTA text for forms
            this.app.responsiveCtaText();

            // New CTAs from lazy loader need to be determined if they should be seen
            this.app.showFormCtas(this.app.lazyLoader, this.app.pageType);

            // Update Links
            this.app.overrideLinks();

            // Update Sizes and Counts after Adding Items
            Hubs.root.setTimeout($.proxy(function() {
                // Update Sizes to get Last Item Bounds
                this.updateSizes();

                // Increment Current Page
                this.currentPage++;

                // Update page number for collection state
                var scrollTop = (this.app.getCollectionState() || {}).scrollTop;
                this.app.setCollectionState(this.currentPage, scrollTop);

                // Update Loading Flag
                this.setLoading(false);

                // Check Boundary to Load More Items
                if (initialLoad) {
                    this.checkBoundary(initialLoad);
                }

                // Internal Callback after Load
                if (typeof callback === 'function') {
                    callback();
                }

                // External Callback after Load
                if (typeof Hubs.onItemsLoaded === 'function') {
                    Shared.onNextEventLoop($.proxy(function() {
                        var $items = $('<ul/>').append(itemData);
                        var itemIds = [];
                        var selector = '', begin = '.tile[data-id="', end = '"]';

                        // Get all Item Ids
                        $items.find('.tile[data-id]').each($.proxy(function(idx, el) {
                            itemIds.push( parseInt($(el).attr('data-id') || 0, 10) );
                        }, this));

                        // Build Element Selector for Querying Elements
                        selector = begin + itemIds.join(end + ',' + begin) + end;

                        // Call Event Handler passing Item IDs and Selector
                        Hubs.onItemsLoaded.apply(this.app, [itemIds, selector]);

                        // emit itemsLoaded hub event
                        Hubs.Events.trigger('itemsLoaded', [itemIds, selector]);

                    }, this));
                }
            }, this), 100);
        }, this));
    } catch (e) {
        // Error Statement
        if (e instanceof Shared.Exception) {
            Shared.Logger.log(e);
        } else {
            Shared.Logger.log({'msg': 'ajax-failed', 'args': {'reason': e}, 'type': Shared.LVL_ERROR});
        }
    }
};

/**
 * Update the Size and Boundary of the Last Item in the List
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.updateSizes = function() {
    // Get Last Item in List
    var $allItems = this.$container.find(Hubs.Config.lazyloader.elements.item),
        $lastItem = ($allItems.length) ? $($allItems[$allItems.length-1]) : $allItems,
        pos = null;

    if (!$lastItem.length) { return; }

    // Get Number of Items
    this.itemCount = $allItems.length;

    // Get Top Offset of Container
    this.containerOffset = (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? this.app.$appContainer[0].offsetTop : this.app.$appContainer[0].offsetLeft;

    // Get Size of Viewport
    if (!Hubs.isEmbedded()) {
        this.viewportSize = (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? Shared.$('window').height() : Shared.$('window').width();
    }

    // Get Size of Last Item
    this.itemSize = (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? $lastItem.outerHeight() : $lastItem.outerWidth();

    // Get Bounds of Last Item
    this.lastItemBounds = this.containerOffset;
    this.lastItemBounds += (Hubs.Config.lazyloader.direction === Hubs.LAZYLOAD_VERTICAL) ? $lastItem[0].offsetTop : $lastItem[0].offsetLeft;
    this.lastItemBounds += (this.itemSize / 2);
};

/**
 * Checks the Boundary of the Last Item in the List and Loads More Items if necessary
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.checkBoundary = function(initialLoad) {
    // Get the ScrollTop for the Container
    var scrollTop = this.scrollTop + this.viewportSize;

    // Check if we need to Load More Items
    if (scrollTop > this.lastItemBounds) {
        this.loadItems(initialLoad);
    }
};

/**
 * Checks the Item Count and Cancels LazyLoading if not enough Items
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.checkItemCount = function() {
    var itemCount = 0,
        $items = $(Hubs.Config.lazyloader.elements.item + ', ' + Hubs.Config.cta.elements.container);

    itemCount = $items.length;
    if (itemCount < Hubs.Config.lazyloader.itemDisplayLimit) {
        this.endOfCollection = true;
    }

    return !this.endOfCollection;
};

/**
 * Toggles the display of the Loading More Indicator
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.loadingNotifier = function() {
    // Build Loading Notifier Element
    if (this.$loadingNotifier === null || !this.$loadingNotifier.length) {
        this.$loadingNotifier = $(Hubs.Config.lazyloader.elements.notify);
        if (!this.$loadingNotifier.length) { return; }
    }

    // Show/Hide Loading Notifier
    if (this.loadingItems) {
        this.$loadingNotifier.show();
    } else {
        this.$loadingNotifier.hide();
    }
};

/**
 * Set the Loading State for the Loading Notifier
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.setLoading = function(loading) {
    // Update Loading Flag
    this.loadingItems = loading;

    // Update Loading Notifier
    this.loadingNotifier();
};

/**
 * Completely destroy the iScroll and free some memory
 *
 * @public
 * @this Hubs.LazyLoader
 * @return undefined
 */
Hubs.LazyLoader.prototype.destroy = function() {
    // Debug Statement
    Shared.Logger.log('lazy-loader-destroy');

    // Remove Events that are tied to the Window Object
    Shared.$('window').off('debouncedresize.Hubs.LazyLoader');

    // Remove event by loading type
    if (Hubs.Config.labOptions.loadByButton) {
        Shared.$('document').off('click.Hubs.LazyLoader', Hubs.Config.lazyloader.elements.loadMore);
    } else {
        Shared.$('window').off('scroll.Hubs.LazyLoader');
    }
};

