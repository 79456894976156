const Hubs = {};

// Collection Types
Hubs.COLLECTION_TYPE_NONE     = 'none';
Hubs.COLLECTION_TYPE_FEATURED = 'featured';
Hubs.COLLECTION_TYPE_DOCS     = 'docs';
Hubs.COLLECTION_TYPE_VIDEOS   = 'videos';
Hubs.COLLECTION_TYPE_SOCIAL   = 'social';
Hubs.COLLECTION_TYPE_BLOGS    = 'blogs';

// Item-Collection Mapping (for determining which collection type an item belongs to)
Hubs.ITEM_COLLECTION_MAP  = {
    'uberflip' : Hubs.COLLECTION_TYPE_DOCS,
    'vimeo'    : Hubs.COLLECTION_TYPE_VIDEOS,
    'youtube'  : Hubs.COLLECTION_TYPE_VIDEOS,
    'wistiar'  : Hubs.COLLECTION_TYPE_VIDEOS,
    'vidyard'  : Hubs.COLLECTION_TYPE_VIDEOS,
    'twitter'  : Hubs.COLLECTION_TYPE_SOCIAL,
    'instagram': Hubs.COLLECTION_TYPE_SOCIAL,
    'blogpost' : Hubs.COLLECTION_TYPE_BLOGS,
    'blogs'    : Hubs.COLLECTION_TYPE_BLOGS
};

// Page Types
Hubs.PAGE_TYPE_HUB        = 'hub';
Hubs.PAGE_TYPE_COLLECTION = 'collection';
Hubs.PAGE_TYPE_ITEM       = 'item';
Hubs.PAGE_TYPE_AUTHOR     = 'author';
Hubs.PAGE_TYPE_PRIVACY    = 'privacy';
Hubs.PAGE_TYPE_CTA        = 'cta';

// Collection Page Types
Hubs.COLLECTION_PAGE_TYPES = [Hubs.PAGE_TYPE_COLLECTION, Hubs.PAGE_TYPE_HUB, Hubs.PAGE_TYPE_AUTHOR];

// Type Determination Regex's
Hubs.REGEX_FLIPBOOK    = /uberflip|flipbook/i;

// Size of Small Screen for Small Screen Device Detection
Hubs.SMALL_SCREEN_HEIGHT = 500;

// Size for defining Large Content Images
Hubs.LARGE_IMAGE_WIDTH = 150;
Hubs.LARGE_IMAGE_HEIGHT = 150;

// Click/Tap Event Types
Hubs.CLICK_EVENT    = 'click.Hubs';
Hubs.TAP_EVENT      = 'click.Hubs';
Hubs.SWIPE_UP_EVENT = 'swipeup.Hubs swiperightup.Hubs swipeleftup.Hubs';
Hubs.CLICK_EVENT_CTA_SUBMIT = 'click.HubsCtaSubmit';
Hubs.CHANGE_EVENT_CTA_FIELD  = 'change.HubsCtaField';
Hubs.CLICK_EVENT_CTA_FIELD  = 'click.HubsCtaField';
Hubs.CLICK_EVENT_CTA_DISMISS = 'click.HubsCtaDismiss';
Hubs.TOUCH_START_EVENT_CTA_FIELD = 'touchstart.HubsCtaField';
Hubs.TOUCH_END_EVENT_CTA_FIELD = 'touchend.HubsCtaField';
Hubs.TOUCH_MOVE_EVENT_CTA_FIELD = 'touchmove.HubsCtaField';
Hubs.CLICK_EVENT_CTA_CANCEL = 'click.HubsCtaCancel';
Hubs.FOCUS_EVENT            = 'focus.Hubs';

// Search Hubs
Hubs.SEARCH_MAX_RECENT        = 5;
Hubs.SEARCH_DISPLAY_INITIAL   = 5;
Hubs.SEARCH_DISPLAY_INTERVAL  = 20;
Hubs.SEARCH_MOBILE_BREAKPOINT = 860;

// LazyLoader Directions
Hubs.LAZYLOAD_VERTICAL   = 'vertical';
Hubs.LAZYLOAD_HORIZONTAL = 'horizontal';

// Cookie for GDPR privacy preferences
Hubs.PRIVACY_PREFS_COOKIE_NAME = 'uf_privacy_prefs';

// States for GDPR privacy banner
Hubs.SHOW_NO_BANNER = 0;
Hubs.SHOW_PRIMARY_BANNER = 1;
Hubs.SHOW_SECONDARY_BANNER = 2;

Hubs.CTA_LOCALSTORAGE_KEY =  'uf_cta_query_params';

// Debug Statements
Hubs.DEBUG_MSG = {
    'app-init'                  : 'Hubs:App Initializing',
    'app-init-end'              : 'Hubs:App Complete & Ready',
    'app-post-init'             : 'Hubs:App Post-Initializing',
    'app-post-init-end'         : 'Hubs:App Post-Complete & Ready',
    'app-resize'                : 'Hubs:App Event - Resize',
    'app-resize-end'            : 'Hubs:App Event Complete - Resize',
    'app-reload'                : 'Hubs:App Reloading',
    'app-reload-end'            : 'Hubs:App Reloading Complete',

    'parse-collections'         : 'Hubs:App Parsing Collections into Groups',
    'parse-collections-end'     : 'Hubs:App Parsing Collections Completed',

    'events-init'               : 'Hubs:App Initializing Events',
    'doc-events-init'           : 'Hubs:App Initializing Flipbook-Document Container Events',
    'doc-toggle-fullscreen'     : 'Hubs:App Toggling Fullscreen Flipbook: {{fullscreen}}',

    'nav-desktop-init'          : 'Hubs:App Initializing Desktop Navigation',
    'nav-mobile-init'           : 'Hubs:App Initializing Mobile Navigation',

    'links-override-init'       : 'Hubs:App Overriding Page Links',
    'internal-link-click'       : 'Hubs:App Event - Internal Link Click',
    'external-share-link-click' : 'Hubs:App Event - External Share-Link Click',
    'cancel-loading'            : 'Hubs:App Cancelling Last Request',

    'build-common'              : 'Hubs:App Building Common Elements',
    'build-common-end'          : 'Hubs:App Completed Common Elements',
    'build-page'                : 'Hubs:App Building Page Elements',
    'build-page-end'            : 'Hubs:App Completed Page Elements',
    'build-menu'                : 'Hubs:App Building Menu Elements',
    'build-menu-end'            : 'Hubs:App Completed Menu Elements',
    'build-sharing'             : 'Hubs:App Building Main Sharing Links',
    'build-sharing-end'         : 'Hubs:App Completed Main Sharing Links',
    'build-featured'            : 'Hubs:App Building Featured Tiles',
    'build-featured-end'        : 'Hubs:App Completed Featured Tiles',
    'build-collections'         : 'Hubs:App Building Collection Tiles',
    'build-collections-end'     : 'Hubs:App Completed Collection Tiles',
    'build-items'               : 'Hubs:App Building Items Tiles',
    'build-items-end'           : 'Hubs:App Completed Item Tiles',
    'build-content'             : 'Hubs:App Building Content',
    'build-content-end'         : 'Hubs:App Completed Content',
    'build-breadcrumbs'         : 'Hubs:App Building BreadCrumbs',
    'build-breadcrumbs-end'     : 'Hubs:App Completed BreadCrumbs',
    'build-related-items'       : 'Hubs:App Building Related Items',
    'build-related-items-end'   : 'Hubs:App Completed Related Items',

    'history-state-change'      : 'Hubs:App Event - History State Change',
    'page-change'               : 'Hubs:App Changing Page Content via Ajax',
    'page-change-by-redirect'   : 'Hubs:App Changing Page by Redirect',
    'page-open-modal'           : 'Hubs:App Open Popup Modal with Content via Ajax',
    'page-update-specific'      : 'Hubs:App Updating Page-Type Specific Elements',
    'internal-page-change-request' : 'Hubs:App Request Internal Page Change',

    'lazy-loader-init'          : 'Hubs:LazyLoader Initializing',
    'lazy-loader-init-end'      : 'Hubs:LazyLoader Complete & Ready',
    'lazy-loader-events-init'   : 'Hubs:LazyLoader Initializing Events',
    'lazy-loader-update'        : 'Hubs:LazyLoader Updating Component',
    'lazy-loader-resize'        : 'Hubs:LazyLoader Event - Resize',
    'lazy-loader-scroll'        : 'Hubs:LazyLoader Event - Scroll',
    'lazy-loader-destroy'       : 'Hubs:LazyLoader Destroying',

    'responsive-resize'         : 'Hubs:Responsive Resizing Image Tiles',
    'responsive-text'           : 'Hubs:Responsive Resizing Text',
    'responsive-header'         : 'Hubs:Responsive Initializing Large Header',
    'responsive-share'          : 'Hubs:Responsive Respositioning Level 3 Share Menu',
    'responsive-small'          : 'Hubs:Responsive Initializing Small Screen Devices',
    'responsive-right-sidebar'  : 'Hubs:Responsive Repositioning Right Sidebar',
    'responsive-resize-header'  : 'Hubs:Responsive Resizing Header Image',

    'search-init'               : 'Hubs:Search Initializing',
    'search-events-init'        : 'Hubs:Search Initializing Events',
    'search-init-end'           : 'Hubs:Search Complete & Ready',
    'search-resize'             : 'Hubs:Search Event - Resize',
    'search-scroll'             : 'Hubs:Search Event - Scroll',
    'search-open'               : 'Hubs:Search Opening Search Input',
    'search-close'              : 'Hubs:Search Closing Search Input/Results',
    'search-open-dropdown'      : 'Hubs:Search Opening Drop-Down Menu',
    'search-close-dropdown'     : 'Hubs:Search Closing Drop-Down Menu',
    'search-hide-dropdown'      : 'Hubs:Search Hiding Drop-Down Menu',
    'search-open-results'       : 'Hubs:Search Opening Search Results',
    'search-close-results'      : 'Hubs:Search Closing Search Results',
    'search-update-results'     : 'Hubs:Search Updating Search Results',
    'search-update-recent'      : 'Hubs:Search Updating Recent Searches',
    'search-update-recommended' : 'Hubs:Search Updating Recommended Searches',
    'search-update-breadcrumbs' : 'Hubs:Search Updating Breadcrumbs',
    'search-initiate'           : 'Hubs:Search Initiating Search',
    'search-perform-search'     : 'Hubs:Search Performing Search {{ac}}',
    'search-toggle-section'     : 'Hubs:Search Toggling Section Display',

    'embedded-init'             : 'Hubs:Embedded Initializing',
    'embedded-update-scroll-data' : 'Hubs:Embedded Updating Scroll Data',
    'embedded-scroll'           : 'Hubs:Embedded Event - Scroll',
    'embedded-scroll-els'       : 'Hubs:Embedded Scrolling Individual Elements',

    'tracking-page'             : 'Hubs:Tracker Page Tracked: {{page}}, GA Code: {{code}}',
    'tracking-event'            : 'Hubs:Tracker Event Tracked: {{event}}, GA Code {{code}}',
    'tracking-post'             : 'Hubs:Tracker Post Tracking Records to Server via Ajax',

    // Error Statements
    'app-invalid-container'     : 'Hubs:App A valid container element must be supplied for Hubs:App',

    'ajax-failed'               : 'Failed to Fetch Ajax. Reason: {{reason}}',
    'ajax-failed-parse'         : 'Shared.fetchAjax Unable to Parse JSON. Reason: {{reason}}',
    'ajax-failed-receive'       : 'Shared.fetchAjax Failed to receive ajax result from server. Reason: {{reason}}',
};

export default Hubs;
