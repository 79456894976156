import { getDisplayProperty, isCapturedInSiteEngager } from './utils/displayProperties';
import 'jquery-migrate';
import $ from 'jquery';

const getLinkCtaTrackingRules = (ctaId, ctaTile) => {
  const $el = $(ctaTile);

  return {
    ctaId,
    display: getDisplayProperty($el),
    isCapturedInSiteEngager: isCapturedInSiteEngager($el),
    isSide: $el.parents('#item-content').hasClass('with-cta'),
    ordinal: Array.from($el[0].parentElement.children).indexOf($el[0]),
  };
};

export default getLinkCtaTrackingRules;
