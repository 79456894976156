import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';

/**
 * Hubs Search
 * 
 * @class Search
 * @classdesc Application Search
 * @namespace Hubs
 * @constructor
 */
Hubs.Search = function(app) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;
    var initEvents    = null;
    
    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */

    /** @member {Object} app A reference to the Application Object */
    this.app = app;

    /** @member {Object} $topNav A jQuery Element Handle to the Top Nav Menu  */
    this.$topNav       = null;
    
    /** @member {Object} $common A Collection of jQuery Element Handles Common Search Elements */
    this.$common = {
        'overlay'         : null,       // {Element} The Overlay Element which holds the Search Results and Mobile Drop-Menu Data (Recent)
        'backdrop'        : null,       // {Element} The Backdrop Element for Setting the Background/Opacity of Search
        'toggleContainer' : null,       // {Element} The Container Element holding the Search Toggle Button within the Top Nav
        'toggle'          : null,       // {Element} The Search Toggle Button within the Top Nav
        'breadcrumbs'     : null,       // {Element} The Breadcrumbs Container
        'results'         : null        // {Element} The Search Results Container
    };
    
    /** @member {Object} $desktop A Collection of jQuery Element Handles Desktop-Specific Search Elements */
    this.$desktop = {
        'menu'      : null,             // {Element} The Drop-Down-Menu available only for Desktop (Recent)
        'input'     : null,             // {Element} The Input Element only visible for Desktop
        'close'     : null              // {Element} The Close Button only visible for Desktop
    };
    
    /** @member {Object} $mobile A Collection of jQuery Element Handles Mobile-Specific Search Elements */
    this.$mobile = {
        'header'    : null,             // {Element} The Pseudo-Search-Header Element for Mobile 
                                        //    - This is to prevent having a Focusable Element [like <input>] within a Fixed-Position container as iOS goes wonky.
                                        //      Solution is to have an Absolute-Position container above all, with a Header Section containing Focusable Elements.
        'input'     : null,             // {Element} The Input Element only visible for Mobile (within Pseudo-Search-Header)
        'close'     : null              // {Element} The Close Button only visible for Mobile (within Pseudo-Search-Header)
    };
    
    /** @member {Object} searchState A Collection of State-Flags for keeping track of the Various States of Search */
    this.searchState = {
        'mobileDisplay' : false,        // {Boolean} True if the Display should be rendered for Mobile Devices
        'searchOpen'    : false,        // {Boolean} True if the Search Input is Currently Opened
        'resultsOpen'   : false,        // {Boolean} True if the Search Results Container is Currently Opened
        'dropMenuOpen'  : false,        // {Boolean} True if the Search DropDown is Currently Opened
        'hasResults'    : false,        // {Boolean} True if the Search Results Container has actual Search Results
        'searchStream'  : false         // {Boolean} A flag for Searching within a Stream
                                        //   - False when Searching from the Home Page of the Hub
                                        //   - True when Searching from a Stream or Item Page of the Hub, unless the User clicks the "Home" link
                                        //     within the Search Result Breadcrumbs requesting to Search the entire Hub
    };
    
    /** @member {Object} searchData A Collection of Search Data related to the Current Search Action */
    this.searchData = {
        'query'         : '',           // {String} The Current Search Query as entered by the User
        'ajaxUUID'      : null,         // {Number} A unique ID for the AJAX calls so that they can be cancelled
        'dropMenuTimer' : 0,            // {Number} A timer ID for the delayed Drop Down Menu
        'results'       : null,         // {Object} A JSON object of the Results from the Search Query
        'recent'        : []             // {Array} A Cached List of Recent Searches as performed by the User
                                            //   - This is held in Local Storage or Cookies and Updated after each successful Search Query
    };
    
    
    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    
    /**
     * Initialize the Search Tool
     * 
     * @private 
     * @this Hubs.Search
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Check if Search is Enabled
        if (!Hubs.Config.search.enabled) { return; }
        
        // Debug Statement
        Shared.Logger.log('search-init');
        
        // Store Reference to Hubs.App
        this.app = app;
        
        // Check the Display State of the Device
        this.updateDisplayState();
        
        // Build Required Elements
        buildElements();
        
        // Initialize Events
        initEvents();
        
        // Get Recent Searches from Storage
        this.getRecentSearches();
        
        // Debug Statement
        Shared.Logger.log('search-init-end');
    }, this);
    
    
    /**
     * Builds and/or gets Handles to the Elements for use with Search
     *   - Search Input, Drop-Down, Overlays, Results, Breadcrumbs 
     * 
     * @private 
     * @this Hubs.Search
     * @return undefined
     */
    buildElements = $.proxy(function() {
        var $breadcrumbsContainer = null;
        var $resultsContainer = null;
        
        // Get Handle to Top Nav Element
        this.$topNav = $(Hubs.Config.containers.topNav);
        
        // Get Handle to Search Toggle Container
        this.$common.toggleContainer = $('.search-container');
        this.$common.toggle = this.$common.toggleContainer.find('.search-toggle');
        
        // Build Search Backdrop
        this.$common.backdrop = $('<div/>')
            .addClass('search-results-backdrop')
            .appendTo(Shared.$('body'));
        
        // Build Search Overlay
        this.$common.overlay = $('<div/>')
            .addClass('search-results-overlay')
            .appendTo(Shared.$('body'));
        
        // Build Search Results Breadcrumbs
        $breadcrumbsContainer = $('<div/>').addClass('breadcrumbs-container page-width').appendTo(this.$common.overlay);
        this.$common.breadcrumbs = $('<div/>').addClass('breadcrumbs page-aligner').appendTo($breadcrumbsContainer);
        
        // Build Search Results Container
        $resultsContainer = $('<div/>').addClass('overlay-scroller page-width').appendTo(this.$common.overlay);
        this.$common.results = $('<div/>').addClass('results page-aligner').appendTo($resultsContainer);
        
        // Desktop Specific Elements
        this.$desktop.input = this.$common.toggleContainer.find('.search-input input');
        this.$desktop.close = this.$common.toggleContainer.find('.search-close span');
        this.$desktop.menu = $('.search-drop-down');

        // Mobile Specific Elements
        this.$mobile.header = $('.mobile-search-header').prependTo(this.$common.overlay);
        this.$mobile.input = this.$mobile.header.find('.search-input input');
        this.$mobile.close = this.$mobile.header.find('.search-close span');
    }, this);
    
    
    /**
     * Initialize Events for the Hubs Search Tools
     *  
     * @private 
     * @this Hubs.Search
     * @return undefined
     */
    initEvents = $.proxy(function() {
        // Click Event Handler for Search Toggle Button
        var searchOpen = $.proxy(function(e) {
            e.stopPropagation();
            this.openSearch();
            return false;
        }, this);
        
        // Click Event Handler for Close Button beside Search Input
        var searchClose = $.proxy(function(e) {
            e.stopPropagation();
            this.closeSearch();
            return false;
        }, this);
        
        var searchHover = $.proxy(function(e) {
            if (e.type === 'mouseenter') {
                this.$common.toggle.addClass('hover');
            } else {
                this.$common.toggle.removeClass('hover');
            }
        }, this);
        
        // Focus Event Handler for Search Input
        var inputFocus = $.proxy(function(e) {
            e.stopPropagation();
            e.preventDefault();

            // Only Open DropDown if not already Visible
            if (!this.searchState.dropMenuOpen && (!this.searchData.query.length)) {
                this.openDropDown();
            }
        }, this);
        
        // KeyUp Event Handler for Search Input
        var inputKeyup = $.proxy(function(e) {
            var key = e.which;
            var $inputEl = $(e.currentTarget);
            
            // Store Current Search Query
            this.searchData.query = $inputEl.val().trim();
            
            // Toggle Display of Drop-Down Menu
            if (!this.searchData.query.length) {
                this.openDropDown();
            } else {
                this.closeDropDown();
            }
            
            // Close Search on Escape Key
            if (key === Shared.KB_ESCAPE) {
                this.closeSearch();
            }
            
            // Perform Search on Enter Key
            if (key === Shared.KB_ENTER && this.searchData.query.length) {
                //$inputEl[0].blur();
                this.openResults();
            }
        }, this);

        // Click Event Handler for Closing Search
        var docClick = $.proxy(function(e) {
            // srcElement is an IE thing, will be undefined in most browsers
            var srcEl = $(e.target || e.srcElement);
            var classList = srcEl.attr('class');
            if (classList === undefined) { return true; }

            // For Non-Mobile Devices
            if (!this.searchState.mobileDisplay) {
                // Dont Close if the Element Clicked is NOT the actual Overlay Scroller DIV
                if (!/page-aligner|page-width/i.test(classList)) {
                    return true;
                }
            }
            
            // For Mobile Devices
            else {
                // Dont Close if the Element Clicked is NOT the actual Overlay Scroller DIV
                if (!/search-results-overlay|overlay-scroller/i.test(classList)) {
                    return true;
                }
            }
            
            // Close Search
            this.closeSearch();
        }, this);
        
        // Debug Statement
        Shared.Logger.log('search-events-init');

        // Hook the Click Event on the Search Toggle Button
        this.$common.toggle.on(Hubs.CLICK_EVENT + '.Search', searchOpen);
        this.$common.toggle.on('mouseenter.Search mouseleave.Search', searchHover);
        
        // Hook the Click Event on the Search Toggle Close Button
        this.$desktop.close.on(Hubs.CLICK_EVENT + '.Search', searchClose);
        this.$mobile.close.on(Hubs.CLICK_EVENT + '.Search', searchClose);
        
        // Display Drop-Down Menu on Input Focus
        //   When Search Button is clicked, we programmatically call Focus on the input, bringing us here
        this.$desktop.input.on('focus.Hubs.Search', inputFocus);
        this.$mobile.input.on('focus.Hubs.Search', inputFocus);
        
        // Hook Key-Press Event of Search Input
        this.$desktop.input.on('debouncedkeyup.Hubs.Search', inputKeyup);
        this.$mobile.input.on('debouncedkeyup.Hubs.Search', inputKeyup);

        this.$common.toggle.find('.search-icon').on('click', $.proxy(function(e) {
            var $expandedContainer = $(e.target).parents('.expanded');
            var $searchInput = $expandedContainer.find('.search-input>input');
            var searchValue = $searchInput.val();

            var isSearchExpanded = $expandedContainer.length;
            var isSearchEmpty = searchValue ? searchValue.trim().length === 0 : true; 

            // prevent clicking the button from closing the search input when its open
            // but don't prevent it from opening if its closed
            if (isSearchExpanded) {
                e.stopPropagation();
            }

            // if the search input is open, and there is a search, do the search
            if (isSearchExpanded && !isSearchEmpty) {
                this.openResults();
            }
        },this))
        
        // Close Search Results when Overlay Clicked and Results are Empty
        Shared.$('document').on(Hubs.CLICK_EVENT + '.Search', docClick);
    }, this);
    

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();
    
    return this;
};
// End of Hubs.Search


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

    
/**
 * Responds to the Resize Events fired on the App Object
 * 
 * @public
 * @this Hubs.Search
 * @param {Object} eventData The Event Data associated with the Event from the App
 * @return undefined
 */
Hubs.Search.prototype.resize = function(eventData) {
    var previousMode = this.searchState.mobileDisplay;
    
    // Update Elements after Resize Event
    var updateElements = $.proxy(function() {
        // Check the Display State of the Device
        this.updateDisplayState();
        
        // Update Size of Overlay to Match Viewport
        this.updateOverlaySize();
        
        // Update Position of Drop Down
        if (this.searchState.dropMenuOpen) {
            this.updateDropMenuPosition(10);
        }
        
        // Update Position of Results for Embedded Hubs
        Shared.onNextEventLoop(function() {
            this.updateResultsPosition();
            this.updateResultsSize();
        }, this, 100);
    }, this);
    
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-resize');
    
    // Check if Mobile Mode has Changed
    if (this.useMobileDisplay() !== previousMode) {
        this.closeSearch().then(updateElements);
    } else {
        updateElements();
    }
};

/**
 * Responds to the Scroll Events fired on the App Object
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.scroll = function(scrollTop, offsetTop, viewportX, viewportY) {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled || this.searchState.mobileDisplay) { return; }
    
    // Debug Statement
    //Shared.Logger.log('search-scroll');
    
    // Close DropDown when Permenent Header is Enabled
    if (Hubs.Config.labOptions.permHeader && !Hubs.Config.labOptions.navAlwaysTop) {
        this.closeDropDown();
    }
};

/* ******************************************************************************************** */

/**
 * Opens the Search Query Input Field when a User clicks on the Search Icon
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.openSearch = function() {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled || this.searchState.searchOpen) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-open');
    
    // Track State of Search
    this.searchState.searchOpen = true;

    // For Non-Mobile Displays
    if (!this.searchState.mobileDisplay) {
        // Disable Top Nav (if exists)
        this.$topNav.addClass('disabled');
        
        // Expand the Search Input
        this.$common.toggle.addClass('expanded');
        
        // Focus on Input Element
        this.$desktop.input[0].focus();
    }
    
    // For Mobile Displays
    else {
        // Freeze Body Scrolling
        Hubs.restrictBodyScroll(this.app.viewportData.scrollTop);   // Pass in Embedded scrollTop in case we are Embedded
        
        // If Overlay is not Visible, Display the Overlay
        this.displayResultsContainer();
        
        // Focus on Input Element
        if (!Shared.IOS) {
            this.$mobile.input[0].focus();
        }
    }
};

/**
 * Closes the Search Query Input Field, as well as any Results and Drop-Downs displayed
 * 
 * @public
 * @this Hubs.Search
 * @return {Object} A Promise
 */
Hubs.Search.prototype.closeSearch = function() {
    var deferred = Q.defer();
    
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled || !this.searchState.searchOpen) { 
        deferred.resolve('closed');
        return deferred.promise; 
    }
    
    // Debug Statement
    Shared.Logger.log('search-close');
    
    // Kill DropDown Display Timer
    if (this.searchData.dropMenuTimer) {
        Hubs.root.clearTimeout(this.searchData.dropMenuTimer);
    }

    // For Non-Mobile Displays
    if (!this.searchState.mobileDisplay) {
        // Restore Top Nav (if exists)
        this.$topNav.removeClass('disabled');
        
        // Collapse the Search Input
        this.$common.toggle.removeClass('expanded');
        
        // Clear Search Input
        this.$desktop.input.val('');
    }
    
    // For Mobile Displays
    else {
        // Hide the Overlay
        this.hideResultsContainer();
        
        // Clear Search Input
        this.$mobile.input.val('');
        
        // Restore Body Scrolling
        Hubs.restoreBodyScroll();
    }
    
    // Clear Search Query
    this.searchData.query = '';
    
    // Reset Search Scope
    this.searchState.searchStream = true; 
    
    // Delay Closing to prevent race condition with open timer
    Shared.onNextEventLoop(function() {
        // Close Drop Down (if open)
        this.closeDropDown();
        
        // Close Results
        this.closeResults();
        
        // Track State of Search
        this.searchState.searchOpen = false;
        
        // Resove Promise
        deferred.resolve('closed');
    }, this, 100);
    
    return deferred.promise;
};

/**
 * Opens the Search Drop-Down and Populates it with Recent Searches
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.openDropDown = function() {
    var displayOpacity = 1;
    if (!Hubs.Config.search.enabled || !this.searchState.searchOpen) { return; }
    
    // For Non-Mobile Displays
    if (!this.searchState.mobileDisplay) {
        // Empty Scroller Element
        this.$desktop.menu.find('.overlay-scroller').empty();
        
        // Populate the Drop-Down Menu
        this.populateDropDown().then($.proxy(function() {
            // Display Drop Down if it has Content
            if (this.$desktop.menu.find('.overlay-scroller .search-result-section').length && this.$desktop.menu.css('display') === 'none') {
                // Debug Statement
                Shared.Logger.log('search-open-dropdown');
                    
                // Update Position of Drop Down
                //   - Note: 200ms = CSS Transition Timer
                this.updateDropMenuPosition(250).then($.proxy(function() {
                    // Show Drop Down Menu
                    this.$desktop.menu.css({'display': 'block'});
                    Shared.animate(this.$desktop.menu.stop(), {'opacity': displayOpacity}, 200);
                    
                    // Track State of Search
                    this.searchState.dropMenuOpen = true;
                }, this));
            }
        }, this));
    }

    // For Mobile Displays
    else {
        if (this.searchState.resultsOpen && this.searchState.hasResults) { return; }
        
        // Determine Display Opacity
        displayOpacity = 0.9;
        
        // Empty out Results Section
        this.$common.results.empty();
        
        // Hide Breadcrumbs and Mark as Search-Menu for Styling
        this.$common.overlay.addClass('no-breadcrumbs as-search-menu');

        // Populate the Drop-Down Menu
        this.populateDropDown().then($.proxy(function() {
            // Track State of Search
            this.searchState.dropMenuOpen = true;
        }, this));
    }
};

/**
 * Closes the Search Drop-Down
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.closeDropDown = function() {
    if (!Hubs.Config.search.enabled || !this.searchState.dropMenuOpen) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-close-dropdown');
    
    // For Non-Mobile Displays
    if (!this.searchState.mobileDisplay) {
        // Hide Drop Down
        Shared.animate(this.$desktop.menu.stop(), {'opacity': 0}, 200, $.proxy(function() {
            this.$desktop.menu.css({'display': 'none'}).find('.overlay-scroller').empty();
            
            // Track State of Search
            this.searchState.dropMenuOpen = false;
        }, this));
    }

    // For Mobile Displays
    else {
        // Remove Search Menu Marking
        this.$common.overlay.removeClass('as-search-menu');
        
        // Empty out Results Section
        this.$common.results.empty();
        
        // Track State of Search
        this.searchState.dropMenuOpen = false;
    }
};

/**
 * Temporarily Hides the Search Drop-Down
 *   - this is only for desktop displays, not for mobile
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.hideDropDown = function() {
    if (!Hubs.Config.search.enabled || this.searchState.mobileDisplay || this.$desktop.menu.css('display') !== 'block') { return; }
    
    // Debug Statement
    Shared.Logger.log('search-hide-dropdown');
    
    // If the Menu is Still technically open, try to Reveal it after the Share Menu closes
    var attemptReveal = $.proxy(function() {
        if (this.$desktop.menu.css('display') !== 'block') { return; }
        
        // If Search was Closed while DropDown was Hidden, Close it
        if (!this.searchState.searchOpen) {
            this.closeDropDown();
        } else {
            // If Share Menu is no longer open, reveal the DropDown Menu
            if (!$('.share-hub, .share-item').is(':visible')) {
                Shared.animate(this.$desktop.menu.stop(), {'opacity': 1}, 200);
            } else {
                Hubs.root.setTimeout(attemptReveal, 300);
            }
        }
    }, this);
    
    // Hide Drop Down
    Shared.animate(this.$desktop.menu.stop(), {'opacity': 0}, 200, $.proxy(function() {
        Hubs.root.setTimeout(attemptReveal, 500);
    }, this));
};

/**
 * Open the Search Result Overlays and Initiate Search
 *   Only Called when the User presses "Enter" on the Search Field or Clicks a Recent Search
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.openResults = function() {
    var resultsTop = 0;
    
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Track State of Search
    this.searchState.resultsOpen = true;
    
    // Close Drop-Down Menu
    this.closeDropDown();
    
    // Save Search Query for "Recent Searches"
    this.addRecentSearch();
    
    // Freeze Body Scrolling
    Hubs.restrictBodyScroll(this.app.viewportData.scrollTop);   // Pass in Embedded scrollTop in case we are Embedded

    // If Overlay is not Visible, Display the Overlay
    this.displayResultsContainer();
    
    // Initiate the Search and Update Elements
    this.initiateSearch();
    
    // Chrome on iOS doesn't update element size/positions
    //   causing some elements to appear as Ghosts that can't be
    //   interacted with.  So we force a fake screen scroll to
    //   trigger a re-calc of the layout.
    if (Shared.CriOS) {
        Shared.onNextEventLoop(function() {
            Shared.scrollTop(Shared.scrollTop() + 1);
            Shared.scrollTop(Shared.scrollTop() - 1);
        }, this);
    }
};

/**
 * Closes the Search Result Overlays
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.closeResults = function() {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-close-results');
    
    // Hide Results Container
    this.hideResultsContainer();
    
    // Empty out Results Section
    this.$common.breadcrumbs.empty();
    this.$common.results.empty();
    
    // Restore Body Scrolling
    Hubs.restoreBodyScroll();
    
    // Track State of Search
    this.searchState.resultsOpen = false;
};

/* ******************************************************************************************** */

/**
 * Populates the Search Drop-Down
 *   If the Search Query is blank, gets the Recent Searches
 *   If the Search Query is not blank, it will fetch nothing
 *   
 * @public
 * @this Hubs.Search
 * @return {Object} A Promise
 */
Hubs.Search.prototype.populateDropDown = function() {
    var promise = null;
    
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // If there has been no promise created, let's create one, so that we can maintain
    // asynchronous code.  The callee expects a promise returned anyway, so in this
    // scenario we create and immediately resolve a promise for the callee.
    if (promise === null) {
        promise = Q.Promise(function(resolve, reject, notify) {
            resolve(true);
        });
    }
    
    // Handle the Promise locally first to finishing Populating the Drop-Down
    promise.then($.proxy(function resolveSuccess(response) {
        this.updateRecentSearches();
    }, this));

    // Return the Promise to the callee
    return promise;
};

/**
 * Initiates a Search for the Search Query
 *   Updates Breadcrumbs and Seach Results
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.initiateSearch = function() {
    // After Search, Remove Loading State and Update Search Results
    var afterSearch = $.proxy(function(response) {
        // Set Loading State
        this.setLoadingState(this.$common.backdrop, false);
        
        // Update Search Result Listings
        this.updateSearchResults(response);
    }, this);
    
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-initiate');
    
    // Empty Results Container
    this.$common.results.empty();
    
    // Blur Input Element to Hide Virtual Keyboard
    if (this.searchState.mobileDisplay) {
        this.$mobile.input[0].blur();
    }
    
    // Set Loading State
    this.setLoadingState(this.$common.backdrop, true);
    
    // Update Breadcrumbs for Current Hub Section
    this.updateBreadcrumbs();

    // Perform Search
    this.performSearch().then(
        $.proxy(function searchSuccess(response) {
            afterSearch(response.response.searchResults.results);
        }, this),

        $.proxy(function searchFailure(reason) {
            reason = $.parseJSON(reason);
            Shared.Logger.log({'msg': 'ajax-failed', 'args': {'reason': reason.meta.error.generic[0]}, 'type': Shared.LVL_ERROR});
            afterSearch(reason);
        }, this)
    );
};

/**
 * Perform Search via AJAX
 * 
 * @public
 * @this Hubs.Search
 * @return {Object} A Promise
 */
Hubs.Search.prototype.performSearch = function() {
    var deferred = Q.defer();
    var searchData = null;
    var streamId = '';

    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log({'msg': 'search-perform-search', 'args': {}});
    
    // Searching from a Stream or Item Page (If allowed by Scope)
    if (this.app.pageType !== Hubs.PAGE_TYPE_HUB && this.searchState.searchStream) {
        streamId = this.app.currentCollectionId;
    }
    
    // Get Data for Search
    searchData = {
        'q'   : this.searchData.query, 
        'sid' : streamId
    };
    
    // Cancel any Previous AJAX Requests
    Shared.cancelAjax(this.searchData.ajaxUUID);
    
    // Initiate AJAX Request for Search
    this.searchData.ajaxUUID = Shared.ajaxFetch(Hubs.Config.serverUrl.search, searchData, 'POST', true,
        function successCallback(response) {
            deferred.resolve(response);
        },
        function errorCallback(jqXhr) {
            deferred.reject(jqXhr.responseText);
        }
    );
    
    return deferred.promise;
};

/* ******************************************************************************************** */

/**
 * Displays the Results Overlay and Backdrop
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.displayResultsContainer = function() {
    if (this.$common.overlay.css('display') !== 'none') { return; }
    
    // Debug Statement
    Shared.Logger.log('search-open-results');
    
    // Show Backdrop & Overlay for Search Results
    this.$common.overlay.css({'display': 'block'});
    this.$common.backdrop.css({'display': 'block'});
    Shared.animate(this.$common.overlay.stop(), {'opacity': 1.0}, 150);
    Shared.animate(this.$common.backdrop.stop(), {'opacity': 0.9}, 150);
    
    // Update Size of Overlay to Match Viewport
    this.updateOverlaySize();
    
    // Update Position of Results for Embedded Hubs
    this.updateResultsPosition();
    this.updateResultsSize();
    
    // Mark Body as Having Search Results Open
    Shared.$('body').addClass('search-results-opened');
};

/**
 * Hides the Results Overlay and Backdrop
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.hideResultsContainer = function() {
    if (this.$common.overlay.css('display') === 'none') { return; }
    
    // Hide Backdrop & Overlay Elements
    this.$common.backdrop.css({'display': 'none', 'opacity': 0});
    this.$common.overlay.css({'display': 'none', 'opacity': 0});
    
    // Mark Body as Not Having Search Results Open
    Shared.$('body').removeClass('search-results-opened');
};

/**
 * Repositions the Desktop DropMenu to align with the Search Input
 * 
 * @public
 * @this Hubs.Search
 * @param {Number} delay (Optional) A delay to wait in milliseconds before updating the Position of the DropDown Menu 
 * @return {Object} A Promise
 */
Hubs.Search.prototype.updateDropMenuPosition = function(delay) {
    var deferred = Q.defer();
    var dropMenuTop = 0;
    var dropMenuLeft = 0;
    
    // DropDown Menu is does not need Positioning in Mobile 
    if (this.searchState.mobileDisplay) {
        deferred.resolve(true);
        return deferred.promise;
    }
    
    // Update Position of Drop Down
    Shared.onNextEventLoop(function() {
        // Determine Correct Left Position
        dropMenuLeft = this.$common.toggleContainer.offset().left;
        this.$desktop.menu.css({'left': dropMenuLeft});
        
        // Determine Correct Top Position for Embedded Hub
        if (Hubs.isEmbedded() || Shared.IOS8) {
            dropMenuTop = parseInt(this.$topNav.css('top') || 0, 10) + parseInt(this.$topNav.height() || 0, 10) + 10;
            this.$desktop.menu.css({'top': dropMenuTop});
        }
        
        // Resolve Promise
        deferred.resolve(true);
    }, this, delay || 0);
    
    return deferred.promise;
};

/**
 * Updates the Position of the Search Overlay Element
 *   - Happens when the Overlay is Displayed or the App is resized/reoriented
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateOverlaySize = function() {
    var top, height;
    if (this.$common.overlay.css('display') === 'none') { return; }
    
    if (this.searchState.mobileDisplay) {
        top = Math.max(Shared.scrollTop(), 0 - parseInt(Shared.$('body').css('top'), 10));
        height = Shared.$('window').height();
        this.$common.overlay.css({'position': 'absolute', 'top': top, 'height': height});
        this.$common.backdrop.css({'position': 'absolute', 'top': top, 'height': height});
    } else {
        top = this.$topNav.height();
        this.$common.overlay.css({'position': 'fixed', 'top': top});
        this.$common.backdrop.css({'position': 'fixed', 'top': top});
    }
};

/**
 * Embedded Only: Updates the Position of the Search Results Overlay and Backdrop when the user scrolls on an Embedded Hub
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateResultsPosition = function() {
    if (!Hubs.Config.search.enabled || !Hubs.isEmbedded() || this.$common.overlay.css('display') === 'none') { return; }
    
    // Update Position of results
    Shared.onNextEventLoop(function() {
        var resultsTop = parseInt(this.$topNav.css('top') || 0, 10);
        if (this.searchState.mobileDisplay) {
            // Add top position of the body
            resultsTop += Math.abs(parseInt(Shared.$('body').css('top') || 0, 10));
        }
        else {
            //added topnav height to prevent covering of search bar on desktop embedded hub
            resultsTop += this.$topNav.height();
        }
        this.$common.overlay.css({'top': resultsTop});
        this.$common.backdrop.css({'top': resultsTop});
    }, this);
};

/**
 * Embedded Only: Updates the Size of the Search Results Overlay and Backdrop when the user scrolls on an Embedded Hub
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateResultsSize = function() {
    if (!Hubs.Config.search.enabled || !Hubs.isEmbedded() || this.$common.overlay.css('display') === 'none') { return; }
    
    // Update Position of results
    Shared.onNextEventLoop(function() {
        var resultsHeight = Shared.$('window').height();
        this.$common.overlay.css({'height': resultsHeight});
        this.$common.backdrop.css({'height': resultsHeight});
    }, this);
};

/* ******************************************************************************************** */

/**
 * Update the Breadcrumbs for the Current Search Query
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateBreadcrumbs = function() {
    var $homeLink = null;
    var $streamLink = null;
    var streamLabel = '';
    var isHomeActive = true;

    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-update-breadcrumbs');
    
    // Empty Breadcrumbs
    this.$common.breadcrumbs.empty();
    
    // No Breadcrumbs for Searches Initiated from Hub-Home
    if (this.app.pageType === Hubs.PAGE_TYPE_HUB || this.app.collectionType === 'social' || this.app.pageType === Hubs.PAGE_TYPE_PRIVACY) {
        this.$common.overlay.addClass('no-breadcrumbs');
        return;
    } else {
        this.$common.overlay.removeClass('no-breadcrumbs');
    }
    
    // Build "Home" Link
    $homeLink = $('<a nohref/>').addClass('home').html(Hubs.Config.search.labels.searchAllContent);
    
    // Attach Click Handler
    $homeLink.on(Hubs.CLICK_EVENT + '.Search', $.proxy(function(e) {
        e.stopPropagation();
        if (this.app.pageType !== Hubs.PAGE_TYPE_HUB && this.searchState.searchStream) {
            // Search Entire Hub, not just Current Stream 
            this.toggleStreamSearch(false);
        }
        return false;
    }, this));
    
    // Searching from a Stream or Item Page
    if (this.app.pageType !== Hubs.PAGE_TYPE_HUB) {
        // Get the Label for the Current Stream
        if (this.app.pageType === Hubs.PAGE_TYPE_COLLECTION) {
            streamLabel = $('h1.hub-heading').html();
        }
        if (this.app.pageType === Hubs.PAGE_TYPE_ITEM) {
            streamLabel = $('#hubs-container .bread-crumbs a[data-page-title]').eq(1).attr('data-page-title');
        }
        
        // Build "Stream" Link
        $streamLink = $('<a nohref/>').addClass('stream').html(streamLabel);
        
        // Attach Click Handler
        $streamLink.on(Hubs.CLICK_EVENT + '.Search', $.proxy(function(e) {
            e.stopPropagation();
            if (!this.searchState.searchStream) {
                // Search Current Stream, not Entire Hub 
                this.toggleStreamSearch(true);
            }
            return false;
        }, this));
        
        // Toggle Active Link
        isHomeActive = !this.searchState.searchStream;
    }
    
    // Empty Breadcrumbs and Append Home Link
    this.$common.breadcrumbs.empty().append('<span>' + Hubs.Config.search.labels.searchPlaceholder + ':</span>').append($homeLink);
    
    // Append Current Stream Link
    if (this.app.pageType !== Hubs.PAGE_TYPE_HUB) {
        this.$common.breadcrumbs.append($streamLink);
    }
    
    // Mark Active Link
    if (isHomeActive) {
        // Make Home Link "Active"
        $homeLink.addClass('active');
        if ($streamLink && $streamLink.length) {
            $streamLink.removeClass('active');
        }
    } else {
        // Make Stream Link "Active"
        if ($streamLink && $streamLink.length) {
            $streamLink.addClass('active');
        }
        $homeLink.removeClass('active');
    }
};

/**
 * Update the Search Results List for the Current Search Query
 *   
 * @public
 * @this Hubs.Search
 * @param {Object} results The results data returned from the Search via AJAX 
 * @return undefined
 */
Hubs.Search.prototype.updateSearchResults = function(results) {
    var i, t, totalCount = 0;
    var sectionTypes = ['blogs', 'docs', 'videos'];

    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Debug Statement
    Shared.Logger.log('search-update-results');
    
    // Invalid Results; Parse Error Message
    if (results && results.meta && results.meta.error) {
        // Add "No Items" Class
        this.$common.results.addClass('no-items-found');

        // No Items Found Message
        $('<div/>')
            .addClass('no-items-found')
            .html(results.meta.error.generic[0])
            .appendTo(this.$common.results);
        
        // Track State of Results
        this.searchState.hasResults = false;
    }
    
    // Valid Results
    else {
        if (results === undefined) { results = {}; }
        for (i = 0; i < sectionTypes.length; i++) {
            if (results[sectionTypes[i]] === undefined) { results[sectionTypes[i]] = {'total': 0}; }
        }
        
        // Store Results
        this.searchData.results = $.extend(true, {}, results);
        
        // Calculate Total Results
        for (i = 0; i < sectionTypes.length; i++) {
            totalCount += results[sectionTypes[i]].total;
            
            // We only want to display the first few results, so truncate the array items
            //  Full Original Results stored in this.searchData.results
            if (results[sectionTypes[i]].items !== undefined) {
                results[sectionTypes[i]].items.splice(Math.min(results[sectionTypes[i]].items.length, Hubs.SEARCH_DISPLAY_INITIAL), Number.MAX_VALUE);
            }
        }
        
        if (totalCount > 0) {
            // Remove "No Items" Class
            this.$common.results.removeClass('no-items-found');
            
            // Populate Results List
            for (i = 0; i < sectionTypes.length; i++) {
                this.addResultsList(sectionTypes[i],  Hubs.Config.search.labels[sectionTypes[i]] + ' (' + results[sectionTypes[i]].total + ')',   results);
            }
            
            // Track State of Results
            this.searchState.hasResults = true;
        } else {
            // Add "No Items" Class
            this.$common.results.addClass('no-items-found');
            
            // Searching from a Stream or Item Page (If allowed by Scope)
            if (this.app.pageType !== Hubs.PAGE_TYPE_HUB && this.searchState.searchStream && this.app.collectionType !== 'social') {
                // No Items Found in Stream Message
                $('<div/>')
                    .addClass('no-items-found')
                    .html(Hubs.Config.search.labels.noItemsStream)
                    .appendTo(this.$common.results);
                
                // Button: Search Entire Hub
                $('<a nohref/>')
                    .addClass('search-entire-hub')
                    .html('<span></span>' + Hubs.Config.search.labels.searchEntireHub)
                    .appendTo(this.$common.results)
                    .on(Hubs.CLICK_EVENT + '.Search', $.proxy(function(e) {
                        e.stopPropagation();
                        // Search Entire Hub, not just Current Stream 
                        this.toggleStreamSearch(false);
                        return false;
                    }, this));
            } else {
                // No Items Found Message
                $('<div/>')
                    .addClass('no-items-found')
                    .html(Hubs.Config.search.labels.noItems)
                    .appendTo(this.$common.results);
            }
            
            // Track State of Results
            this.searchState.hasResults = false;
        }
    }
};

/**
 * Fetches More Results for a Category (Blogs, Docs, Videos) when the "See More" button is clicked from the Search Results
 *   
 * @public
 * @this Hubs.Search
 * @param {Object} $seeMoreLink A jQuery Element Handle to the "See More" Link that was clicked
 * @param {Object} $list A jQuery Element Handle to the List to populate
 * @param {Object} $content A jQuery Element Handle to the Container of the List
 * @param {String} containerType The type of Container (Blogs, Docs, Videos) being Updated
 * @param {Number} nextOffset The offset of the Results to fetch
 * @return undefined
 */
Hubs.Search.prototype.seeMoreResults = function($seeMoreLink, $list, $content, containerType, nextOffset) {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Set Loading State on Link
    $seeMoreLink.addClass('loading');
    
    // Get Copy of Results from Stored Results Object
    var listData = $.extend(true, {}, this.searchData.results);

    // Display More Results
    if (listData[containerType].items.length > nextOffset) {
        // Copy Subset of Results
        listData[containerType].items = listData[containerType].items.slice(nextOffset, nextOffset + Hubs.SEARCH_DISPLAY_INTERVAL);
        listData[containerType].offset = nextOffset;
        
        // Append Results to List Element
        this.appendFormattedResults($list, $content, listData, containerType);
    } 
    
    // No More Results to Display
    else {
        $seeMoreLink.remove();
    }
};

/* ******************************************************************************************** */

/**
 * Adds a Sectional List of Results to the Drop-Down Menu
 *   
 * @public
 * @this Hubs.Search
 * @param {String} containerClass The CSS Classname for the Sectional List
 * @param {String} headerLabel The Header Label for the Sectional List
 * @param {Array} listItems The Items to Populate the List with
 * @return undefined 
 */
Hubs.Search.prototype.addDropMenuList = function(containerClass, headerLabel, listItems) {
    var $container = null;
    var $header = null;
    var $content = null;
    var $list = null;
    var $link = null;
    
    var clickHandler = $.proxy(function(listItem) { 
        return $.proxy(function(e) {
            e.stopPropagation();
            
            // Store Current Search Query
            this.searchData.query = listItem;
            
            // Update Search Input Box
            if (this.searchState.mobileDisplay) {
                this.$mobile.input.val(listItem);
            } else {
                this.$desktop.input.val(listItem);
            }
            
            // Open Search Results and Perform Search
            this.openResults();
            
            return false;
        }, this);
    }, this);

    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Create Recent Searches Section 
    // Section Container
    $container = $('<div/>')
        .addClass('search-result-section')
        .addClass(containerClass);
    
    // Section Header
    if (headerLabel.length) {
        $header = $('<div/>')
            .addClass('section-header')
            .append('<h2>' + headerLabel + '</h2>')
            .appendTo($container);
    }
    
    // Section Content
    $content = $('<div/>')
        .addClass('section-content')
        .appendTo($container);
    
    // List Element
    $list = $('<ul/>')
        .appendTo($container);
    
    // Build List of Recent Searches
    for (var i = 0; i < listItems.length; i++) {
        if (!listItems[i] || !listItems[i].length) { continue; }
        
        // Build Link Element
        $link = $('<a nohref/>')
            .append($('<span/>').addClass('search-icon'))
            .append(Shared.jhe(listItems[i]))
            .on(Hubs.CLICK_EVENT + '.Search', clickHandler(listItems[i]));
        
        // Append Link to List
        $('<li/>').append($link).appendTo($list);
    }
    $content.append($list);
    

    // For Non-Mobile Displays
    if (!this.searchState.mobileDisplay) {
        // Append Content to Drop Down Menu
        this.$desktop.menu.find('.overlay-scroller').append($container);
    }
    
    // For mobile Displays
    else {
        // Append Content to Results Container
        this.$common.results.append($container);
    }
    
    return true;
};

/**
 * Adds a Sectional List of Results to the Results Overlay
 *   
 * @public
 * @this Hubs.Search
 * @param {String} containerType The CSS Classname for the Sectional List
 * @param {String} headerLabel The Header Label for the Sectional List
 * @param {Object} listData The Item-Data to Populate the List with
 * @return undefined 
 */
Hubs.Search.prototype.addResultsList = function(containerType, headerLabel, listData) {
    var $container = null;
    var $header = null;
    var $content = null;
    var $list = null;
    var streamData = listData[containerType];

    if (!Hubs.Config.search.enabled || !streamData.items || !streamData.items.length) { return; }

    // Create Recent Searches Section 
    // Section Container
    $container = $('<div/>')
        .addClass('search-result-section')
        .addClass(containerType + '-items');
    
    // Section Header
    $header = $('<div/>')
        .addClass('section-header')
        .append('<h2>' + headerLabel + '</h2>')
        .appendTo($container);
    
    // Section Content
    $content = $('<div/>')
        .addClass('section-content')
        .appendTo($container);
    
    // List Element
    $list = $('<ul/>')
        .appendTo($container);

    // Append Results to List Element
    this.appendFormattedResults($list, $content, listData, containerType);
    
    // Append Content to Results Container
    this.$common.results.append($container);
    return true;
};

/**
 * Appends Results to a List with Enhanced Formatting for Display
 *   Links are Hooked with Hubs History API to enable Hubs Style Page Changes
 *   
 * @public
 * @this Hubs.Search
 * @param {Object} $list A jQuery Element Handle to the List to populate
 * @param {Object} $content A jQuery Element Handle to the Container of the List
 * @param {Object} listData The Item-Data to Populate the List with
 * @param {String} containerType The type of Container (Blogs, Docs, Videos) being Updated
 * @return undefined 
 */
Hubs.Search.prototype.appendFormattedResults = function($list, $content, listData, containerType) {
    var $seeMoreLink = $list.find('.see-more');
    var streamData = listData[containerType];
    var iconClass = 'color-box-service-icon';
    var i, html = '';

    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Remove any existing "See More" Link (we will generate it again if needed)
    if ($seeMoreLink.length) {
        $seeMoreLink.remove();
    }
    
    // Medium Icons for Mobile
    if (this.searchState.mobileDisplay) {
        iconClass += ' medium';
    }
    
    // Build List of Search Results
    for (i = 0; i < streamData.items.length; i++) {
        if (!streamData.items[i]) { continue; }
        
        // Build List Item HTML
        html  = '<a href="' + streamData.items[i].url + '" data-internal="' + streamData.items[i].service + '">';
        html += '<span class="' + iconClass + ' service-' + streamData.items[i].service + '">';
        html += '<i style="background-image:url(' + streamData.items[i].thumbnail + ')"></i>';
        html += '</span>';
        html += ' ' + streamData.items[i].label + '</a>';
        
        // Append List Item to List
        $('<li/>').html(html).appendTo($list);
    }
    
    // Append "See More" Link (if needed)
    if (streamData.items.length + streamData.offset < streamData.total) {
        $('<li/>')
            .addClass('see-more')
            .html('<a nohref>' + Hubs.Config.search.labels.seeMore + '</a>')
            .appendTo($list)
            .on(Hubs.CLICK_EVENT + '.Search', $.proxy(function(e) {
                var $seeMoreLink = $(e.currentTarget);
                var nextOffset = streamData.items.length + streamData.offset;
                e.stopPropagation();
                this.seeMoreResults($seeMoreLink, $list, $content, containerType, nextOffset);
                return false;
            }, this));
    }
    
    // Append List to Content
    $content.append($list);
    
    // Hook Internal Links
    Shared.onNextEventLoop(function() { 
        this.app.overrideLinks(); 
    }, this);
};

/* ******************************************************************************************** */

/**
 * Determine if Search should render the Display for Mobile or Desktop
 * 
 * @public
 * @this Hubs.Search
 * @return {Boolean} True for Mobile Display rendering
 */
Hubs.Search.prototype.useMobileDisplay = function() {
    // Are We Displaying for Mobile?
    return (Shared.IOS || Shared.$('window').width() <= Hubs.SEARCH_MOBILE_BREAKPOINT);
};

/**
 * Sets the Current Mobile Display Mode, and Marks the App as using Mobile-Search-Mode
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateDisplayState = function() {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }

    // Are We Displaying for Mobile?
    this.searchState.mobileDisplay = this.useMobileDisplay();
    
    // Add/Remove Class to Indicate Search Display State
    Shared.$('body')[this.searchState.mobileDisplay ? 'addClass' : 'removeClass']('mobile-search');
};

/**
 * Toggles the State of the Stream-Search feature.  
 *   If Stream Search is enabled, then the Search will only be performed within the current Stream.
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.toggleStreamSearch = function(toggleState) {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // If state is not set, toggle
    if (toggleState === undefined) {
        this.searchState.searchStream = !this.searchState.searchStream;
    } else {
        this.searchState.searchStream = toggleState;
    }

    // Re-search
    this.initiateSearch();
};

/**
 * Adds a Search Query to the Recent Searches Storage
 *   If the Search Query is already in the List, move to Top of List
 *   Max 5 Recent Searches
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.addRecentSearch = function() {
    var position = -1;
    if (!this.searchData.query || !this.searchData.query.length) { return; }
    
    // Remove Duplicate Queries from Array
    position = $.inArray(this.searchData.query, this.searchData.recent);
    if (position > -1) {
        this.searchData.recent.splice(position, 1);
    }
    
    // Add Most Recent Query to Top of List
    this.searchData.recent.unshift(this.searchData.query);
    
    // Truncate List to Max
    this.searchData.recent.length = Math.min(this.searchData.recent.length, Hubs.SEARCH_MAX_RECENT);
    
    // Store List (LocalStorage or Cookies)
    localStorage.setItem('hubs.rsq', this.searchData.recent.join(','));
};

/**
 * Get the List of Recent Searches from Storage
 * 
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.getRecentSearches = function() {
    var storedSearches = localStorage.getItem('hubs.rsq');
    if (!storedSearches) { return; }
    this.searchData.recent = storedSearches.split(',');
};

/**
 * Sets the Loading State of a Container Element
 * 
 * @public
 * @this Hubs.Search
 * @param {Object} $container A jQuery Element Handle to the Container to be Updated
 * @param {Boolean} state The State of Loading 
 * @return undefined
 */
Hubs.Search.prototype.setLoadingState = function($container, state) {
    // Check if Search is Enabled
    if (!Hubs.Config.search.enabled) { return; }
    
    // Toggle Loading State
    $container[state ? 'addClass' : 'removeClass']('loading');
};

/**
 * Updates the Drop-Down Menu with a List of Recent Searches
 *   
 * @public
 * @this Hubs.Search
 * @return undefined
 */
Hubs.Search.prototype.updateRecentSearches = function() {
    if (!Hubs.Config.search.enabled || !this.searchData.recent || !this.searchData.recent.length) { return false; }
    
    // Debug Statement
    Shared.Logger.log('search-update-recent');
    
    // Add Recent Items to Drop Down Menu
    return this.addDropMenuList('recent-searches', Hubs.Config.search.labels.recent, this.searchData.recent);
};
