const getScrollDepthTrackingRules = () => ({
  scrollDepthTracking: {
    rules: [
      {
        name: 'main-content',
        selector: '.single-page #item-content article',
      },
      {
        name: 'main-hub-content',
        selector: '.hub-page #hubs-container',
      },
      {
        name: 'main-stream-content',
        selector: '.listing-page #hubs-container',
      },
    ],
  },
});

export default getScrollDepthTrackingRules;
