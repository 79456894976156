import Hubs from './core';

Hubs.Config = {
    'serverTimestamp'   : 0,
    'hubId'             : '',
    'hubTitle'          : '',
    'hubBaseUrl'        : '',
    'previewOn'         : 0,

    // Cookies
    'cookies': {
        'entry': 'ufentry'
    },

    // Server URLs for App
    'serverUrl' : {
        'hub'               : '',
        'cdn'               : '',
        'lazyLoader'        : '/hubsFront/ajax_loadAdditionalItems/',
        'extraInfo'         : '/hubsFront/ajax_loadExtraInfo/',
        'search'            : '/hubsFront/ajax_search/',
        'ctaTracking'       : '/hubsFront/ajax_trackCta',
        'ctaViewTracking'   : '/hubsFront/ajax_trackCtaView',
        'ctaSubmit'         : '/hubsFront/ajax_submitCtaForm',
        'ctaActivate'       : '/hubsFront/ajax_activateFormCta',
        'pageViewTracking'  : '/hubsFront/ajax_trackPageView',
        'socialTracking'    : '/hubsFront/ajax_trackSocial',
        'signalMetricsTemp' : '/hubsFront/signalMetricsTemp',
        'updateMAPUsers'    : '/hubsFront/ajax_updateMAPUsers',
        'ping'              : '/hubsFront/ajax_ping',
        'getFormFieldConditions' : '/hubsFront/ajax_getFormFieldConditions'
    },

    // Container Elements
    'containers' : {
        'app'                   : '#hubs-container',
        'levelThree'            : '.level-three',
        'pageWidth'             : '.page-width',
        'topNav'                : '.top-nav',
        'leftNav'               : ['#left-nav', '#left-nav-phone', '.left-nav'],
        'largeHeader'           : '.large-header',
        'mainContent'           : '.main',
        'itemContent'           : '#item-content',
        'loadingOverlay'        : '#loading-overlay',
        'loadingIndicator'      : '.loading-indicator',
        'pageIdentifier'        : '#page-type-identifier',
        'returnToHub'           : '#returnToHub',
        'moveToTop'             : '#moveToTop',
        'flipbookContainer'     : '.single-uberflip',
        'fullscreenControls'    : '.fullscreen-controls',
        'showMoreLink'          : '.display-more',
        'descWrapper'           : '.full-desc-container',
        'fullDesc'              : '.full-desc',
        'videoPlayer'           : '.single-video',
        'shareContainer'        : '.share-container',
        'timeAgo'               : 'abbr.timeago',
        'nonProxiedImg'         : 'span.non-proxied-image',
        'headerLoading'         : '#header-loading-overlay',
        'headerMain'            : '.header-main',
        'splashLoader'          : '#splash-loader',
        'splashChevron'         : '#splash-chevron',
        'addthisToolbar'        : '.addthis_toolbox',
        'addthisCounter'        : '.addthis_counter',
        'addthisSmartlayers'    : '.addthis-smartlayers',
        'footer'                : 'body > footer',
        'mobileFlipbook'        : '.mobile-flipbook.level-two',
        'visibleApp'            : 'header, footer, .main',
        'date'                  : '.date'
    },

    // CTA's
    'cta' : {
        'elements' : {
            'container'          : '.cta',
            'itemCta'            : '.cta-item-container',
            'formContainer'      : '.cta-form',
            'sprite'             : '.cta-sprite',
            'form'               : '.cta-form',
            'formField'          : '.preview-form-field',
            'hideCancel'         : '.hide-preview-cancel',
            'formBottom'         : '.hidden-cta-fields',
            'formTop'            : '.run-away',
            'submitForm'         : '.cta-submit-form',
            'activateForm'       : '.activate-button',
            'fieldName'          : '.cta-field-name',
            'formError'          : '.cta-form-error',
            'formSuccess'        : '.cta-form-success',
            'allFormContent'     : '.full-cta-form-content',
            'submitting'         : '.submission-loader-container',
            'startFullScreen'    : '.cta-form:not(.full-screen-cta) .preview-form-field',
            'noThanks'           : '.no-thanks a',
            'possibleBlock'      : '.possible-block',
            'blockCta'           : '.block-cta',
            'blockingCta'        : '.blocking-cta',
            'itemBlockCta'       : '.embed-block-body',
            'optIn'              : '.opt-in',
            'optInRequired'      : '.opt-in-required',
            'hiddenEmbed'        : '.hide-embed',
            'possibleHiddenEmbed': '.possible-hidden-embed'
        },
        'storage'              : 'flyptech-hub-',
        'hidePlacementNumDays' : 7
    },

    // Carousel Control
    'carousel' : {
        'id'            : '%name%-items-carousel',
        'name'          : '',
        'start'         : 1,
        'elements' : {
            'container' : '.carousel',
            'item'      : '.tile',
            'parent'    : '.%name%-container',
            'inner'     : '.carousel-inner',
            'nav'       : {'container': '.carousel-nav', 'prev': '#%name%NavPrev', 'next': '#%name%NavNext'},
            'loadMore'  : '.load-more'
        },
        'lazyLoad' : {
            'enabled' : false,
            'url'     : '',
            'limit'   : 10,
            'page'    : 1,
            'before'  : 20
        },
        'l10n' : {
            'carousel-init'         : 'Shared:Carousel Initializing',
            'carousel-init-end'     : 'Shared:Carousel Complete & Ready',
            'carousel-events-init'  : 'Shared:Carousel Initializing Events',
            'carousel-update'       : 'Shared:Carousel Updating Container',
            'carousel-update-items' : 'Shared:Carousel Updating Items',
            'carousel-update-nav'   : 'Shared:Carousel Updating Navigation',
            'carousel-scroll-start' : 'Shared:Carousel Scrolling',
            'carousel-scroll-end'   : 'Shared:Carousel Scrolling Stopped',
            'carousel-resize'       : 'Shared:Carousel Event - Resize',
            'carousel-destroy'      : 'Shared:Carousel Destroying'
        },
        'eventNamespace' : '.Hubs.Carousel',

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // Customizable Routines
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        'itemsUpdated'          : function() { return false; }
    },

    // LazyLoader Control
    'lazyloader' : {
        'direction'         : Hubs.LAZYLOAD_VERTICAL,
        'itemDisplayLimit'  : 20,
        'elements' : {
            'container'     : '#collection-items > .collection-items-container',
            'notify'        : '#loading-notifier',
            'section'       : '.collection-section',
            'item'          : '.tile:not(.cta):not(#loading-notifier)',
            'loadMore'      : '#load-more'
        }
    },

    // Search Tool
    'search' : {
        'enabled'              : false,
        'labels' : {
            'home'             : 'Home',
            'videos'           : 'Videos',
            'blogs'            : 'Blogs',
            'docs'             : 'Docs',
            'social'           : 'Social',
            'recent'           : 'Recent Searches',
            'noItems'          : 'No Items Found',
            'noItemsStream'    : 'No Items Found in this Stream',
            'seeMore'          : 'See More',
            'searchEntireHub'  : 'Search the rest of the Hub'
        }
    },

    // Empty Hub State
    'isEmptyHub'            : false,

    // Mobile Devices
    'isMobile'              : false,

    // Are we in an IFrame?
    'isIFrame'              : (Hubs.root.top.location !== Hubs.root.self.location),

    // Are we in an Embedded-IFrame?
    'isEmbedded'            : false,

    // Options from Hubs Labs
    'labOptions' : {
        'topMenu'           : false,
        'permHeader'        : false,
        'noHeader'          : false,
        'hideBanner'        : false,
        'highlightFirst'    : false,
        'stickyFooter'      : false,
        'flipbookBreakOut'  : false,
        'linkBreakOut'      : false
    },

    // Options from Embedded Hub
    'embedOptions' : {
        'hideHeader'        : false,
        'hideBanner'        : false,
        'hideFooter'        : false,
        'hidePrimaryNav'    : false,
        'hideSecondaryNav'  : false,
        'linkBreakOut'      : false,
        'revealBehaviour'   : 'fade'
    },

    // Initial Page Type Displayed
    'pageType'              : Hubs.PAGE_TYPE_HUB,

    // Tracking Delay for sending stats analytics to server in batches
    'trackingDelay'         : 60000,

    // Responsive Breakpoints
    'breakpoints' : {
        'mobile1'  :  480,
        'mobile2'  :  560,
        'tablet1'  :  720,
        'tablet2'  :  796,
        'tablet3'  :  980,
        'desktop1' : 1066,
        'desktop2' : 1336,
        'desktop3' : 1606,
        'desktop4' : 1876,
        'desktop5' : 9999
    },

    // Bombora
    'bombora'    : false,

    // Google Analytics Codes for Event Tracking
    'analyticsCodes'        : [],

    // Page Tracking
    'enablePageTracking'    : true,

    // Debugging
    'enableDebugger'        : false,

    //itemPreview Button Label
    'itemPreviewButtonLabel' : 'Continue Reading...',

    // Hub-specific query strings
    'ufQueryStrings': ['page', 'scrollTop', 'prevCol', 'prevItm', 'ts', 'recotrk', 'visual']
};
