import 'jquery-migrate';
import $ from 'jquery';
import Shared from './shared_util';

/**
 * Shared Logger
 *
 * @class Logger
 * @classdesc Logs Messages to the Console for Debugging
 * @namespace Shared
 * @constructor
 */
Shared.Logger = {};


/* ******************************************************************************************** */
/* * Private Static Members                                                                   * */
/* ******************************************************************************************** */
Shared.Logger.l10n     = {};
Shared.Logger.enabled  = true;


/* ******************************************************************************************** */
/* * Public Static Methods                                                                    * */
/* ******************************************************************************************** */
/**
 * Logs a Message to the Console; Type and Arguments may be specified
 *
 * @public
 * @this Shared.Logger
 * @param {Mixed} options The message to be logged.  Can be a string for direct translation, or an object with 'msg', 'type' and 'args' keys
 * @return {Boolean} Returns False for all Error type logs; True for all others
 */
Shared.Logger.log = function(options) {
    if (!Shared.Logger.enabled) { return; }
    
    // Parse Options into a Readable Message
    options = Shared.Logger.parse(options);
    
    // Output Message
    Shared.Logger.output(options.msg, options.type);

    // Return
    return !/^error$/i.test(options.type);
};

/**
 * Common Function for Outputting to the Logs
 *
 * @public
 * @this Shared.Logger
 * @param {String} msg The message to be logged
 * @param {String} [type] The type of message to log ('debug', 'info', 'error', 'warning')
 * @return undefined
 */
Shared.Logger.output = function(msg, type) {
    if (!Shared.Logger.enabled) { return; }
    console.log(msg);
};

/**
 * Parses a Log Object to get translations and arguments into the Message
 *
 * @public
 * @this Shared.Logger
 * @param {Mixed} options The message to be logged.  Can be a string for direct translation, or an object with 'msg', 'type' and 'args' keys
 * @return {Object} The parsed and translated options
 */
Shared.Logger.parse = function(options) {
    var arg = null;

    if (typeof options !== 'object') {
        options = {'msg': options};
    }
    if (options.msg === undefined) { options.msg = ''; }
    if (options.type === undefined) { options.type = Shared.LVL_DEBUG; }
    if (options.args === undefined) { options.args = null; }

    // Attempt to Convert Message
    if (typeof options.msg === 'string') {
        if (Shared.Logger.l10n[options.msg] !== undefined) {
            options.msg = Shared.Logger.l10n[options.msg];
        }
    }

    // If Type Exists Prepend Type
    if (options.type && options.type.length) {
        options.msg = '[' + options.type + '] ' + options.msg;
    } else {
        options.type = 'none';
    }

    // Replace Args in Message
    if (options.args !== undefined) {
        for (arg in options.args) {
            if (options.args.hasOwnProperty(arg)) {
                options.msg = options.msg.replace('{{' + arg + '}}', options.args[arg]);
            }
        }
    }

    return options;
};

/**
 * Sets the Localization Text for String Conversions
 *
 * @public
 * @this Shared.Logger
 * @param {Object} l10n An object containing key->value associations for text conversions
 * @return undefined
 */
Shared.Logger.setL10N = function(l10n) {
    Shared.Logger.l10n = $.extend(true, {}, Shared.Logger.l10n, l10n);
};
