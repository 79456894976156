import 'jquery-migrate';
import $ from 'jquery';
import Shared from './shared_util';
import IScroll from '/app/libs/iscroll5/iscroll';

/**
 * Shared Carousel
 * 
 * @class Carousel
 * @classdesc Shared Application Carousel
 * @namespace Shared
 * @return {Object} The Class Instance
 * @constructor
 */
Shared.Carousel = function(config) {

    /* **************************************************************************************** */
    /* * Private Members                                                                      * */
    /* **************************************************************************************** */
    var initialize, initEvents, resizeEvent, scrollMove, scrollEnd;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    /** @member {Object} config An object holding all configuration options */
    this.config           = $.extend(true, {}, Shared.Carousel.defaultConfig, config);
    
    /** @member {Object} $carousel A jQuery Element Handle to the Container DIV of the Carousel  */
    this.$carousel        = null;
    
    /** @member {Object} $carouselInner A jQuery Element Handle to the Inner Container DIV of the Carousel  */
    this.$carouselInner   = null;
    
    /** @member {Object} $carouselItem A jQuery Element Handle to the Carousel Items */
    this.$carouselItem    = null;
    
    /** @member {Object} $carouselParent A jQuery Element Handle to the Parent Container DIV of the Carousel  */
    this.$carouselParent  = null;
    
    /** @member {Object} $carouselNav A jQuery Element Handle to the Controls Container DIV of the Carousel  */
    this.$carouselNav     = null;
    
    /** @member {Object} $carouselNavPrev A jQuery Element Handle to the Move "Prev" Control DIV of the Carousel  */
    this.$carouselNavPrev = null;
    
    /** @member {Object} $carouselNavNext A jQuery Element Handle to the Move "Next" Control DIV of the Carousel  */
    this.$carouselNavNext = null;
    
    /** @member {Object} $$carouselLoadMore A jQuery Element Handle to the mobile load more button  */
    this.$carouselLoadMore = null;
    
    /** @member {Object} $carouselScroll A jQuery Component for Touch-based Scrolling  */
    this.$carouselScroll  = null;
    
    /** @member {Number} carouselWidth The Outer Width of the Carousel */
    this.carouselWidth    = 0;
    
    /** @member {Number} itemWidth The Outer Width of each Item in the Carousel */
    this.itemWidth        = 0;
    
    /** @member {Number} currentItem The Current Index of the Featured Item that is visible within the Carousel  */
    this.currentItem      = 1;
    
    /** @member {Number} itemCount The number of Featured Items that are within the Carousel  */
    this.itemCount        = 0;
    
    /** @member {Number} slideState The Sliding State of the Carousel  */
    this.slideState       = 0;

    /** @member {Boolean} containerFade Whether or not the container has a Fade on its edge  */
    this.containerFade    = false;
    
    /** @member {Boolean} isLazyLoading Flag to whether or not a current load is in progress  */
    this.isLazyLoading    = false;
    
    /** @member {Number} movePrevNextStep The Number of Items to Move the Carousel by when clicking Prev/Next buttons */
    this.movePrevNextStep = 1;
    

    /* **************************************************************************************** */
    /* * Private Methods                                                                      * */
    /* **************************************************************************************** */
    /**
     * Initialize the Header
     *     -
     *
     * @private
     * @this Shared.Carousel
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Add Localization to Logger
        Shared.Logger.setL10N(this.config.l10n);
        
        // Debug Statement
        Shared.Logger.log('carousel-init');
        
        // Ensure Special Events are initialized; we need the debouncedresize event
        Shared.initSpecialEvents();
        
        // Get Handles to Carousel Elements
        this.$carousel = $(this.config.elements.container);
        if (!this.$carousel.length) { return; }

        // Get Handles to Carousel Elements
        this.$carouselInner = this.$carousel.find(this.config.elements.inner);
        this.$carouselItem = this.$carousel.find(this.config.elements.item);
        this.$carouselParent = $(this.config.elements.parent.replace('%name%', this.config.name));
        this.$carouselNav = $(this.config.elements.nav.container); 
        this.$carouselNavPrev = $(this.config.elements.nav.prev.replace('%name%', this.config.name));
        this.$carouselNavNext = $(this.config.elements.nav.next.replace('%name%', this.config.name));
        
        // Prevent Dragging Item Links
        this.$carouselItem.find('a').prop('draggable', false);
        
        // Get Count of Items
        this.itemCount = this.$carouselItem.length;
        
        // Reset Scroll State
        this.slideState = 0;
        
        // Set Starting Item
        this.currentItem = this.config.start - 1;
        if (this.currentItem < 0 || this.currentItem >= this.itemCount) {
            this.currentItem = 0;
        }
        
        // Update Carousel (Element Sizes)
        this.updateContainer();
        this.updateHeight();

        // Create Scroller Control
        const id = this.config.id.replace('%name%', this.config.name);
        this.$carouselScroll = new IScroll(`#${id}`, {
            'hScroll'             : true, 
            'vScroll'             : false, 
            'hScrollbar'          : false, 
            'vScrollbar'          : false,
            'handleClick'         : false,
            'snap'                : 'div' + this.config.elements.item,
            'onBeforeScrollStart' : function() { return false; },
            'scrollX'             : true,
            'scrollY'             : false,
        });
        this.updateNav();
        this.updateSteps();
        
        // Focus on Start Item
        if (this.currentItem !== 0) {
            this.$carouselScroll.scrollToPage(this.currentItem-1, 0, 0);
        }
        
        // Initialize Events
        initEvents();
        
        // Debug Statement
        Shared.Logger.log('carousel-init-end');
    }, this);
    
    /**
     * Initialize Events for the Shared Carousel
     *  
     * @private 
     * @this Shared.Carousel
     * @return undefined
     */
    initEvents = $.proxy(function() {
        // Debug Statement
        Shared.Logger.log('carousel-events-init');
        
        // Window Resize/Orientation Events
        Shared.$('window')
            .on('debouncedresize' + this.config.eventNamespace, resizeEvent)
            .on('orientationchange' + this.config.eventNamespace, resizeEvent);

        // Attach scrollEnd event handler - similar to scrollMoveAndEnd from iScroll 4
        this.$carouselScroll.on('scrollEnd', $.proxy(function () {
            this.updateNav();
        }, this));
        
        // Attach Events to Carousel Controls
        this.$carouselNavNext.on('click' + this.config.eventNamespace, $.proxy(function(e) {
            e.preventDefault();
            if (!$(e.currentTarget).hasClass('disabled')) {
                // Calculate the target page index
                const currPageX = this.$carouselScroll.currentPage.pageX;
                let targetPageIndex = currPageX + this.movePrevNextStep;
                
                // Ensure the target page index is within the range of the available pages
                const maxPageIndex = this.$carouselScroll.pages.length - 1;
                targetPageIndex = Math.min(maxPageIndex, Math.max(0, targetPageIndex));
                
                this.$carouselScroll.goToPage(targetPageIndex, 0, 400);
            }
        }, this));

        this.$carouselNavPrev.on('click' + this.config.eventNamespace, $.proxy(function(e) {
            e.preventDefault();
            
            if (!$(e.currentTarget).hasClass('disabled')) {
                // Calculate the target page index
                const currPageX = this.$carouselScroll.currentPage.pageX;
                let targetPageIndex = currPageX - this.movePrevNextStep;
                
               // Ensure the target page index is within the range of the available pages
                targetPageIndex = Math.max(0, targetPageIndex);
                
                // Scroll to the target page
                this.$carouselScroll.goToPage(targetPageIndex, 0, 400);
            }
        }, this));
        
        // Prevent Clicking on Links in Carousel while Sliding
        this.$carousel.on('click' + this.config.eventNamespace, 'a', $.proxy(function(e) {
            if (this.isSliding()) {
                e.preventDefault();
                return false;
            }
            return true;
        }, this));
    }, this);
    
    /**
     * Observes and Responds to the Resize Events fired on the Global Window Object
     * 
     * @private
     * @this Shared.Carousel
     * @param {Object} eventData The Event Data associated with the Event 
     * @return {Boolean} The success state of the event; determines whether to allow event-bubbling
     */
    resizeEvent = $.proxy(function(eventData) {
        // Debug Statement
        Shared.Logger.log('carousel-resize');
        
        // Update Carousel
        window.setTimeout($.proxy(function() {
            this.currentItem = 0;
            this.updateContainer();
            this.updateHeight();
            this.updateNav();
            this.$carouselScroll.refresh();
            this.updateSteps();
            
            // Debug Statement
            Shared.Logger.log('carousel-resize-end');
        }, this), 100);
        
        return true;
    }, this);

    
    /**
     * scrollMove - Observes and Responds to the Scroll-Move Event fired on the iScroll Control
     * 
     * @private
     * @this Shared.Carousel
     * @return undefined
     */
    scrollMove = $.proxy(function(e) {
        // Debug Statement
        Shared.Logger.log('carousel-scroll-start');
        $(window).trigger('carousel.sliding');

        // Track Sliding State
        this.slideState = 1; 
    }, this);
    
    /**
     * scrollEnd - Observes and Responds to the Scroll-End Event fired on the iScroll Control
     * 
     * @private
     * @this Shared.Carousel
     * @return undefined
     */
    scrollEnd = $.proxy(function() {
        // Debug Statement
        Shared.Logger.log('carousel-scroll-end');
        $(window).trigger('carousel.stoppedSliding');
        
        // Track Sliding State
        window.setTimeout($.proxy(function() { this.slideState = 0; }, this), 10); 
        
        // Update Current Item
        this.currentItem = this.$carouselScroll.currPageX;
        if (this.currentItem < 0) { this.currentItem = 0; }
        if (this.currentItem > (this.itemCount - this.visibleItems)) { this.currentItem = (this.itemCount - this.visibleItems); }
        
        // Only lazy load if turned on, last request was successful (load limit was met), and near the end
        if(this.config.lazyLoad.enabled && !this.isLazyLoading && (this.currentItem + this.visibleItems + this.config.lazyLoad.before ) >= this.itemCount) {
            this.isLazyLoading = true;
            
            // Make ajax request for more items and place them in the carousel
            try {
                Shared.ajaxFetch(this.config.lazyLoad.url, {limit: this.config.lazyLoad.limit, page: this.config.lazyLoad.page}, 'GET', false,  $.proxy(function(itemData){
                    var itemChange = 0, newCount = 0;
                
                    // Append New Items to Carousel
                    this.$carouselInner.append(itemData);
                    
                    // Get Count of Items
                    this.$carouselItem = this.$carousel.find(this.config.elements.item);
                    newCount = this.$carouselItem.length;
                    itemChange = newCount - this.itemCount;
                    this.itemCount = newCount;
                    
                    // Stop lazy loading if there aren't any more items to load
                    if(itemChange < this.lazyLoadLimit){
                        this.config.lazyLoad.enabled = false;
                    }
                    
                    // Update New Items and Nav
                    this.updateContainer();
                    this.updateNav();
                    this.$carouselScroll.refresh();
                    this.updateSteps();
                    
                    // Make sure Items are not Draggable
                    this.$carouselItem.find('a').prop('draggable', false);
                    
                    // Fire Event for Items-Updated
                    this.config.itemsUpdated(this.itemCount, this.config.lazyLoad.limit, this.$carouselItem);
                    
                    this.config.lazyLoad.page += 1;
                    this.isLazyLoading = false;
                }, this));
            } 
            catch(e) {
                // Error Statement
                if (e instanceof Shared.Exception) {
                    Shared.Logger.log(e);
                } else {
                    Shared.Logger.log({'msg': 'ajax-failed', 'args': {'reason': e}, 'type': Shared.LVL_ERROR});
                }
            }
        } else {
            this.updateNav();
        }
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();
};
// End of Shared.Carousel


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * updateContainer - Responsively updates the size of elements based on the size of the viewport
 * 
 * @public 
 * @this Shared.Carousel
 * @return undefined
 */
Shared.Carousel.prototype.updateContainer = function() {
    var parentWidth, itemWidth;

    // Debug Statement
    Shared.Logger.log('carousel-update');
    
    // Get Full Width of Item
    itemWidth = this.$carouselItem.outerWidth(true);
    this.$carouselInner.width(itemWidth * this.$carouselItem.length);
    
    // Get Ideal Size of Container
    parentWidth = this.$carouselParent.width();
    
    // Update Carousel Width
    this.$carousel.width(parentWidth);
    
    // Update Position of Carousel Nav
    this.$carouselNav.css({'left': (parentWidth - this.$carouselNav.outerWidth(true))});
};

/**
 * updateNav - Responsively updates the navigation elements based on the size of the viewport
 *     - Hides navigation if viewport fits all items
 * 
 * @public 
 * @this Shared.Carousel
 * @return undefined
 */
Shared.Carousel.prototype.updateNav = function() {
    var carouselWidth, innerWidth, maxWidth;

    // re-get the items in case they've been switched out for Reco content
    // TODO: only do this when it's a reco carousel
    this.$carouselItem = this.$carousel.find(this.config.elements.item);
    
    // Debug Statement
    Shared.Logger.log('carousel-update-nav');
    
    carouselWidth = this.$carousel.width();
    innerWidth = this.$carouselInner.width();
    
    if (innerWidth <= carouselWidth) {
        this.$carouselNav.css('display', 'none');
    } else {
        this.$carouselNav.css('display', '');
        this.$carouselNav.find('a').removeClass('disabled'); 

        if (this.$carouselScroll.currentPage.x >= -0) {
            this.$carouselNavPrev.addClass('disabled');
            if (this.containerFade) {
                $(this.config.elements.fade.left).fadeOut('fast', $.proxy(function() { $(this.config.elements.fade.left).addClass('hide'); }, this));
                $(this.config.elements.fade.right).removeClass('hide').fadeIn();
            }
        }
            
        if (this.$carouselScroll.currentPage.x <= this.$carouselScroll.maxScrollX) {
            this.$carouselNavNext.addClass('disabled');
            if (this.containerFade) {
                $(this.config.elements.fade.right).fadeOut('fast', $.proxy(function() { $(this.config.elements.fade.right).addClass('hide'); }, this));
                $(this.config.elements.fade.left).removeClass('hide').fadeIn();
            }
        }
    }
};

/**
 * updateHeight - Updates the Height of the Carousel based on Item Height
 * 
 * @public
 * @this Shared.Carousel
 * @return undefined
 */
Shared.Carousel.prototype.updateHeight = function() {
    // Fix Height of Carousel
    window.setTimeout($.proxy(function() {
        var h = this.$carousel.find(this.config.elements.item).outerHeight(true);
        if (h !== null && h > 50) {
            this.$carousel.css({'height': h});
        }
    }, this), 0);
};

/**
 * updateSteps - Updates the Number of Steps to move when clicking the Prev/Next buttons
 * 
 * @public
 * @this Shared.Carousel
 * @return undefined
 */
Shared.Carousel.prototype.updateSteps = function() {

    // re-get the items in case they've been switched out for reco
    // TODO: only do this when it's a reco carousel
    this.$carouselItem = this.$carousel.find(this.config.elements.item);

    var carouselWidth = this.$carousel.width(),
        itemWidth = this.$carouselItem.outerWidth(true);
    
    // Calculate Number of Items to Move By
    this.movePrevNextStep = Math.floor(carouselWidth / itemWidth);
};


/**
 * isSliding - Checks if the Carousel is currently in a State of Sliding
 * 
 * @public
 * @this Shared.Carousel
 * @return {Boolean} True if the Carousel is currently sliding
 */
Shared.Carousel.prototype.isSliding = function() {
    return (this.slideState > 0);
};

/**
 * Completely destroy the iScroll and free some memory
 * 
 * @public
 * @this Shared.Carousel
 * @return undefined
 */
Shared.Carousel.prototype.destroy = function() {
    // Debug Statement
    Shared.Logger.log('carousel-destroy');
    
    // Remove Events that are tied to the Window Object
    Shared.$('window')
        .off('debouncedresize' + this.config.eventNamespace)
        .off('orientationchange' + this.config.eventNamespace);
    
    // Remove previous events
    this.$carouselNavNext.off('click' + this.config.eventNamespace);
    this.$carouselNavPrev.off('click' + this.config.eventNamespace);
    this.$carousel.off('click' + this.config.eventNamespace);
    
    // Destroy iScroll Control
    this.$carouselScroll.destroy();
    this.$carouselScroll = null;
};


/* ******************************************************************************************** */
/* * Default Configuration Options                                                            * */
/* ******************************************************************************************** */
Shared.Carousel.defaultConfig = {
    'id'            : '%name%-items-carousel',
    'name'          : '',
    'start'         : 1,
    'elements' : {
        'container' : '.carousel',
        'item'      : '.tile',
        'parent'    : '.%name%-container',
        'inner'     : '.carousel-inner',
        'nav'       : {'container': '.carousel-nav', 'prev': '#%name%NavPrev', 'next': '#%name%NavNext'},
        'loadMore'  : '.load-more',
        'fade'      : {'right': '.featured-container-fade.fade-right', 'left': '.featured-container-fade.fade-left'}
    },
    'lazyLoad' : {
        'enabled' : false,
        'url'     : '',
        'limit'   : 10,
        'page'    : 1,
        'before'  : 20
    },
    'l10n' : {
        'carousel-init'         : 'Shared:Carousel Initializing',
        'carousel-init-end'     : 'Shared:Carousel Complete & Ready',
        'carousel-events-init'  : 'Shared:Carousel Initializing Events',
        'carousel-update'       : 'Shared:Carousel Updating Container',
        'carousel-update-items' : 'Shared:Carousel Updating Items',
        'carousel-update-nav'   : 'Shared:Carousel Updating Navigation',
        'carousel-scroll-start' : 'Shared:Carousel Scrolling',
        'carousel-scroll-end'   : 'Shared:Carousel Scrolling Stopped',
        'carousel-resize'       : 'Shared:Carousel Event - Resize',
        'carousel-resize-end'   : 'Shared:Carousel Event - Resize-End',
        'carousel-destroy'      : 'Shared:Carousel Destroying'
    },
    'eventNamespace' : '.Shared.Carousel',
    
    'itemsUpdated' : function() {
        return false;
    }
};
