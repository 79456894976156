const getDisplayProperty = (el) => {
  if ($(el).parent().hasClass('reco-panel-items')) {
    return 'recommendationPanel';
  }
  if ($(el).parents('body').hasClass('listing-page')) {
    return 'stream';
  }
  if ($(el).parents().hasClass('hub-page')) {
    return 'hub';
  }
  if ($(el).parents().hasClass('single-page')) {
    return 'item-level';
  }
  return undefined;
};

const isCapturedInSiteEngager = (el) => !!$(el).parents('iframe').hasClass('ufw-iframe');

export { getDisplayProperty, isCapturedInSiteEngager };
