import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

/**
 * Hubs Responsive
 * 
 * @class Responsive
 * @kind JS.Module
 * @mixin
 * @classdesc Provides the Responsive JS Behaviour for the App
 * @namespace Hubs
 * @constructor
 */
Hubs.Responsive = function() {};

/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Main Hub Sharing Menu 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.resizeHubShare = function() {
    var w, $icons, $shareHub = $('.share-item');
    if (!$shareHub.length) { $shareHub = $('.share-hub'); }
    if (!$shareHub.length) { return; }
    
    // Get Number of Share Icons
    $icons = $shareHub.find('li');
    
    // Get Width of Icons
    w = $icons.find('a').outerWidth();
    
    // Update Width of Hub Sharing Container
    $shareHub.css({'width': (($icons.length-1) * w)});
    
    // Update Padding when only 2 or 3 items displayed
    if ($icons.length === 3) { $icons.css({'padding': '0 7px'}); }
    if ($icons.length === 2) { $icons.css({'padding': '0 42px'}); }
};

/**
 * Reposition Header elements (vertically align) 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.repositionHeader = function() {
    //vertically center header-main based on its height and the height of large-header
    var $largeHeader = $(Hubs.Config.containers.largeHeader);
    if(!$largeHeader.length) { return; }
    
    // Debug Statement
    Shared.Logger.log('responsive-header');
    
    var $wrapper = $largeHeader.find('.header-wrapper');
    var mTop = parseInt( ($largeHeader.height() - $wrapper.height() ) / 2, 10);
    $wrapper.css({'top': 0, 'margin-top': mTop});
};

/**
 * Resize all Image Tiles on Page 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.resizeImageTiles = function() {
    // Debug Statement
    Shared.Logger.log('responsive-resize');
    
    // Iterate through all Tiles with Images on Page
    $('.tile .img img').each($.proxy(function(idx, imgEl) {
        this.resizeImageTile(imgEl);
    }, this));
};

/**
 * Resize a Specific Image Tile on Page 
 * 
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.resizeImageTile = function(imgEl, callback) {
    var $el        = $(imgEl).parent(),
        $tile      = $el.closest('.tile'),
        tileWidth  = $el.width(),
        tileHeight = $el.height(),
        $tileImg   = $el.find('img'),
        imgHeight  = $tileImg.height(),
        imgWidth   = $tileImg.width();
    
    if (imgHeight <= 0 || $tileImg.hasClass('shorter') || $tileImg.hasClass('taller')) { return; }

    // If the height is greater than the width, portrait style
    if (imgHeight >= imgWidth){
        $tile.addClass('portrait');
        tileWidth  = $el.width();
        tileHeight = $el.height();
    }
    
    // If the Image is Taller than the Tile
    if (imgHeight >= tileHeight) {
        $tileImg.addClass('taller');

        // Centering the image vertically in the tile, EXCEPT for docs, because we want to see the top of the doc in the tile
        if (!$el.parent('.tile').is('.uberflip')) {
            $tileImg.css({'margin-top' : -((imgHeight - tileHeight) / 2 )});
        }
    }
    
    // If the Image is Shorter than the Tile
    else {
        if (Hubs.isMobile()){
            $tileImg.css({'height' : tileHeight, 'width' : 'auto'});
        } else {
            $tileImg.addClass('shorter');
        }
        
        // Center the image horizontally in the tile
        $tileImg.css({'margin-left' : -(($tileImg.width() - tileWidth) / 2 )});
    }
    
    if (typeof callback === 'function') {
        callback.call(this);
    }
};

/**
 * Updates the Position of the Sharing Menu on Level 3 to maintain alignment with content
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.repositionLevel3Sharing = function() {
    // Hide Top-Nav Sharing Button on Twitter Item Pages
    var $shareToggle = $('.share-toggle');
    if ($shareToggle.length) {
        if (this.pageType === Hubs.PAGE_TYPE_ITEM && ($('.share-twitter').length || $('.btn-facebook').length)) {
            $shareToggle.hide();
        } else {
            $shareToggle.show();
        }
    }
};

/**
 * Keep instagram videos a perfect square
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.resizeInstagramVideo = function() {
    // Hide Top-Nav Sharing Button on Twitter Item Pages
    var $videoContainer = $('.level-three.instagram .entry-video');
    if ($videoContainer.length) {
        $videoContainer.css("height", $videoContainer.width() + "px" );
    }
};

/**
 * Reposition CTA depending on Screen Size
 *       
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.repositionCta = function() { 
    var vpw, pos, $ctaContainer = $('.cta-item-container');
    if (!$ctaContainer.length) { return; }
    
    // Check Size of Viewport
    vpw = Shared.$('window').width();
    
    // Check Current Position of CTA
    pos = $ctaContainer.data('position');
    
    if (vpw >= Hubs.Config.breakpoints.desktop2) {
        if (pos === 'outside') { return; }
        
        // Update Position of CTA
        $('.page-width.item-level .item-contents-with-cta').eq(0).after($ctaContainer);
        
        // Determine if CTA is small enough to have Fixed Position
        if($('body').height() < 700) {
            $ctaContainer.find('.cta').css('position', 'relative');
        } else {
            $ctaContainer.find('.cta').css('position', 'fixed');
        }
        
        // Store Position of CTA
        $ctaContainer.data('position', 'outside');
    }
    else {
        if (pos === 'inside') { return; }
        
        // Update Position of CTA
        $ctaContainer.find('.cta').css('position', 'relative');
        $('.entry-wrapper').append($ctaContainer);
        
        // Store Position of CTA
        $ctaContainer.data('position', 'inside');
    }
};

/**
 * Resize all CTAs that have aspect ratio text set up
 *       
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.responsiveCtaText = function() {
    $.each($('.cta[data-aspect-ratio]'), function(){
        var $el = $(this),
            fontAspectRatio = $el.attr('data-aspect-ratio'),
            $updateEls = $el.find('p'),
            ctaWidth = $el.width(),
            ctaHeight = ($el.height()>1) ? $el.outerHeight() : parseFloat($el.css('padding-bottom')),
            fontSize =  parseFloat(fontAspectRatio) * (ctaWidth+ctaHeight);
        
        $updateEls.css({'font-size': fontSize + 'px', 'margin-top': fontSize + 'px', 'margin-bottom': fontSize + 'px'});
    }); 
};

/**
 * Resizes the cta forms, mainly to make sure the anchor tag is always showing.
 *
 * @public
 * @this Hubs.App
 * @return undefined
 */
Hubs.Responsive.prototype.resizeCtaForms = function() {
    var $formCtas = $(Hubs.Config.cta.elements.form);
    
    $.each($formCtas, function(){
        var $container = $(this),
            $top = $container.find(Hubs.Config.cta.elements.formTop),
            $cancelAnchor = $top.find(Hubs.Config.cta.elements.hideCancel),
            $bottom = $container.find(Hubs.Config.cta.elements.formBottom),
            topUp = 0,
            bottomUp = 0;
        
        // Anchor tag only needs to be resized if it is showing
        if($cancelAnchor.css('display') === 'block'){
            // Bring up the top all the way but still show the button portion for cancel
            topUp = -1 * ($top.height() - $cancelAnchor.outerHeight());
            
            // Bottom comes all the way up but below the cancel button
            bottomUp = $top.find('.hide-preview-cancel').outerHeight();
            
            $top.addClass('notransition').css({'top': topUp});
            $bottom.addClass('notransition').css({'top': bottomUp});
            
            // Removing transitions needs a delay otherwise the transitional class gets added back too quickly.
            // Transition happens for the top css attribute even though the code is run synchronously 
            setTimeout(function(){
                $top.removeClass('notransition');
                $bottom.removeClass('notransition');
            }, 500);
            
        }
    });
};
