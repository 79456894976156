import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

/**
 * Hubs CTAs
 *
 * @class CTAs
 * @kind JS.Module
 * @mixin
 * @classdesc Provides the JS Behaviour for CTAs
 * @namespace Hubs
 * @constructor
 */
Hubs.CTAs = function() {};

// Public Static Variable that is necessary to expose to kill setTimeout of a delayed CTA
Hubs.CTAs.repeatDelayCTA = null;
Hubs.CTAs.initialDelayCTA = null;

// Public Static Variable to keep track of whether we removed fullscreen from a flipbook in the CTA block overlay case
Hubs.CTAs.fullscreenedFlipbook = false;

// Container var which contains CTAs that need to have a view event tracked
Hubs.CTAs.ctasForViewTracking = [];

function getCtaBottom($cta) {
    return $cta.find(Hubs.Config.cta.elements.formBottom + '[data-cta-id="' + $cta.attr('data-cta-id') + '"]');
}

function refreshScrollbar($cta) {

    var $bottom = getCtaBottom($cta);

    if ($bottom.find('form').height() > $bottom.height()) {
        $bottom.perfectScrollbar({
            wheelSpeed: 1,
            useKeyboard: false,
            wheelPropagation: false,
            maxScrollbarLength: 215
        });
        $bottom.perfectScrollbar('update');
    } else {
        $bottom.perfectScrollbar('destroy');
    }
}

/**
 * Mark a CTA for view tracking
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.markCtasForViewTracking = function($element) {
    var $ctaContainers = $(Hubs.Config.cta.elements.container);
    var $ctaElement;
    var $el;
    var ctaId;

    if ($element && $element.is(':visible')) {
        // Blocking CTAs pass in the parent element and not the actual CTA element
        // Make sure we grab the ID from the CTA element
        if ($element.hasClass('cta')) {
            $ctaElement = $element;
        } else {
            $ctaElement = $element.find('.cta');
        }

        // No ID so we have an invalid element somehow, do nothing
        if (!$ctaElement.length) { return; }

        ctaId = $ctaElement.attr('id').split('-')[2];
        if (parseInt(ctaId, 10) > 0 && Hubs.CTAs.ctasForViewTracking.indexOf(ctaId) < 0) {
            Hubs.CTAs.ctasForViewTracking.push(ctaId);
        }
        return;
    }

    // No specific element passed in, loop through all visible CTAs
    $.each($ctaContainers, function(index, $cta) {
        $el = $($cta);
        ctaId = $el.attr('id').split('-')[2];
        if (parseInt(ctaId, 10) > 0 && $el.is(':visible') && Hubs.CTAs.ctasForViewTracking.indexOf(ctaId) < 0) {
            Hubs.CTAs.ctasForViewTracking.push(ctaId);
        }
    });
};

Hubs.CTAs.showCta = function($element, isDelayed) {
    $element.show();

    // Mark any visible CTAs as ones that need a view tracked
    Hubs.CTAs.markCtasForViewTracking($element);

    // Delayed CTAs will not be part of the original batch of CTAs that were sent if any.
    // Manually trigger the record function
    if (isDelayed !== undefined && isDelayed) {
        Hubs.App.recordCtaViews();
    }
};

/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Initializes Events for CTAs
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.initCTAs = function() {
    var $doc = Shared.$('document');

    // Activate the cta
    $doc.on('focus', Hubs.Config.cta.elements.activateForm, $.proxy(this.activateCtaForm, this));

    // Submits the cta form data to the server
    $doc.on(Hubs.CLICK_EVENT_CTA_SUBMIT, Hubs.Config.cta.elements.submitForm, $.proxy(this.submitCtaForm, this));

    // Moves the cta form back to its original display state
    $doc.on(Hubs.CLICK_EVENT_CTA_CANCEL, Hubs.Config.cta.elements.hideCancel, $.proxy(this.moveCtaFormDown, this));

    // Moves the cta form back to its original display state
    $doc.on(Hubs.CLICK_EVENT_CTA_DISMISS, Hubs.Config.cta.elements.noThanks, $.proxy(this.dismiss, this));

};


/**
 * Sets up the form cta handling
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.initializeFormCtas = function(){
    // Add the cta transition class if not mobile
    $.each($(Hubs.Config.cta.elements.hideCancel), function(e) {
        var $top = $(this).parent(),
            ctaID = $top.attr('data-cta-id'),
            $bottom = $(Hubs.Config.cta.elements.formBottom + '[data-cta-id="'+ctaID+'"]'),
            $inputs = $bottom.find('input');

        if(!Hubs.isMobile() && !$top.hasClass('cta-transition')){
            $top.addClass('cta-transition');
            $bottom.addClass('cta-transition');
        }
    });

    // Need to stop propagation of keydown events for forms as perfect scrollbar adds some (space scrolls down)
    $(Hubs.Config.cta.elements.container + ' input').on('keydown', function(e){
        e.stopPropagation();
    });

    var eloquaConnected = Hubs.mapIsConnected('Eloqua');

    if (eloquaConnected) {
        Hubs.registerEloquaVisitor();
    }
};


/**
 * When a block CTA is shown on top of a fullscreen Flipbook, unexpand it and record that fact
 *
 * @public
 * @static
 * @this [self]
 * @return undefined
 */
Hubs.CTAs.prototype.unexpandForCta = function() {
    var $flipbook = $(Hubs.Config.containers.flipbookContainer);
    if (!$flipbook.length) { return; } // ensure we have a flipbook container
    if (!(Shared.isBrowserFullscreen() || Shared.isPseudoFullscreen()) && !$flipbook.hasClass('initial-expanded')) { return; }

    Hubs.CTAs.fullscreenedFlipbook = true;
    this.toggleFullscreen(false);

    // Check Timer of Delayed Initial Fullscreen (from app.js)
    if (this.initialFullscreenTimer) {
        window.clearTimeout(this.initialFullscreenTimer);
    }
};

/**
 * When a block CTA is dismissed and the Flipbook was previously fullscreen, expand it again
 *
 * @public
 * @static
 * @this [self]
 * @return undefined
 */
Hubs.CTAs.prototype.expandAfterCta = function() {
    var $flipbook = $(Hubs.Config.containers.flipbookContainer);
    if (!$flipbook.length || !Hubs.CTAs.fullscreenedFlipbook) { return; } // ensure we have a flipbook container

    Hubs.CTAs.fullscreenedFlipbook = false;
    this.toggleFullscreen(true, true);
};

/**
* Dismisses a blocking cta by hiding it
*
* @public
* @this Hubs.CTAs
* @return undefined
*/
Hubs.CTAs.prototype.dismiss = function(e) {
    var $el = $(e.currentTarget);
    var $blockCta = $(Hubs.Config.cta.elements.blockCta);
    var $blockingCta = $(Hubs.Config.cta.elements.blockingCta);
    var repeatDelay = parseInt($el.attr('data-repeat-delay')) * 1000;
    var placementID = $el.attr('data-placement-id');
    var delayTill = new Date();

    // Remove Blocking CTA
    $blockingCta.removeClass('blocking-cta');
    $blockCta.hide().find(Hubs.Config.cta.elements.hideCancel).click();

    // If there is a hidden embed, make sure it is visible
    $(Hubs.Config.cta.elements.hiddenEmbed).removeClass('hide-embed');

    // Restore Expanded Flipbook State
    this.expandAfterCta();

    // Check for Repeat Blocking CTA
    if (repeatDelay > 0) {
        Hubs.CTAs.repeatDelayCTA = Hubs.root.setTimeout($.proxy(function() {
            var $repeatBlockCta = $(Hubs.Config.cta.elements.blockCta);

            if ($repeatBlockCta.length) {
                // Display Repeated Blocking CTA
                $(Hubs.Config.cta.elements.possibleBlock).addClass('blocking-cta');
                $(Hubs.Config.cta.elements.possibleHiddenEmbed).addClass('hide-embed');
                $repeatBlockCta.show();

                // Remove Expanded Flipbook State
                this.unexpandForCta();

                // Reposition CTA
                this.repositionBlockingCTA($repeatBlockCta);
            }
        }, this), repeatDelay);
    }

    // If repeat delay is less than 0, that means the placement is suppose to be hidden for a set time (session)
    else if (repeatDelay < 0){
        delayTill = delayTill.setDate(delayTill.getDate() + Hubs.Config.cta.hidePlacementNumDays);
        this.storePlacement({placementID: placementID, hideUntil: delayTill});
    }

    this.embeddedScrollData.cta_b.f = false;
    //this.embeddedScrollData.cta_s.f = false;
    return false;
};

/**
 * Moves the cta form down back to its original state (Requires *this to point to the cancel anchor)
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.moveCtaFormDown = function(e) {
    var $el = $(e.currentTarget);
    var $top = $el.parent();
    var ctaID = $top.attr('data-cta-id');
    var $container = $el.closest(Hubs.Config.cta.elements.container + '[data-cta-id="'+ctaID+'"]');
    var $originalContainer = $(Hubs.Config.cta.elements.container + '[data-cta-id="'+ctaID+'"]');
    var $bottom = $($container.find(Hubs.Config.cta.elements.formBottom + '[data-cta-id="'+ctaID+'"]')[0]);
    var $fields = $container.find(Hubs.Config.cta.elements.formField);
    var $blockingCTA = $('.block-cta');

    if (Hubs.isMobile()) {
        // Remove Overlay-CTA
        $('#mobile-cta-overlay').remove();
        $originalContainer.show().removeClass("setValues");

        // link between cta container and mobile overlay (this way we know which cta belongs to the overlay)
        $originalContainer.removeClass('active-mobile-overlay');

        Shared.$('body').removeClass('noscroll');

        var hiddenEmbed = ($(Hubs.Config.cta.elements.possibleHiddenEmbed).length);

        if ($blockingCTA.length && hiddenEmbed === false) {
            this.repositionBlockingCTA($blockingCTA);
        }

        // Need to un-attach the good cta submit event and re-attach the fake one until the form is in full view as per comment in initCTAs
        Shared.$('document').off(Hubs.CLICK_EVENT_CTA_SUBMIT);
        Shared.$('document').on(Hubs.CLICK_EVENT_CTA_SUBMIT, Hubs.Config.cta.elements.submitForm, function(){return false;});

        if (Hubs.isEmbedded() && 'parentIFrame' in window) {
            window.parentIFrame.scroll('top');
            Shared.$('window').trigger('scroll');
        }
    }

    $container.removeClass('setValues');
    $bottom.removeClass('fields-revealed');
    $bottom.find('.cta-field-section').removeClass('smaller');
    $bottom.scrollTop(0);
    $bottom.perfectScrollbar('destroy');

    $.each($fields, function(e) {
        $(this).removeAttr('style').removeClass('error');
    });

    // Show activation button
    if (Hubs.isMobile()) {
        $originalContainer.find('.cta-button-container').show();
    } else {
        $container.find('.cta-button-container').show();
    }
};

/**
 * Moves the cta form up to display other fields and submit (Requires *this to point to a form field)
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.moveCtaFormUp = function($el) {
    // Current CTA Container Element
    var id = $el.attr('data-cta-id');

    // CTA Elements
    var $container = $el.closest(Hubs.Config.cta.elements.container + '[data-cta-id="'+id+'"]');
    var $fields = null;
    var $top = null;
    var $cancelAnchor = null;
    var $bottom = null;
    var $mobileCtaOverlay = null;

    // Positioning Vars
    var topUp = 0;
    var bottomUp = 0;

    // Hide activation button
    $el.closest('.cta-button-container').hide();

    // Try and set the known values on the first form up - should only happen once
    if (!$container.hasClass('setValues')) {
        $fields = $container.find(Hubs.Config.cta.elements.formField);
        $.each($fields, function(){
            var $el = $(this);
            var value = $el.attr('data-value');

            if (value && value != 'UberflipSelect') {
                if ($el.attr('type') == 'checkbox') {
                    $el.attr('checked', /true|1/i.test(value));
                } else {
                    $el.val(value);
                }
            }
        });

        $container.addClass('setValues');
    }

    if (!$('#mobile-cta-overlay').length) {
        if (Hubs.isMobile()) {
            // Remove the touch move event as no longer needed
            $el.off(Hubs.TOUCH_MOVE_EVENT_CTA_FIELD);
                            // Build Overlay-CTA
            $mobileCtaOverlay = $('<div/>')
                .attr('id', 'mobile-cta-overlay')
                .addClass('cta full-screen-cta setValues')
                .appendTo(Shared.$('body'));

            // Copy Contents from Original CTA
            $container.children().clone().appendTo($mobileCtaOverlay);

            // this is required to get the correct CTA Element because the same cta-id can appear multiple times on the page
            // link between cta container and mobile overlay (this way we know which cta belongs to the overlay)
            // before add the class - let's remove all classes from ctas (could be multiple of the same cta on the page)
            // we can't remove it after cta submit due to delay in ufa collector
            $('[data-cta-id=' + id + ']').removeClass('active-mobile-overlay');
            $container.addClass('active-mobile-overlay');

            // Clone the select values if not uberflipselect (non chosen value)
            $container.find('select').each(function(i) {
                var $select = $(this);
                if ($select.val() != 'UberflipSelect') {
                    $mobileCtaOverlay.find('select').eq(i).val($select.val());
                }
            });

            // Update Style of Overlay-CTA
            $mobileCtaOverlay
                .css('background-color', $container.css('background-color'))
                .attr('data-cta-id', $container.attr('data-cta-id'))
                .attr('data-list-id', $container.attr('data-list-id'))
                .attr('data-integration', $container.attr('data-integration'));

            // Update Header of CTA
            $top = $mobileCtaOverlay.find(Hubs.Config.cta.elements.formTop);
            $top.css({'background-color': $container.css('background-color'), 'height': '50px'});
            $top.find('p').remove();

            // Update Scroll Position to Center CTA on Page
            if (Hubs.isEmbedded()) {
                $mobileCtaOverlay.find(Hubs.Config.cta.elements.hideCancel).css('display', 'block');
                if ('parentIFrame' in window) {
                    window.parentIFrame.scroll('top');
                }
            } else {
                $mobileCtaOverlay.find(Hubs.Config.cta.elements.hideCancel).fadeIn('fast');
            }

            // Fix Positioning for iOS Devices
            if (Shared.IOS || Shared.IOS7 || Shared.IOS8) {
                Shared.scrollTop(0);
                Shared.$('body').addClass('noscroll');
            }

            // Focus on First Form Input
            $bottom = $mobileCtaOverlay.find(Hubs.Config.cta.elements.formBottom);
            Shared.onNextEventLoop(function() {
                $bottom.find(':not(.hide) > input').eq(0).trigger('focus');
            }, this, 0);

            // For mobile, setting a delay of 1 second before the submit form event is attached to avoid double clicking
            // on the first field and then the submit button (this was common for ios 7 chrome)
            Hubs.root.setTimeout($.proxy(function() {
                Shared.$('document').off(Hubs.CLICK_EVENT_CTA_SUBMIT, Hubs.Config.cta.elements.submitForm);
                Shared.$('document').on(Hubs.CLICK_EVENT_CTA_SUBMIT, Hubs.Config.cta.elements.submitForm, $.proxy(this.submitCtaForm, this));
            }, this), 1000);

        } else {
            $bottom = getCtaBottom($container);
            $bottom.addClass('fields-revealed');

            if ($bottom.find('.cta-field-section:not(.hide)').length == 4) {
                $bottom.find('.cta-field-section:not(.hide)').addClass('smaller');
            }

            refreshScrollbar($container);

        }

        var $firstFieldContainer = $container.find('.form-fields .cta-field-section:not(.hide):first');
        if ($firstFieldContainer.length) {
            // move focus to the first field
            $firstFieldContainer.find('input').trigger('focus');

            // Update activation field label
            var fieldName = $firstFieldContainer.find('.cta-field-name:not(.label-only-text)').text();
            if (fieldName) {
                $container.find('.cta-activate-button-container .cta-field-name').text(Shared.jhe(fieldName));
            }
        }
    }

    return true; // !Hubs.isMobile();
};


function getCtasObject($el) {
    var ctaID = $el.attr('data-cta-id');
    var $current = $el.closest(Hubs.Config.cta.elements.container + '[data-cta-id="' + ctaID + '"]');
    var $all = $(Hubs.Config.cta.elements.container + '[data-cta-id="' + ctaID + '"]');
    var $successLink        = $all.find(Hubs.Config.cta.elements.formSuccess).find('a');
    return {
        ctaID: ctaID,
        isBlocking: $(Hubs.Config.cta.elements.blockCta + ' ' + Hubs.Config.cta.elements.container).attr('data-cta-id') === ctaID,
        hidesHiddenEmbed: $(Hubs.Config.cta.elements.hiddenEmbed).length,
        hasMoreFields: $el.hasClass('more-fields'),
        current: {
            container: $current,
            top: $current.find(Hubs.Config.cta.elements.formTop),
            bottom: $current.find(Hubs.Config.cta.elements.formBottom),
            error: $current.find(Hubs.Config.cta.elements.formError),
            success: $current.find(Hubs.Config.cta.elements.formSuccess),
            fields: $current.find(Hubs.Config.cta.elements.formField),
            loading: $current.find(Hubs.Config.cta.elements.submitting),
            sprites: $current.find(Hubs.Config.cta.elements.sprite),
            eloquaGUID: $current.find('.hide.GUID input'),
            integration: $current.attr("data-integration").toLowerCase(),
            isFullscreen: $current.hasClass('full-screen-cta'),
            optIn: $current.find(Hubs.Config.cta.elements.optIn),
            optInRequired: $current.find(Hubs.Config.cta.elements.optInRequired)
        },
        all: {
            container: $all,
            top: $all.find(Hubs.Config.cta.elements.formTop),
            bottom: $all.find(Hubs.Config.cta.elements.formBottom),
            error: $all.find(Hubs.Config.cta.elements.formError),
            success: $all.find(Hubs.Config.cta.elements.formSuccess)
        },
        hasSuccessLink: $successLink.length,
        successUrl: $successLink.length ? $successLink.attr('href') : '',
        listID: $current.attr('data-list-id'),
        currentPos: $current.attr('data-saved-pos')
    };
}

function getCtaFieldValue($element, integration) {
    var checked = 1;
    var notChecked = 0;

    if (integration === 'hubspot') {
        checked = 'true';
        notChecked = 'false';
    }

    if ($element.attr('type') === 'checkbox') {
        return $element.is(':checked') ? checked : notChecked;
    }

    var fieldValue = $element.val();
    if (typeof fieldValue === 'string') {
        return fieldValue.trim();
    }

    return fieldValue;
}

/**
 * Submits a cta form (Requires *this to point to the cta form submit btn)
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.submitCtaForm = function(e) {
    var $el                 = $(e.currentTarget);
    var ctaID               = $el.attr('data-cta-id');
    var $all                = $(Hubs.Config.cta.elements.container + '[data-cta-id="'+ctaID+'"]');
    var ctaName             = $all.attr('data-cta-name');
    var integrationService  = $all.attr('data-integration');
    var queryString         = $.extend({}, this.queryString);
    var ctas                = getCtasObject($el);
    var $firstError         = false;
    var valid               = true;
    var pageViews           = {};
    var extraParams         = [];
    var formFieldData       = [];
    var scrollTo            = 0;
    var errorSpriteClass    = 'cta-sprite-small-exclaim';
    var errorClass          = 'error';
    var email               = false;
    var knownUser           = false;
    var mappedData          = {};

    // Remove all the errors, then re-evaluate whether or not there is any errors
    ctas.current.sprites.removeClass(errorSpriteClass);
    $.each(ctas.current.fields, function(){
        var $el                 = $(this),
            val                 = getCtaFieldValue($el, ctas.current.integration),
            formFieldId         = $el.attr('data-field-id'),
            formValidation      = $el.attr('data-regex'),
            $required           = ($el.attr('data-required') == '1'),
            $errorSprite        = ctas.current.container.find(Hubs.Config.cta.elements.sprite + '[data-field-id="'+formFieldId+'"]'),
            fieldName           = ctas.current.container.find(Hubs.Config.cta.elements.fieldName + '[data-field-id="'+formFieldId+'"]').html(),
            re                  = new RegExp(formValidation, 'i');

        var fieldValid;
        if ($required && val === '') {
            fieldValid = false;
        } else {
            fieldValid = ( (re.test(val) || val === 'UberflipFlip') && val !== 'Error with ' + fieldName ) || !$required;
        }

        // If a param in the query string is mapped to the field and the value tells us to change it, do so
        // If the query string is empty but the value exists in local storage use that value
        var ctaFieldUseQueryParamValues = $el.attr('data-allow-query-string');
        if ($el.attr('type') === 'hidden' && ctaFieldUseQueryParamValues) {
            var ctaFieldQueryParamName = $el.attr('data-query-string-param-name');
            var currentQueryParamValue = queryString[ctaFieldQueryParamName];
            if (currentQueryParamValue) {
                val = currentQueryParamValue;
            } else {
                var storedCtaQueryParams = localStorage.getItem(Hubs.CTA_LOCALSTORAGE_KEY);
                var storedValues = storedCtaQueryParams ? JSON.parse(storedCtaQueryParams) : {};
                if (storedValues[ctaFieldQueryParamName]) {
                    val = storedValues[ctaFieldQueryParamName];
                }
            }
        }

        var isTypeOfHidden = $el.attr('type') === 'hidden';
        var isVisible = $el.is(":visible");

        var isEmptyHiddenField = isTypeOfHidden && val === '';
        var isInvisibleInputField = !isTypeOfHidden && !isVisible;

        // We should always submit the email field so that the submission data can be tied to a contact
        var isEmail = $el.parent().hasClass('email-field');

        var ignoreThisField = !isEmail && (isEmptyHiddenField || isInvisibleInputField);

        if (ignoreThisField) {
            return;
        }

        mappedData[$el.attr('data-mapping')] = val;

        // If valid is currently false or tests as false, make sure stays as false
        formFieldData.push({id: formFieldId, value: val});

        if ($el.closest('.email-field').length) {
            email = $el.val();
        }

        // If not valid, grab the first error, and set error classes
        if(!fieldValid && $el.is(":visible")){
            valid = false;
            $firstError = !$firstError ? $el.parent() : $firstError;
            $el.addClass(errorClass).on(Hubs.FOCUS_EVENT, function(){
                $errorSprite.removeClass(errorSpriteClass);
                $el.removeClass(errorClass).off(Hubs.CLICK_EVENT);
            });
            $errorSprite.addClass(errorSpriteClass);
        }
    });

    // Opt in field has been enabled, must be checked to continue
    if(ctas.current.optIn.length && !ctas.current.optIn.is(':checked')){
        ctas.current.optInRequired.addClass('show-required-text');
        ctas.current.optIn.on(Hubs.CLICK_EVENT, function(){
            ctas.current.optInRequired.removeClass('show-required-text');
        });
        $firstError = !$firstError ? ctas.current.optIn.parent() : $firstError;
        valid = false;
    }

    // If not valid, scroll to the error and exit
    if(!valid){
        if(ctas.current.bottom.find('form').height() > ctas.current.bottom.height()){
            // Scroll to is relative to the container the errors are in (that container is scrollable)
            scrollTo = $firstError.offset().top - ctas.current.bottom.offset().top + ctas.current.bottom.scrollTop();
            ctas.current.bottom.scrollTop(scrollTo).perfectScrollbar('update');
        } else if (ctas.current.container.hasClass('full-screen-cta')){
            // Scroll to is page relative in this case
            scrollTo = $firstError.offset().top;
            Shared.scrollTop(scrollTo);
        }

        return false;
    }

    // Update page views
    var itemViews = localStorage.getItem(integrationService);
    if (itemViews) {
        try {
            itemViews = JSON.parse(itemViews);
            if (itemViews && _.isArray(itemViews.items)) {
                pageViews = itemViews.items;
            }
        } catch (parseError) {
            // Reset storage
            localStorage.setItem(integrationService, JSON.stringify({'items':[]}));
        }
    }

    var recoItemId;
    var recoCollectionId;
    var recotrk;
    var recoCookie = Shared.getCookie('uf_last_reco');

    if (Hubs.appInstance.pageType === Hubs.PAGE_TYPE_CTA && queryString.recotrk && queryString.itm && queryString.col) {
        // Check if we're running the app as embedded CTA only, and if we're
        // passing recommended recotrk/Item/Stream IDs to accredit for the CTA submission
        recotrk = queryString.recotrk;
        recoItemId = queryString.itm;
        recoCollectionId = queryString.col;
    } else if (recoCookie) {
        // Accredit the CTA submission to the latest recommended Item
        var recoCookieObj = JSON.parse(recoCookie);
        recotrk = recoCookieObj.recotrk;
        recoItemId = recoCookieObj.item_id;
        recoCollectionId = recoCookieObj.collection_id;
    }

    var conversionItemId = Hubs.appInstance.currentItemId;
    var conversionCollectionId = Hubs.appInstance.currentCollectionId;

    if (conversionItemId) {
        extraParams.push({
            id: 'uf_conversion_item_id',
            value: conversionItemId
        });
    }

    // Send the query parameters stored from app initialization with cta
    $.each(queryString, function(idx, val){
        extraParams.push({id: decodeURIComponent(idx), value: decodeURIComponent(val)});
    });

    this.setSubmitSuccessState(ctas);

    Shared.$('body').removeClass('noscroll');
    $.each(Hubs.Config.knownUser, function(idx, val){
        if (val) {
            knownUser = true;
        }
    });

    // Need to use standard jQuery ajax call as we're cancelling ajaxFetch call when swapping pages
    $.ajax({
        url: Shared.absoluteHubUrl(Hubs.Config.serverUrl.ctaSubmit),
        type: 'POST',
        data: {
            'cta': ctas.ctaID,
            'col': conversionCollectionId,
            'itm': conversionItemId,
            'prevCol' : Hubs.appInstance.hubState.previousState.get('collectionId'),
            'prevItm' : Hubs.appInstance.hubState.previousState.get('itemId'),
            'extraParams': extraParams,
            'formData': formFieldData,
            'pageViews': JSON.stringify(pageViews),
            'recotrk' : recotrk,
            'recoItemId': recoItemId,
            'recoCollectionId': recoCollectionId,
            'csrf_token': ctas.current.container.find('[name=csrf_token]').val(),
        },
        success: $.proxy(function(data){
            ctas.current.loading.addClass('hidden');

            // After this we might know who the user is
            Hubs.Config.knownUser = data.response.knownUser;
            Object.seal(Hubs.Config.knownUser);

            // Reset page views
            localStorage.setItem(integrationService,  JSON.stringify({"items":[]}));

            // Track event
            if (typeof data.response.event != 'undefined') {
                Shared.Tracker.trackEvent(data.response.event.category, data.response.event.action, data.response.event.label);
            }

            // BrightInfo Integration tracking details
            // Format is:
            //  _BI.sendRegistrationDetails(url, name, firstName, lastName, email, company)
            // Where url and email are manditory and rest are not
            // We don't have the rest of the data all the time and can't tell what is name fields
            // since the field names change so email + url will do
            if (Hubs.root._BI !== undefined && email) {
                Hubs.root._BI.sendRegistrationDetails(location.href, null, null, null, email, knownUser);
            }

            // External Callback - Call Event Handler passing CTA ID, data, name and Selector
            if (typeof Hubs.onCtaFormSubmitSuccess === 'function') {
                Hubs.onCtaFormSubmitSuccess.apply(this, [ctaID, mappedData, ctaName]);
            }

            // emit hub event
            Hubs.Events.trigger('ctaFormSubmitSuccess', [ctaID, mappedData, ctaName, ctas.current.container]);

            this.embeddedScrollData.cta_b.f = false;
        }, this),
        error: $.proxy(function (xhr){
            ctas.current.loading.addClass('hidden');

            // Remove user from list if there was an error
            this.reverseStoreCta(ctas.listID);

        }, this)
    });

    // Return false to not follow a link
    return false;
};

function toggleFieldDisplay(self, conditions, $formFieldsWrapper, $actionField) {
    var showField = self.verifyFieldsConditions(conditions, $formFieldsWrapper);
    if(showField) {
        $actionField.parent(".cta-field-section").removeClass("hide");
    } else {
        $actionField.parent(".cta-field-section").addClass("hide");
        $actionField.val('');
    }
}

Hubs.CTAs.prototype.processFormFieldConditions = function ($formFieldsWrapper, data) {
    var self = this;
    if ($formFieldsWrapper.find('[data-conditional="1"]').length > 0) {

        $formFieldsWrapper.find('[data-conditional="1"]').each(function() {
            var $actionField = $(this);
            var actionFieldId = $actionField.data("field-id");

            if (data.response.fieldConditionsObj) {
                var fieldConditions = _.find(data.response.fieldConditionsObj, {'field_id': actionFieldId});

                var conditionLength = fieldConditions.condition_data.length;
                var conditions = fieldConditions.condition_data;

                for (var i = 0; i < parseInt(conditionLength); i++) {
                    var conditionalFieldId = conditions[i].conditional_field_id;
                    var $conditionalField = $formFieldsWrapper.find('.preview-form-field[data-field-id="'+conditionalFieldId+'"]');

                    toggleFieldDisplay(self, conditions, $formFieldsWrapper, $actionField);
                    refreshScrollbar($actionField.parents('.cta'));
                    $conditionalField.on("change", function() {
                        toggleFieldDisplay(self, conditions, $formFieldsWrapper, $actionField);
                        refreshScrollbar($actionField.parents('.cta'));
                    });
                }
            }
        });
    }
};

Hubs.CTAs.prototype.getFormFieldConditions = function ($obj) {
    var ctaId = $obj.data('ctaId');
    var $formFieldsWrapper = $obj.closest('form').find('.form-fields');
    $.ajax({
        url: Shared.absoluteHubUrl(Hubs.Config.serverUrl.getFormFieldConditions),
        type: 'POST',
        data: {'cta': ctaId},
        success: $.proxy(function(data){
            if (Hubs.isMobile()) {
                $formFieldsWrapper = $('#mobile-cta-overlay .form-fields');
            }
            // get all conditional fields
            this.processFormFieldConditions($formFieldsWrapper, data);
        }, this)
    });
};

/**
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.activateCtaForm = function(e) {
    var $obj = $(e.currentTarget);
    var ctaId = $obj.data('ctaId');
    var itemId = Hubs.appInstance.currentItemId;

    if (!ctaId) {
        // We want to warn devs (inc onbrand) if they are accidentally triggering a cta-activate on a non cta,
        // or if their custom code has broken regular CTA behaviour.
        console.warn('Element ' + e.currentTarget + ' has no data-cta-id, cannot activate.', e.currentTarget);

        // try to find and clean-up CTA:
        $obj.parents('.cta.item').hide();
        $(Hubs.Config.cta.elements.hiddenEmbed).removeClass('hide-embed');
        $(Hubs.Config.cta.elements.blockingCta).removeClass('blocking-cta');
        return;
    }

    Hubs.Events.trigger('ctaActivateUfaCollector', [ctaId, $obj.parents('.cta-form')]);

    var $formFieldsWrapper = $obj.closest('form').find('.form-fields');
    var isUserTrackingMaService = !$formFieldsWrapper.data('formIsActive');

    /*
        * (boolean) isUserTrackingMaService:
        *
        *  - for MA Services that use tracking, will make an API Request to update Form CTA
        *    fields (so that the fields are populated with user-filled data, eg. Progressive
        *    Profiling, known/tracked user, etc),
        *
        *  - Services that do not require field values to be updated: MailChimp, Custom Forms.
        */
    if (isUserTrackingMaService) {
        $.ajax({
            url: Shared.absoluteHubUrl(Hubs.Config.serverUrl.ctaActivate),
            type: 'POST',
            data: {
                'cta': ctaId,
                'itemId': itemId,
            }
        }).done($.proxy(function(data) {
            if (data.response && data.response.data) {
                $formFieldsWrapper.html(data.response.data);
                // If no fields are showing (due to progressive profiling), show just the email
                if ($formFieldsWrapper.find('.cta-field-section:not(.hide):not(.opt-in-section)').length === 0){
                    $formFieldsWrapper.find('.cta-field-section.email-field').removeClass('hide');
                }
                this.moveCtaFormUp($obj);

                if (Hubs.isMobile()) {
                    $formFieldsWrapper = $('#mobile-cta-overlay .form-fields');
                }

                this.processFormFieldConditions($formFieldsWrapper, data);

                // External Callback - Call Event Handler passing CTA ID
                if (typeof Hubs.onCtaActivate === 'function') {
                    Hubs.onCtaActivate.apply(this, [ctaId]);
                }

                // Emit ctaActivate hub event
                Hubs.Events.trigger('ctaActivate', [ctaId, $obj.parents('.cta-form')]);
            }

            // Update MAP users
            if (data.response && data.response.knownUser) {
                Hubs.Config.knownUser = data.response.knownUser;
                Object.seal(Hubs.Config.knownUser);
            }
        }, this))
        .fail($.proxy(function() {
            // The request failed, but we want to remove any block CTAs, etc.
            var ctas = getCtasObject($obj);

            if (ctas.current.isFullscreen) {
                ctas.current.container.remove();
                Shared.scrollTop(ctas.currentPos);

                // Allow Scrolling on Body
                Hubs.restoreBodyScroll();
            }

            // If was a blocking cta, remove the blocking class and hide it
            if (ctas.isBlocking) {
                $(Hubs.Config.cta.elements.blockingCta).removeClass('blocking-cta');
                $(Hubs.Config.cta.elements.blockCta).hide();

                // if this CTA was blocking on top of a fullscreen flipbook, restore the fullscreen status after dismissed
                this.expandAfterCta();
            }

            // If there is a hidden embed (IE: Flipbook), make sure it becomes visible
            if (ctas.hidesHiddenEmbed) {
                $(Hubs.Config.cta.elements.hiddenEmbed).removeClass('hide-embed');
            }

            // Everything is cleaned up, get rid of the broken CTA
            ctas.current.container.hide();
        }, this));
    } else {
        this.getFormFieldConditions($obj);
        this.moveCtaFormUp($obj);
    }
};

/**
 * Sets the success state on the client side for a successful cta submission
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.setSubmitSuccessState = function(ctas){
    if(ctas.current.isFullscreen){
        ctas.current.container.remove();
        Shared.scrollTop(ctas.currentPos);

        // Allow Scrolling on Body
        Hubs.restoreBodyScroll();
    }

    // Show the success screen
    ctas.all.container.css('background-color', ctas.all.success.css('background-color'));
    ctas.all.top.css('display', 'none');
    ctas.all.bottom.css('display', 'none');
    ctas.all.success.css('display', 'block');

    // If was a blocking cta, remove the blocking class and hide it
    if(ctas.isBlocking){
        $(Hubs.Config.cta.elements.blockingCta).removeClass('blocking-cta');
        $(Hubs.Config.cta.elements.blockCta).hide();

        // if this CTA was blocking on top of a fullscreen flipbook, restore the fullscreen status after dismissed
        this.expandAfterCta();
    }

    // If there is a hidden embed, make sure it is visible
    if (ctas.hidesHiddenEmbed) {
        $(Hubs.Config.cta.elements.hiddenEmbed).removeClass('hide-embed');
    }

    // If there are not more fields, then store the cta so we won't see it again (based on listID)
    if (!ctas.hasMoreFields) {
        this.storeCta({listID: ctas.listID, ctaID: ctas.ctaID});
    }

    return true;
};

/**
 * Adds a cta to local storage
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.storeCta = function(props) {
    var storageString = Hubs.Config.cta.storage + Hubs.Config.hubId,
        storageValue,
        storeCta = {'listID': props.listID, 'ctaID': props.ctaID};

    storageValue = localStorage.getItem(storageString);

    // Set up the object
    if(storageValue === null){
        storageValue = {'cta': [], 'placement': []};
    } else {
        storageValue = JSON.parse(storageValue);
    }

    storageValue.cta.push(storeCta);
    localStorage.setItem(storageString, JSON.stringify(storageValue));
};

/**
 * Removes a cta from local storage
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.reverseStoreCta = function(listID) {
    var storageString = Hubs.Config.cta.storage + Hubs.Config.hubId,
        storageValue,
        found = false;

    storageValue = localStorage.getItem(storageString);

    // Set up the object
    if(storageValue === null){
        return found;
    }

    storageValue = JSON.parse(storageValue);

    $.each(storageValue.cta, function(idx, val){
        if(val.listID == listID){
            storageValue.cta.splice(idx, 1);
            found = true;
            return false;
        }
    });

    localStorage.setItem(storageString, JSON.stringify(storageValue));

    return found;
};

/**
 * Adds a placement to local storage for hiding purposes
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */

Hubs.CTAs.prototype.storePlacement = function(props) {
    var storageString = Hubs.Config.cta.storage + Hubs.Config.hubId,
        storageValue,
        storeCta = {'placementID': props.placementID, 'hideUntil': props.hideUntil};

    storageValue = localStorage.getItem(storageString);

    // Set up the object
    if(storageValue === null){
        storageValue = {'cta': [], 'placement': []};
    } else {
        storageValue = JSON.parse(storageValue);

        // Placements were added after, create the object property if necessary
        if(!storageValue.placement){
            storageValue.placement = [];
        }
    }

    storageValue.placement.push(storeCta);
    localStorage.setItem(storageString, JSON.stringify(storageValue));
};

/**
 * Shows non submitted form ctas
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.showFormCtas = function(lazyLoader, pageType) {
    var self = this,
        $container = $(Hubs.Config.cta.elements.container),
        storageString = Hubs.Config.cta.storage + Hubs.Config.hubId,
        storageValue = localStorage.getItem(storageString),
        besideExists = false,
        hideListIds = [],
        hideCtaIds = [],
        hidePlacementIds = [],
        initialDelay = 0,
        parsedValue = JSON.parse(storageValue),
        backUpValue = {cta: [], placement: []},
        resetValue = false,
        hiddenBlockingCta = 0;

    if (storageValue === null) {
        // Make sure there are some fields showing
        $.each($container, function(){
            var $el = $(this);

            if($el.parent().hasClass('block-cta')){
                if($(Hubs.Config.cta.elements.blockCta + ' ' + Hubs.Config.cta.elements.container).attr('data-init-delay')){
                    initialDelay = parseInt($(Hubs.Config.cta.elements.blockCta + ' ' + Hubs.Config.cta.elements.container).attr('data-init-delay'), 10) * 1000;
                    Hubs.CTAs.initialDelayCTA = Hubs.root.setTimeout(function(){
                        $(Hubs.Config.cta.elements.possibleBlock).addClass('blocking-cta');
                        $(Hubs.Config.cta.elements.possibleHiddenEmbed).addClass('hide-embed');

                        self.repositionBlockingCTA($(Hubs.Config.cta.elements.blockCta));

                        self.unexpandForCta();

                        Hubs.CTAs.showCta($(Hubs.Config.cta.elements.blockCta), true);
                    }, initialDelay);
                } else {
                    $(Hubs.Config.cta.elements.possibleBlock).addClass('blocking-cta');
                    $(Hubs.Config.cta.elements.possibleHiddenEmbed).addClass('hide-embed');

                    Hubs.CTAs.showCta($(Hubs.Config.cta.elements.blockCta));

                    self.unexpandForCta();
                }
            }

            // Show the CTA
            Hubs.CTAs.showCta($el);
        });

        return hiddenBlockingCta;
    }

    // Go through the storage of ctas and hide the submitted ones
    $.each(parsedValue.cta, function(){
        hideListIds.push(this.listID);
        hideCtaIds.push(this.ctaID);
        backUpValue.cta.push(this);
    });


    // Placement may not exist yet as it was added later
    if(parsedValue.placement){
        $.each(parsedValue.placement, function(){
            if(this.hideUntil && new Date().getTime() > this.hideUntil){
                resetValue = true;
            } else {
                hidePlacementIds.push(this.placementID);
                backUpValue.placement.push(this);
            }
        });

        // If the placement timeout is now done, reset the value to know for next time
        if(resetValue){
            localStorage.setItem(storageString, JSON.stringify(backUpValue));
        }
    }

    $.each($container, function(){
        var $el = $(this), ctaCompleted = false, initialDelay = 0;

        // Custom CTAs don't have lists - for now
        if($el.attr('data-integration') != 'form_cta_type'){
            $.each(hideListIds, function() {
                if(this.toString() === $el.attr('data-list-id')){
                    ctaCompleted = true;
                    return false;
                }
            });
        } else {
            $.each(hideCtaIds, function() {
                if(this.toString() === $el.attr('data-cta-id')){
                    ctaCompleted = true;
                        return false;
                }
            });
        }

        $.each(hidePlacementIds, function() {
            if(this.toString() === $el.attr('data-placement-id')){
                ctaCompleted = true;
            }
        });

        if($el.attr('data-no-hide')){
            ctaCompleted = false;

            // Show a message if "show success message" option turned on
            if ($el.attr('data-show-success-message') && $.inArray($el.attr('data-cta-id'), hideCtaIds) != -1) {
                $el.find(Hubs.Config.cta.elements.formTop).hide();
                $el.find(Hubs.Config.cta.elements.formSuccess).show();
            }
        }

        // If no hidden blocking cta, the current cta is blocked, and current cta is a blocking cta: we found a hidden blocking cta
        if(!hiddenBlockingCta && ctaCompleted && $el.parent().hasClass('block-cta')){
            hiddenBlockingCta = 1;
        }

        // CTA HTML is always injected to the DOM; The default state is hidden. Here we unhide the cta if its not
        // found in localStorage (ctas are stored in localStorage after all fields are completed for a user)
        if(!ctaCompleted){
            // If not on the list, but no fields are showing (due to progressive profiling), show just the email
            // to allow the user to still opt in to the cta
            if($el.find('.cta-field-section:not(.hide):not(.opt-in-section)').length === 0){
                $el.find('.cta-field-section.email-field').removeClass('hide');
            }

            $el.removeClass('hide');

                // If CTA has setting "Never hide this CTA", make sure we unexpand the Fullscreen Mode
                if ($el.attr('data-no-hide')) {
                    self.unexpandForCta();
                }

            if($el.parent().hasClass('block-cta')){
                if($el.attr('data-init-delay')){
                    initialDelay = parseInt($el.attr('data-init-delay'), 10) * 1000;
                    Hubs.CTAs.initialDelayCTA = setTimeout(function(){
                        $(Hubs.Config.cta.elements.possibleBlock).addClass('blocking-cta');
                        $(Hubs.Config.cta.elements.possibleHiddenEmbed).addClass('hide-embed');

                        self.repositionBlockingCTA($(Hubs.Config.cta.elements.blockCta));

                        self.unexpandForCta();

                        Hubs.CTAs.showCta($el.parent(), true);
                    }, initialDelay);
                } else {
                    $(Hubs.Config.cta.elements.possibleBlock).addClass('blocking-cta');
                    $(Hubs.Config.cta.elements.possibleHiddenEmbed).addClass('hide-embed');
                    self.unexpandForCta();
                    Hubs.CTAs.showCta($el.parent());
                }
            } else if($el.parent().hasClass('cta-item-container')){
                besideExists = true;
                // Mark any visible CTAs as ones that need a view tracked
                Hubs.CTAs.markCtasForViewTracking();
            } else {
                // Not a beside or blocking CTA, we're on level 1
                // Mark any visible CTAs as ones that need a view tracked
                Hubs.CTAs.markCtasForViewTracking();
            }
        }
    });

    // Only need to call the lazy loader if it's a not an item
    if (pageType !== Hubs.PAGE_TYPE_ITEM && lazyLoader) {
        lazyLoader.updateSizes();
    }

    if (pageType === Hubs.PAGE_TYPE_ITEM && !besideExists) {
        $('.item-contents-with-cta').removeClass('item-contents-with-cta');
        $('.with-cta').removeClass('with-cta');
        $('.cta-item-container').remove();
    }

    return hiddenBlockingCta;
};

/**
 * Reposition Blocking CTAs so that they are in View
 *
 * @public
 * @this Hubs.CTAs
 * @return undefined
 */
Hubs.CTAs.prototype.repositionBlockingCTA = function($ctaElement) {
    var ctaTop = 0;
    if (!$ctaElement.length) { return; }

    if ($(window).height() < Hubs.SMALL_SCREEN_HEIGHT) {
        //if (Shared.IOS) {
        //    ctaTop = $(Hubs.Config.containers.topNav).outerHeight(true);
        //    $ctaElement.css({'position': 'fixed', 'top': ctaTop, 'margin-top': 0});
        //} else {
            ctaTop = Shared.scrollTop();
            $ctaElement.css({'position': 'absolute', 'top': ctaTop, 'margin-top': 0});
        //}
    }
};

Hubs.CTAs.prototype.verifyFieldsConditions = function(conditions, $formFieldsWrapper) {
    var conditionLength = conditions.length;

    for (var i = 0; i < conditionLength; i++) {
        var conditionalFieldId = conditions[i].conditional_field_id;
        var conditionalFieldValue = conditions[i].value;
        var conditionalOperator = conditions[i].operator;

        var $conditionalField = $formFieldsWrapper.find('.preview-form-field[data-field-id="' + conditionalFieldId + '"]');

        // if the field type is not checkbox or select return false
        var elementType = $conditionalField.prop('tagName');
        if (!(elementType === 'INPUT' && $conditionalField.prop('type') === 'checkbox') && elementType !== 'SELECT') {
            return false;
        }

        var changedVal;

        if ($conditionalField.prop('type') === 'checkbox') {
            changedVal = $conditionalField.is(':checked') ? 1 : 0;
        } else {
            changedVal = $conditionalField.val();
        }

        if ((changedVal == conditionalFieldValue && conditionalOperator == 'EQUAL_TO') || (changedVal != conditionalFieldValue && conditionalOperator == 'NOT_EQUAL_TO')) {
            return true;
        } else {
            // check for other fields in conditional field before taking action
            // if anyone of them is true don't hide the field
            var conditionsLeft = conditions.filter(function(val,index){return index !== i;});

            if (conditionsLeft.length > 0 && this.verifyFieldsConditions(conditionsLeft, $formFieldsWrapper)) {
                return true;
            } else {
                return false;
            }

        }

    }
};
