import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core.js';
import UfaCaller from '../ufa/UfaCaller';
import Modernizr from 'modernizr';
import { parse } from 'uri-js';
import * as recoMetrics from './reco_metrics';
import Privacy from './privacy';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import SessionVariable from './stateWrappers/sessionVariable.js'; // es6+ & babelified
import CookieVariable from './stateWrappers/cookieVariable.js'; // es6+ & babelified
import { augmentJquery, wrapHistory } from './legacyCodePollyfills/legacyCodePollyfills';
import { ufa } from '@uberflip/ufa-client';

// Expose ufa global on hubs
window.ufa = ufa;

// touch test for backwards compatibility
Modernizr.addTest('touch', () => {
  let bool;
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {bool = true;} else {
    const query = ['@media (', Modernizr._prefixes.join('touch-enabled),('), 'heartz', ')', '{#modernizr{top:9px;position:absolute}}'].join('');
    Modernizr.testStyles(query, node => {bool = node.offsetTop === 9;});
  }
  return bool;
});
window.Modernizr = Modernizr;

// Add event triggers to our history object
// Event hooks used to control page change logic
wrapHistory(window.self.history);
window.$ = window.jQuery = $;

// Augment jQuery with functionality previously supplied by:
// -- jquery.cookie
// -- jquery.fitvids
// -- jquery.perfectScrollbar
// For backawards compatibility with custom code
augmentJquery.withAllPollyfills($);

/**
 * adds jquery.fn.imagesLoaded to jquery, allowing you to listen for image load events
 */
imagesLoaded.makeJQueryPlugin( $ );
window._ = _;
window.imagesLoaded = imagesLoaded;

require('../../../libs/promise/q.js');
require('../../../libs/timeago/timeago.js');
require('../../../libs/mbp/js/helper.js');
require('../../../libs/iscroll4/iscroll.js');
require('../../../libs/jgestures/jgestures.js');
require('../../../libs/iframeResizer/iframeResizer.contentWindow.js');

require('./core.js');
require('./shared_constants.js');
require('./constants.js');
require('./config.js');
require('./shared_util.js');
require('./util.js');
require('./logger.js');
require('./tracker.js');
require('./carousel.js');
require('./cta.js');
require('./responsive.js');
require('./recommendations.js');
require('./embedded.js');
require('./lazyloader.js');
require('./search.js');
require('./privacy_helpers.js');

Hubs.SessionVariable = SessionVariable;
Hubs.CookieVariable = CookieVariable;
Hubs.url = { parse };
Hubs.recoMetrics = recoMetrics;
Hubs.UfaCaller = UfaCaller;
Hubs.Privacy = Privacy;

require('./app.js');
