import { getDisplayProperty, isCapturedInSiteEngager } from './utils/displayProperties';
import 'jquery-migrate';
import $ from 'jquery';

const getCtaFormProperties = (ctaId, ctaTile) => {
  const $el = $(ctaTile);
  const isBlocking = $el.parent().hasClass('block-cta');

  return {
    ctaId,
    display: getDisplayProperty($el),
    isBlocking,
    isCapturedInSiteEngager: isCapturedInSiteEngager($el),
    isEmbedded: $('body').hasClass('cta-embed'),
    isForm: $el.hasClass('cta-form'),
    isSide: $el.parents('#item-content').hasClass('with-cta') && !isBlocking,
    ordinal: Array.from($el[0].parentElement.children).indexOf($el[0]),
  };
};

export default getCtaFormProperties;
