import 'jquery-migrate';
import $ from 'jquery';
import { debounce } from 'lodash';

let batchedItems = {};

// on scroll/resize/touch events batch items together
const collect = e => {
    const { recoItems } = e.data;
    recoItems.filter(item => item.isVisible && !item.impressed).forEach(item => batchedItems[item.item_id] = item);
};

// wait 600ms after stop scrolling - send batched items to the api (register impressions)
const handleEvent = debounce(event => {
    const objectValues = Object.keys(batchedItems).map(e => batchedItems[e]);
    checkItems(objectValues, event.data.recotrk);
    batchedItems = {};
}, 600);


export function initialRecoMetrics(isCarousel) {
    const $window = $(window);

    // the same recotrk for one set of recommedations/page re-load
    let recotrk = '';
    // recommended items wrapper
    let $wrapper = {};

    if (isCarousel) {
        $wrapper = $('#related-items-carousel .tile');
        recotrk = $('#related-items-carousel .item-link').first().data('recotrk');
    } else { //panel view
        recotrk = $('.reco-panel-items .item-link').first().data('recotrk');
        $wrapper = $('.reco-panel-items .tile');
    }

    // all recommendations (items) either inside panel or carousel
    let recoItems = $wrapper.toArray().map(
        item => {
            return {
                item: item,
                item_id: item.dataset.id,
                collection_id: item.dataset.sourceStreamId,
                visual: item.getElementsByClassName("item-link")[0].dataset.visual,
                get isVisible() {
                 return isVisible(item);
                },
                impressed: false
            };
        }
    );

    // register impressions for any items that are visible on load
    let onLoadItems = recoItems.filter(item => item.isVisible && !item.impressed);
    checkItems(onLoadItems, recotrk);

    // data to send to scroll/resize/etc. events
    let data = {recoItems: recoItems, recotrk: recotrk};

    //carousel view
    if (isCarousel) {
        let $carousel = $('#related-items-carousel');
        let nextRecommendationLink = document.querySelectorAll('#recommendation-button-wrapper a')[0];

        $carousel.on('scroll', data, collect);
        $carousel.on('scroll', data, handleEvent);

        $window.on('scroll', data, collect);
        $window.on('scroll', data, handleEvent);


        const {sourceStreamId, itemId, visual } = nextRecommendationLink.dataset;

        const nextRecoLinkData = [{
            hub_id: Hubs.Config.hubId,
            collection_id: sourceStreamId,
            item_id: itemId,
            visual: visual
        }];
        // next recommendation link impression
        if (isVisible(nextRecommendationLink)) {
            registerImpressions(nextRecoLinkData, recotrk);
        } else {
            const registerNextItem = () => {
                if (isVisible(nextRecommendationLink)) {
                    registerImpressions(nextRecoLinkData, recotrk);
                    $window.unbind('scroll', registerNextItem);
                }
            }

            $window.on('scroll', data, registerNextItem);
        }

        // carousel sliding events
        $window.on('carousel.sliding carousel.stoppedSliding', data, collect);
        $window.on('carousel.stoppedSliding', data, handleEvent);

    } else { // panel view
        const $panel = $('.reco-panel');

        $panel.on('scroll', data, collect);
        $panel.on('scroll', data, handleEvent);

        // In case of customized side panel without scroll bar
        $window.on('scroll', data, collect);
        $window.on('scroll', data, handleEvent);
    }

    // shared between panel and carousel views
    $window.on('resize', data, collect);
    $window.on('resize debouncedresize', data, handleEvent);
}

function checkItems(recoItems, recotrk) {
    if (recoItems.length) {
        registerImpressions(recoItems, recotrk);
    }
}

function registerImpressions(recoItems, recotrk) {
    const urlBase = Hubs.Config.externalApiUrl;

    $.ajax({
        type: 'POST',
        url: `${urlBase}/recommendations/${recotrk}/impressions`,
        data: {
            data: recoItems.map(item => ({
                hub_id: Hubs.Config.hubId,
                stream_id: item.collection_id,
                item_id: item.item_id,
                visual: item.visual}))
        }
    }).done(() => recoItems.forEach(item => {item.impressed = true;}));
}

function isVisible (elem) {
    // Get element's bounding
    const bounding = elem.getBoundingClientRect();

    // covering the case when element is present on the page, but has display: none css
    if (bounding.height === 0 || bounding.width === 0 ) {
        return false;
    }
    // Check if it's out of the viewport on each side
    const out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);

    const visible = !out.top && !out.left && !out.bottom && !out.right;

    // for carousel only
    const parent = document.getElementById('related-items-carousel');

    // if element is visible in viewport and it a child of carousel
    if (visible && parent && $.contains(parent, elem)) {
        const maxLeftSide = $(parent).offset().left;
        const maxRightSide = maxLeftSide + $(parent).width();

        // check if element is also visible inside the parent element
        if (bounding.left < maxLeftSide || bounding.right > maxRightSide) {
            return false;
        }
    }

    return visible;
}
