import { getDisplayProperty, isCapturedInSiteEngager } from './utils/displayProperties';
import 'jquery-migrate';
import $ from 'jquery';

const tile = {
  name: 'itemTile',
  selectors: ['.tile:not(.cta):not(#loading-notifier)'],
  transformerCallback: (el) => ({
    display: getDisplayProperty(el),
    id: el.dataset.id,
    isCapturedInSiteEngager: isCapturedInSiteEngager(el),
    isHighlighted: $(el).hasClass('highlight'),
    ordinal: Array.from(el.parentElement.children).indexOf(el),
  }),
};

const nextPrev = {
  name: 'itemTile',
  selectors: ['.item-next-prev a.hooked'],
  transformerCallback: (el) => ({
    display: 'nextPreviousCarousel',
    id: el.dataset.itemId,
    isCapturedInSiteEngager: isCapturedInSiteEngager(el),
    isHighlighted: $(el).hasClass('highlight'),
    ordinal: Array.from(el.parentElement.children).indexOf(el),
  }),
};

const cta = {
  name: 'ctaTile',
  selectors: ['.tile.cta'],
  transformerCallback: (el) => {
    const $el = $(el);
    const isBlocking = $el.parent().hasClass('block-cta');

    return {
      display: getDisplayProperty(el),
      id: el.dataset.ctaId,
      isBlocking,
      isCapturedInSiteEngager: isCapturedInSiteEngager(el),
      isEmbedded: $('body').hasClass('cta-embed'),
      isForm: $el.hasClass('cta-form'),
      isHighlighted: $(el).hasClass('highlight'),
      isSide: $el.parents('#item-content').hasClass('with-cta') && !isBlocking,
      ordinal: Array.from(el.parentElement.children).indexOf(el),
    };
  },
};

const getImpressionTrackingRules = () => ({
  impressionTracking: {
    rules: [tile, cta, nextPrev],
  },
});

export default getImpressionTrackingRules;
