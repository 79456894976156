import '../../../libs/timeago/timeago.js';
import 'jquery-migrate';
import $ from 'jquery';
import Hubs from './core';
import Shared from './shared_util';

Hubs.lastKnownBodyScroll = false;

/**
 * Checks if the device is considered a Mobile device
 *
 * @public
 * @this local
 * @return {Boolean} True if the device is a Mobile device
 */
Hubs.isMobile = function() {
    return Hubs.Config.isMobile;
};

/**
 * Checks if the Hub is viewed within an IFrame
 *
 * @public
 * @this local
 * @return {Boolean} True if the Hub is within an IFrame
 */
Hubs.isIFrame = function() {
    return Hubs.Config.isIFrame;
};

/**
 * Checks if the Hub is Embedded within an IFrame
 *
 * @public
 * @this local
 * @return {Boolean} True if the Hub is Embedded within an IFrame
 */
Hubs.isEmbedded = function() {
    return Hubs.Config.isEmbedded;
};

/**
 * Ensure the eloquatk cookie is updated for the visitor and ping the server to populate the known user cache
 *
 * @public
 * @this local
 * @return {String} Eloqua tracking GUID if found, null otherwise.
 */
Hubs.registerEloquaVisitor = function() {
    var updateEloquaVisitor = function() {
        var guid = Hubs.getEloquaVisitorGUID();

        if (guid) {
            if (Hubs.getEloquaUTKValue() !== guid) {
                Hubs.setEloquaUTKValue(guid);

                // Causes 'known user' cache to be updated with Eloqua user data
                Shared.ajaxFetch(Hubs.Config.serverUrl.ping, null, 'GET', true, null, null);
            }
        }

        return guid;
    };

    if (updateEloquaVisitor() === null) {
        var intervalCount = 0;
        // 12 * 500ms = 6s max total wait time -- recommended by product team
        var maxIntervalCount = 12;
        var intervalTimeout = 500;

        var interval = setInterval(function () {
            intervalCount ++;
            // sometimes the eloqua request to set the customer GUID fails (or succeeds incorrectly)
            // here we tell the eloqua script to retry that request
            if (typeof window._elqQ !== 'undefined') {
                window._elqQ.push(['elqGetCustomerGUID']);
            }

            if (updateEloquaVisitor() !== null || intervalCount === maxIntervalCount) {
                clearInterval(interval);
            }
        }, intervalTimeout);
    }
};


/**
 * Retrieve an Eloqua visitor tracking GUID from a first or third party cookie
 *
 * @public
 * @this local
 * @return {String} Eloqua visitor tracking GUID if found, null otherwise.
 */
Hubs.getEloquaVisitorGUID = function() {
    var guid = null;

    if (Hubs.Config.eloquaFirstPartyCookies === 1) {
        var cookieName, cookieValue, equalsSignIndex;
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            equalsSignIndex = cookies[i].indexOf('=');

            if (equalsSignIndex > 0 && cookies[i].length > equalsSignIndex + 1) {
                cookieName = cookies[i].substr(0, equalsSignIndex).trim();

                if (cookieName === 'ELOQUA') {
                    cookieValue = cookies[i].substr(equalsSignIndex + 1);

                    var subCookies = cookieValue.split("&");

                    for (var l = 0; l < subCookies.length; l++) {
                        var subCookie = subCookies[l].split("=");

                        if (subCookie.length === 2 && subCookie[0] === 'GUID') {
                            guid = subCookie[1];
                        }
                    }
                }
            }
        }
    } else if (typeof window.GetElqCustomerGUID === 'function') {
        guid =  window.GetElqCustomerGUID();
    }

    return guid;
};

/**
 * Populate the Uberflip eloquautk cookie with an Eloqua tracking GUID
 *
 * @public
 * @this local
 * @param {String} guid - An Eloqua tracking GUID
 * @return undefined
 */
Hubs.setEloquaUTKValue = function(guid) {
    var date = new Date();
    date.setTime(date.getTime() + (60 * 60 * 24 * 1000 * 365));

    var expiry = "; expires=" + date.toUTCString();

    document.cookie = 'eloquautk' + '=' + guid + expiry + '; path=/';
};

/**
 * Get the value of the Uberflip eloquautk cookie
 *
 * @public
 * @this local
 * @return {String} The GUID value found in eloquautk, or null if not found
 */
Hubs.getEloquaUTKValue = function() {
    var cookieName, cookieValue, equalsSignIndex;
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
        equalsSignIndex = cookies[i].indexOf('=');

        if (equalsSignIndex > 0 && cookies[i].length > equalsSignIndex + 1) {
            cookieName = cookies[i].substr(0, equalsSignIndex).trim();

            if (cookieName === 'eloquautk') {
                cookieValue = cookies[i].substr(equalsSignIndex + 1);

                return cookieValue;
            }
        }
    }

    return null;
};

/**
 * Check if a given Marketing Automation Platform is connected on this Hub
 *
 * @public
 * @this local
 * @return {Boolean} True if the map is connected
 */
Hubs.mapIsConnected = function(map) {
    var integrationCount = Hubs.Config.mapIntegrations.length;

    for (var i = 0; i < integrationCount; i++) {
        if (Hubs.Config.mapIntegrations[i].toLowerCase() === map.toLowerCase()) {
            return true;
        }
    }

    return false;
};

/**
 * Scroll the Body Element to a Pixel-Based Coordinate
 *  - Standarized method for Scrolling that works in all Browsers/Devices
 *
 * @public
 * @this local
 * @param {Number} top - The amount of Pixels to Scroll the Element by; If not supplied, will return the Current Value of "scrollTop"
 * @param {Number} animateSpeed - The speed of the Animation to use; if not set no animation will be used
 * @param {Object} $el - a jQuery Element Handle to the Element to be Scrolled; Defaults to "window"
 * @return undefined
 */
Hubs.scrollTop = function(top, animateSpeed, $el) {
    return Shared.scrollTop(top, animateSpeed, $el);
};

/**
 * Resizes any Video Controls to fit within the Space provided by its container
 *
 * @public
 * @this local
 * @param {String} Optional param for the hubs page type
 * @return undefined
 */
Hubs.updateVideoSize = function(type) {
    // Vidyard or Vimeo page type should never try this code
    if (type !== undefined && (type === 'vidyard' || type === 'vimeo')) {
        return;
    }

    var $vids = $(Hubs.Config.containers.videoPlayer);
    if ($vids.length) { $vids.fitVids(); }
};

/**
 * Removes the Main Hub Sharing section if there are no Sharing Options enabled
 *
 * @public
 * @this local
 * @param {Object} hub The Hub object to get the sharing options from
 * @return undefined
 */
Hubs.toggleHubSharing = function(hub) {
    var countClass = '';

    // Get ClassName for Share Icons
    countClass = Hubs.getShareCountClass(hub);

    // If there is no sharing enabled, disable the Mobile Share Nav
    if (countClass === 'zero') {
        $('.share-toggle').remove();
    }
};

/**
 * Toggles the Height of an Element with an Unknown Height
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.toggleHeight = function(state, $content, defaultMaxHeight) {
    var newHeight = 0, maxHeight = 0;

    if (defaultMaxHeight === undefined) {
        defaultMaxHeight = 0;
    }

    if (state) {
        // Store Old Max-Height of Section Content
        maxHeight = parseInt($content.css('max-height'), 10) || defaultMaxHeight;
        $content.data('max-height', maxHeight);

        // Calculate New Height to Expand to
        newHeight = $content.prop('scrollHeight');

        // Expand Height
        $content.css({'max-height': newHeight});
    } else {
        // Restore Height
        $content.css({'max-height': $content.data('max-height')});
    }
};

/**
 * Closes any open menus in the Desktop and Mobile Navigation Menus
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.closeOpenMenus = function() {
    var $expandedItems = Shared.$('.mobile-nav').find('li.expanded');

    // Close any open menus
    Shared.$(Hubs.Config.containers.leftNav[2]).find('li.collapsed').removeClass('hover');
    $expandedItems.each(function() {
        var $el = $(this);
        Hubs.toggleHeight(false, $el.find('.collapsable-section'));
        $el.removeClass('expanded').addClass('collapsed');
    });
    Shared.$('.mobile-nav').hide();
    $('.share-hub, .share-item').removeClass('hover');
};

/**
 * Updates the Page-Width Element with appropriate classnames
 *   - Updates the classnames of the page-width div in the top-nav to be the same as the
 *     page-width div in the content of the page
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.updatePageWidthElement = function(pageType) {
    var $headerContent = $('header .page-width');
    var $bodyContent = $('#hubs-container .page-width');

    if (!$headerContent.length || !$bodyContent.length) { return; }
    $headerContent.removeClass().addClass($bodyContent.attr('class'));
};

/**
 * Positions the Left Nav for Desktop and Tablet Devices
 *     - ensures that the menu is centered vertically within the view when larger than the viewport
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.positionLeftNav = function() {
    var $leftNav = Shared.$(Hubs.Config.containers.leftNav[2]),
        $hoveredEl = $leftNav.find('.hover'),
        $hoveredList, ny, ly, lh, ot,
        vph = Shared.$('window').height();

    // Fix Position
    if ($hoveredEl.length) {
        // Reset Position
        $hoveredList = $hoveredEl.find('> ul');
        $hoveredList.css({'top': 0});

        ly = 0 - Shared.$('window').scrollTop();
        lh = $hoveredList.outerHeight();
        ot = $hoveredList.offset();
        if (ot && ot.top !== undefined) {
            ly += ot.top;
        }

        // Fit in Viewport
        if (lh >= vph) {
            lh = vph - 40;
            $hoveredList.height(lh).css({'overflow': 'auto'});
        }

        // Position in Vertical Center of Screen
        if (ly + lh + 10 > vph) {
            ny = ly - ((vph - lh) / 2);
            $hoveredList.css({'top': -ny});
        }
    }
};

/**
 * Positions the Sharing Nav for Desktop and Tablet Devices
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.positionShareNav = function() {
    var $shareNav = Shared.$('.share-hub'),
        $shareBtn = Shared.$('.share-toggle');
    if (!$shareNav.length || !$shareBtn.length) { return; }
    if ($('.share-item').length) { $shareNav = $('.share-item'); }
    if (!Hubs.isEmbedded()) {
        $shareNav.css({'left': $shareBtn.offset().left + $shareBtn.outerWidth() - $shareNav.outerWidth()});
    } else {
        $shareNav.css({
            'top' : $shareBtn.offset().top + $shareBtn.outerHeight() + 7,
            'left': $shareBtn.offset().left + $shareBtn.outerWidth() - $shareNav.outerWidth()
        });
    }
};

/**
 * Updates the Size of Images in Tiles after the Image has Loaded
 *
 * @public
 * @this local
 * @param {Object} app A reference to the Application Object
 * @param {String} container The selector of the Container to fix images within
 * @return undefined
 */
Hubs.updateImageTiles = function(app, container) {
    var imgLoad = imagesLoaded(container);

    imgLoad.on('progress', function(instance, image) {
        var $img = null;
        if (!image.isLoaded) { return; }

        $img = $(image.img);

        // Weird stuff happens in IOS chrome where width is loaded and height isn't
        // Attaching an onload event seems to work
        if($img.height() <= 0 && $img.width() > 0){
            image.img.onload = function() {
                app.resizeImageTile(image.img, function() {
                    $img.parent().addClass('visible');
                });
            };
        } else {
            app.resizeImageTile(image.img, function() {
                $img.parent().addClass('visible');
            });
        }
    });

    // Ensure All Images are Visible
    imgLoad.on('always', $.proxy(function(instance, image){
        $(container + ':not(.visible)').addClass('visible');
    }, this));
};

/**
 * Fixes Large Content Images by removing any specified Height/Width attributes or styles
 *     - Checks if the image is larger than a specific size, and adds a classname to help styling
 *
 * @public
 * @this local
 * @param {String} type The Type of Page to be displayed
 * @param {String} container The ID of the Container to fix images within
 * @return undefined
 */
Hubs.fixContentImages = function(type, container) {
    var $container = $(container);
    if (!$container.length) { return; }

    // Fix content imags for Blogs
    if (/blogs|blogpost/i.test(type)) {
        $container.find('.entry img').each(function(idx, el) {
            var $el = $(el);
            if ($el.width() > Hubs.LARGE_IMAGE_WIDTH || $el.height() > Hubs.LARGE_IMAGE_HEIGHT) {
                $el.addClass('large');
            }
            $el.removeAttr('height').css({'height': 'auto'});
        });
    }
};

/**
 * Loads images not hosted on our server for safety purposes
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.loadNonProxiedImages = function(){
    $(Hubs.Config.containers.nonProxiedImg).each(function(){
        var imageTest = $('<img>'),
            $container = $(this);
        imageTest.attr('src', $container.attr('data-imgurl')).load(function () {
            $(this).addClass($container.attr('data-class'));
            $container.parent().html(this.outerHTML).hide().fadeIn(500);
        }).error(function () {
            // Broken link, remove the span
            $container.remove();
        });
    });
};

/**
 * Styles timestamps to english
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.setFriendlyTimestamps = function(){
    $(Hubs.Config.containers.timeAgo).timeago();
};

/**
 * Fixes Content Links by adding target="_blank" rel="noopener" data-internal="false"
 *
 * @public
 * @this local
 * @param {String} container The ID of the Container to fix images within
 * @return undefined
 */
Hubs.fixContentLinks = function(container) {
    var $container = $(container);
    if (!$container.length) { return; }

    $container.find('a:not(.hooked)').each(function(idx, el) {
        var $el = $(el);
        // if target is already set, respect content's decision
        // otherwise override to open in new window unless it's an anchor
        if ($el.attr('target')) {
            $el.attr('data-internal', 'false');
        }
        else if(Hubs.isLinkInternal($el.attr('href'))) {
            $el.attr('data-internal', 'standard');
        }
        else if ($el.attr('href') && $el.attr('href').length && $el.attr('href').charAt(0) === '#') {
            $el.attr({'target': '_self', 'data-internal': 'false'});
        }
        else {
            $el.attr({'target': '_blank', 'rel': 'noopener', 'data-internal': 'false'});
        }
    });
};

Hubs.isLinkInternal = function(link) {
    if(typeof link != 'string') {
        return false;
    }
    var pattern = new RegExp("^" + location.protocol + "//" + window.location.hostname, "i");
    return link.match(pattern) !== null;
};

/**
 * Fixes previous and next images for the image pages by re-aligning
 *
 * @public
 * @this local
 * @param {String} container The ID of the Container to fix images within
 * @return undefined
 */
Hubs.fixPrevNextImages = function(container) {
    var $container = $(container),
        imgLoad = null;

    if (!$container.length) { return; }

    imgLoad = imagesLoaded(container);
    imgLoad.on('always', function(instance, image){
        $container.find('.prev-next img').each(function(){
            var img = $(this), w = img.width(), pw = img.parent().width();
            if (w > pw) {
                img.css('left', -((w-pw)/2));
            }
        });
        });

    };

/**
 * Restricts Scrolling on the Body Element (used while the Splash Screen is Displayed)
 *  - Note: "noscroll" class sets Body Element Position to "Fixed", so we have to transfer the current value
 *    of "scrollTop" to the Body CSS "top", and transfer back when we restore scrolling.
 * @public
 * @this local
 * @return undefined
 */
Hubs.restrictBodyScroll = function(scrollTop) {
    if (Hubs.lastKnownBodyScroll !== false) { return; }
    Hubs.lastKnownBodyScroll = Math.max(Shared.scrollTop(), scrollTop || 0);
    Shared.scrollTop(0);
    Shared.$('body').css({'top': -Hubs.lastKnownBodyScroll});
    Shared.$('body').addClass('noscroll');
};

/**
 * Restores Scrolling on the Body Element
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.restoreBodyScroll = function() {
    if (Hubs.lastKnownBodyScroll === false) { return; }
    Shared.scrollTop(Hubs.lastKnownBodyScroll);
    Shared.$('body').css({'top': 0});
    Shared.$('body').removeClass('noscroll');
    Hubs.lastKnownBodyScroll = false;
};

/**
 * Sets the State of Scrolling on the Body Element
 *
 * @public
 * @param {Boolean} state - True to Allow Scrolling, False to Restrict Scrolling
 * @this local
 * @return undefined
 */
Hubs.setBodyScroll = function(state) {
    Hubs[state ? 'restoreBodyScroll' : 'restrictBodyScroll']();
};

/**
 * Stores a page view either locally or server side depending on condition
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.storePageView = function(pageType, itemId, collectionId, hiddenBlockingCta){
    var configKnownUser = Hubs.Config.knownUser;

    var knownUsers = [];
    for (var key in configKnownUser) {
        if (
            configKnownUser.hasOwnProperty(key) &&
            configKnownUser[key]
        ) {
            knownUsers.push(key);
        }
    }

    var timestamp = Hubs.Config.serverTimestamp || Math.floor(Date.now()/1000);

    var currentView = [itemId, collectionId, timestamp, window.location.toString()];

    // Send page views
    if (knownUsers.length) {
        var data = {};
        for (var j=0; j < knownUsers.length; j++) {
                // check storage before submit
                var dataStorage = localStorage.getItem(knownUsers[j]);
                if(dataStorage !== null && typeof dataStorage !== "undefined"){
                    dataStorage = JSON.parse(dataStorage);
                }

                // check if we need to submit additional data
                if (dataStorage && _.isArray(dataStorage.items)) {
                    dataStorage.items.push(currentView);
                    data[knownUsers[j]] = dataStorage.items;
                } else {
                    data[knownUsers[j]] = [currentView];
                }
        }

        Shared.ajaxFetch(Hubs.Config.serverUrl.pageViewTracking, {views: data, hiddenBlockingCta: hiddenBlockingCta}, 'POST', true, function(resp){
            if (resp.response.ok) {
                // clean storage
                for (var j=0; j < knownUsers.length; j++) {
                    localStorage.setItem(knownUsers[j],  JSON.stringify({items:[]}));
                }
            }
        });
    }

    // Get unsaved integrations
    var unsavedIntegrations = _.difference(Hubs.Config.integrationsToTrackViews, knownUsers);

    // Save page views
    if (unsavedIntegrations.length) {
        for (var i=0; i < unsavedIntegrations.length; i++) {
            var integrationName = unsavedIntegrations[i];
            var obj = localStorage.getItem(integrationName);
            if (obj === null || typeof obj === "undefined"){
                // Create a new object
                obj = {"items": [currentView]};
            } else {
                obj = JSON.parse(obj);
                if (_.isArray(obj.items)) {
                    // If user reached the limit of 150 items, reset the item list
                    if (obj.items.length >= 150) {
                        obj.items = [currentView];
                    } else {
                        obj.items.push(currentView);
                    }
                }
            }
            localStorage.setItem(integrationName,  JSON.stringify(obj));
        }
    }

    // Update MAP users
    Shared.ajaxFetch(Hubs.Config.serverUrl.updateMAPUsers, {}, 'POST', true, function(data) {
        if (data.response.knownUser) {
            Hubs.Config.knownUser = data.response.knownUser;
            Object.seal(Hubs.Config.knownUser);
        }
    });
};

/**
 * getUrlFragmentId():
 *   Converts a non-vanity url into a fragment that is used as a hash-bookmark on the
 *   embedded hub's parent window.
 *
 * @public
 * @this local
 * @param pageUrl = non-vanity url, e.g. https://hub.uberflip.com/h/i/12448663-example-article
 * @returns {string} = bookmark fragment, e.g. ufh-i-12448663-example-article
 */
Hubs.getUrlFragmentId = function(pageUrl) {
    var urlFragment = pageUrl || '';
    var index;

    // Remove any Query String
    index = urlFragment.indexOf('?');
    if (index > -1) urlFragment = urlFragment.substr(0, index);

    // Remove any Fragment Identifiers
    index = urlFragment.indexOf('#');
    if (index > -1) urlFragment = urlFragment.substr(0, index);

    // Clean Up URL
    urlFragment = urlFragment.substr(urlFragment.lastIndexOf('/h') + 3).replace(/([c|i|author])\//i, '$1-');
    if (urlFragment.length) urlFragment = 'ufh-' + urlFragment;

    return urlFragment;
};

/**
 * Initialize Special Global Events
 *
 * @public
 * @this local
 * @return undefined
 */
Hubs.initSpecialEvents = function() {
    var specialEvents = [
        {'event': 'keyup',  'prefix': 'debounced', 'threshold': 250},
        {'event': 'resize', 'prefix': 'debounced', 'threshold': 150},
        {'event': 'scroll', 'prefix': 'debounced', 'threshold': 150}
    ];
    Shared.registerSpecialEvents(specialEvents);
};

/**
 * Delegate events to jquery
 */
var eventNamespace = 'Hubs';
var $eventElement = $(window);
Hubs.Events = {
    on: function(eventName, fn) {
        $eventElement.on(eventNamespace + '.' + eventName, function(/*jqEvent*/) {
        var args = Array.prototype.slice.call(arguments, 1);
        fn.apply(null, args);
        });

        // allow chaining
        return Hubs.Events;
    },
    off: function(eventName) {
        $eventElement.off(eventNamespace + '.' + eventName);
        return Hubs.Events;
    },
    trigger: function(/*eventName*/) {
        var args = Array.prototype.slice.call(arguments);
        args[0] = eventNamespace + '.' + args[0];
        $eventElement.trigger.apply($eventElement, args);
    }
};
