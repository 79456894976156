import 'jquery-migrate';
import $ from 'jquery';

/**
 * A wrapper for creating a variable that is read from / written to session storage,
 * containing events for any side effects that may be needed. Used to move us away from
 * ajax based navigation, while preserving state for the user across page changes.
 *
 * @class SessionVariable
 */
class SessionVariable {

  constructor(key) {
    this.sessionStorageKey = key;
  }

  /**
   * Apply jQuery based event listeners for this variable.
   * EX types: set, get, clear
   *
   * @param {String} type
   * @param {Function} func
   * @returns {SessionVariable}
   * @memberof SessionVariable
   */
  on(type, func) {
    $.fn.on.apply($(window),[
      `state.${this.sessionStorageKey}.${type}`, 
      func
    ]);
    return this;
  }

  /**
   * Trigger jQuery based events on this variable
   *
   * @param {String} type
   * @param {Function} func
   * @returns {SessionVariable}
   * @memberof SessionVariable
   */
  trigger(type, ...args) {
    $.fn.trigger.apply($(window),[
      `state.${this.sessionStorageKey}.${type}`,
      ...args
    ]);
    return this;
  }

  /**
   * Get the value of a particular key on this session variable,
   * or get the entire sessionVariable object (with no key provided).
   * Triggers "get" Event.
   *
   * @param {String} key
   * @memberof SessionVariable
   */
  get(key) {
    if(key) {
      const data = sessionStorage.getItem(this.sessionStorageKey);
      const obj = data ? JSON.parse(data) : {};
      this.trigger('get', obj[key], key);
      return obj[key];
    } else {
      const data = sessionStorage.getItem(this.sessionStorageKey);
      const obj = data ? JSON.parse(data) : {};
      this.trigger('get', obj, 'all');
      return obj;
    }
  }
      
  /**
   * Set a particular key on this session variable to a
   * provided value. Triggers "set" event.
   *
   * @param {String} key
   * @param {*} value
   * @returns {Object}
   * @memberof SessionVariable
   */
  set(key, value) {
    const data = sessionStorage.getItem(this.sessionStorageKey);
    const obj = data ? JSON.parse(data) : {};
    obj[key] = value;
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(obj));
    this.trigger('set', obj, key);
    return obj;
  }
      
  /**
   * Delete the contents of a session variable, replacing them with an
   * empty object.
   *
   * @returns {SessionVariable}
   * @memberof SessionVariable
   */
  clear() {
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify({}));
    this.trigger('clear', this.sessionStorageKey);
    return this;
  }

  /**
   * Removes a particular key on this session variable.
   * Triggers a remove event
   *
   * @param {String} key
   * @returns {Object}
   * @memberof SessionVariable
   */
  remove(keyToDelete) {
    const data = sessionStorage.getItem(this.sessionStorageKey);
    const obj = data ? JSON.parse(data) : {};
    const { [keyToDelete]: value, ...newObjValue } = obj;
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(newObjValue));
    this.trigger('remove', keyToDelete);
    return newObjValue;
  }

  /**
   * Returns true/false if this session variable contains any keys
   *
   * @returns {SessionVariable}
   * @memberof SessionVariable
   */
  hasContents() {
    const contents = this.get();
    return (Object.keys(contents).length > 0);
  }
}

export default SessionVariable;